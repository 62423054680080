<template>
    <!-- 'width:' + window.innerWidth*1 + 'px;height:' +  window.innerHeight*1 + 'px' -->
    <div :style="{ width: viewWidth + 'px', height: viewHeight + 'px' }" @mousedown="updataRenderer"
        @touchstart="updataRenderer" @mousemove="updataRenderer" v-if="mpdUrl"
        style="overflow: hidden;position: absolute;top:0px;left:0px;background-color: #35495d;" ref="mpdview">
        <!-- <div>{{viewWidth}}:{{viewHeight}}</div> -->
        <div style="width: 100%;height: 100%;" id="three2"></div>

        <div v-if="modelStepTotal > 1"
            style="position:absolute;bottom: 10px;left: 50%;transform: translateX(-50%);display: flex;align-items: center;width: 100vw;">

            <el-button :disabled=bottom_disabled @click="SetToBeforeStep"
                style="font-size: 50px;padding: 5px;margin-left: 5px;" type="primary"><i
                    class="el-icon-arrow-left"></i></el-button>
            <div style="flex:1;margin-left: 20px;">

                <el-slider :show-tooltip="false" :min="1" :max="modelStepTotal" @change="stepChange" @input="stepInput"
                    :step="1" v-model="stepShow"></el-slider>


            </div>


            <div style="display:flex;">

                <input type="number" v-model="stepShow_input" @input="stepInput(stepShow_input)"
                    @blur="handleInputToTargetStep(stepShow_input)"
                    @keyup.enter="handleInputToTargetStep(stepShow_input)" class="input-hide-arrows"
                    :style="'width:' + stepInputWidth + 'px;margin-left: 15px;margin-right: 5px;height: 30px;font-size: 25px;font-weight: 900;max-width: 150px;min-width: 30px;'">
                <div
                    style="color:aliceblue ;font-size: small;display: flex;flex-direction: column-reverse;margin-right: 5px;">
                    /{{ modelStepTotal }}</div>

            </div>

            <el-button :disabled=bottom_disabled @click="SetToAfterStep"
                style="font-size: 50px;padding: 5px;margin-right: 5px;" type="primary"><i
                    class="el-icon-arrow-right"></i></el-button>
        </div>




        <div
            style="z-index: 50;position:absolute;top: 0px;right:0;display: flex;align-items: flex-end;flex-direction: column;justify-content: center">
            <!-- 查看零件时不显示这个关闭按钮 -->
            <el-button v-if="!showPartInfo" @click="closeMpdPlayer"
                style="padding: 0px;font-size: xx-large;margin: 10px;" type="text" icon="el-icon-close" circle>
            </el-button>


            <!-- <el-button v-if="!showPartInfo" @click="updateRander" style="padding: 0px;font-size: xx-large;margin: 10px;"
                type="text" icon="el-icon-close" circle>更新渲染器
            </el-button> -->



            <!-- @image-click="partClick" v-if="viewWidth  > viewHeight"-->
            <!-- 竖屏隐藏 -->
            <MpdGroupList v-if="partsListImagesData_GroupsArr.length > 0 && viewWidth > viewHeight"
                :ImegesData=partsListImagesData_GroupsArr
                style="max-width: 300px;max-height: 80vh;height:auto;width: max-content;margin-top: 5px;margin-right: 5px;border: #4095f7 solid 2px;background-color: #ecf0f1;padding: 5px;">
            </MpdGroupList>

        </div>



        <div style="position:absolute;top: 10px;left: 0px;">

            <div style="font-size:x-large ;font-weight: 900;color: #ecf0f1;text-align: left;">{{ stepPath }}/{{
                maxStepPath }}
            </div>

            <MpdPartList v-if="partsListImagesData_PartsArr.length > 0"
                :class="viewWidth > viewHeight ? 'vertical' : 'horizontal'" :ImegesData=partsListImagesData_PartsArr
                :ParentWidth=viewWidth :ParentHeight=viewHeight @image-click="partClick" style="">
            </MpdPartList>

        </div>


        <div class="mask-layer"
            style="position: absolute;top:0;left:0;font-size: medium;width: 200vw;height: 200vh;display: flex;flex-wrap: wrap;">

            <div class="planet" v-for="item in items" :key="item.index">搭搭星球{{ FileInfoShow }}</div>

        </div>


        <!-- 零件信息 -->
        <div style="position:absolute;top: 0;left: 0;z-index: 100;">

            <MpdPartInfoView @clickCloseInfoView="closePartInfoView"></MpdPartInfoView>

        </div>

        <!-- 显示零件时不显示这几个按钮 -->
        <div v-if="!showPartInfo"
            style="position:absolute;bottom: 90px;right:  10px;color: #4095f7;display: flex;flex-direction: column-reverse;align-items: center;">

            <!-- 步骤零件可见性 -->
            <div style="width:50px ;height: 50px;margin-bottom: 5px;">
                <el-button :disabled=bottom_disabled @click="SetThisStepAndBeforeOnStep" type="text"
                    style="width:100% ;height: 100%;padding: 0px;">

                    <div v-if="visibleBottomLoading" style="width: 100%;height:100%;pointer-events: none;">

                        <div
                            style="width:80% ;height: 80%;border: #4095f7 solid 2px;border-radius: 5px;display: flex;justify-content: center;align-items: center;">
                            <i class="el-icon-loading"></i>
                        </div>

                    </div>

                    <div v-else style="width: 100%;height:100%;pointer-events: none;">

                        <div v-if="seeAllPars"
                            style="width:80% ;height: 80%;border: #4095f7 solid 2px;border-radius: 5px;display: flex;justify-content: center;align-items: center;">
                            <img style="width:75% ;height: 75%;user-drag:none" src="../assets/visible.svg" alt="">
                        </div>
                        <div v-else
                            style="width:80% ;height: 80%;border: #4095f7 solid 2px;border-radius: 5px;display: flex;justify-content: center;align-items: center;">
                            <img style="width:75% ;height: 75%;user-drag:none" src="../assets/notAllVisible.svg" alt="">
                        </div>

                    </div>

                </el-button>
            </div>

            <!-- 自动包围球模式 -->
            <div style="width:50px ;height: 50px;margin-bottom: 5px;">
                <el-button @click="SetStepPartsBigShow" type="text" style="width:100% ;height: 100%;padding: 0px;">
                    <div v-if="enableAtuoStepBigShow"
                        style="width:80% ;height: 80%;border: #4095f7 solid 2px;border-radius: 5px;display: flex;justify-content: center;align-items: center;">
                        <img style="width:75% ;height: 75%;user-drag:none" src="../assets/stepModelBigestShow.svg"
                            alt="">
                    </div>
                    <div v-else
                        style="width:80% ;height: 80%;border: #4095f7 solid 2px;border-radius: 5px;display: flex;justify-content: center;align-items: center;">
                        <img style="width:75% ;height: 75%;user-drag:none"
                            src="../assets/stepModelBigestShow_notEnable.svg" alt="">
                    </div>
                </el-button>
            </div>
        </div>

        <div id="canvas_part_list"
            style="position: absolute;left: -1000px;top: 100vh;background-color: #212328;width: 100px;height: 100px;">
        </div>




    </div>
</template>

<script>



import * as THREE from 'three';

import COS from 'cos-js-sdk-v5';

// import { GUI } from 'three/addons/libs/lil-gui.module.min.js';
import * as TWEEN from "tween.js";
import Stats from "stats.js";
import { OrbitControls } from '../../src/js/OrbitControls.js';
// import { TransformControls } from "../../src/js/TransformControls.js";

import { RoomEnvironment } from '../../src/js/RoomEnvironment.js';

import { LDrawLoader } from '../../src/js/LDrawLoader.js';
// import { LDrawUtils } from '../../src/js/LDrawUtils.js';
import { DragControls } from 'three/addons/controls/DragControls.js'

// import { EventBus } from './eventBus.js';

import MpdPartList from "../components/MpdPartList.vue";
import MpdGroupList from "../components/MpdGroupList.vue";

// import MpdPartList from "../components/MpdPartList.vue";
import MpdPartInfoView from "../components/PartInfoView.vue";

// import ColorMaterialLibrary from '../../public/colorInfo/colors.txt';

import { materialLibraryTXT } from '../js/colors.js';



import WebGL from '../../src/js/WebGL.js';




import { MpdPartListCreater } from "../../src/js/MpdPartListCreater.js";

// DoStepAnimationPlayer
import { DoStepAnimation } from "../../src/js/DoStepAnimation.js"
// DragControls = new DragControls(model.children, camera, renderer.domElement);

let container, progressBarDiv;

let camera, scene, renderer, OrbitControl

let mpdPartListCreater, DoStepAnimationPlayer

// le t gui

let model;

let DragControl
let lDrawLoader
// let Transformcontrol
let stats
// let translatePart
// let showGroup
let MyBoxHelperedgesMaterial = new THREE.LineBasicMaterial({
    color: 0xff00cc,
});

MyBoxHelperedgesMaterial.userData.type = "MyBox3HelperMaterial";

//半透明材质
let material_translucent = new THREE.MeshBasicMaterial({
    color: 0x888888,
    // depthTest: false,
    // depthWrite: false,
    opacity: 0.05,
    transparent: true,
});
material_translucent.userData.myType = 'MyTranslucentMaterial'



//不透明灰色
let material_opaque = new THREE.MeshStandardMaterial({
    color: 0x999999,
    // depthTest: false,
    // depthWrite: false,
    // opacity: 0.05,
    // transparent: true,
});

material_opaque.userData.myType = 'MyOpaqueMaterial'

let Model_children_visibleInfoArr


//允许渲染
// let Rendering_enabled = true


// 获取元素//用户产权信息显示
// var fileInfoShow

// import tcb from "@cloudbase/js-sdk";
// import { set } from 'vue/types/umd';
// import { LoopOnce } from 'three';
// import { TetrahedronGeometry } from "three";
// const app = tcb.init({
//     env: process.env.VUE_APP_CLOUDBASE_ENV_ID,
// });

//存储桶参数
// const Bucket = 'dadastar-1307054034';
// const Region = 'ap-shanghai';

// let COS = require('cos-js-sdk-v5');
// const cos = new COS({
//   SecretId: 'AKIDueB3oQp0xgKm394oNipiy3A7sSnvpX4P',
//   SecretKey: 'FI8mCuPglfS1XqvmPnxtb7LUedR4TebJ',
// });

// 引入所需的包
// import 'babel-core/browser.min.js';
// import 'babel-polyfill/browser-polyfill.min.js';
// import '../js/base64.js';
// import '../js/sdk-v0.2.1.js';

// 在需要进行动画循环的组件中，定义一个变量来存储 animation frame ID
// let animationFrameId = null;



// const ldrawPath = 'models/ldraw/officialLibrary/'
let ldrawPath = ''

//步骤变量
let stepNumber = 0
//可见性组
let showGroup

//所有的零件
let partArr = []
//所有的组
let groupArr = []

let partAndGroupArr = []

let loading

let EnableRender = true
let UserLastOperateTimeStamp

let init = false

let ViewModel = true

//是否支持webgl
var WebGL_available = false

//正在查看零件信息
var partInfoView_isVisible = false


// let saveText

//零件动画数据
// let partAnimationArr
//mpdView组件的尺寸
// let MpdViewWidth, MpdViewHeight


// https://636c-cloudbase-baas-5g5r8gfv6e64cb31-1307054034.tcb.qcloud.la/mpdtest/baolong_wedo2_0.ldr?sign=19b57ed3cf9d78cf0f3f05cdc2f90df1&t=1689177926
// https://636c-cloudbase-baas-5g5r8gfv6e64cb31-1307054034.tcb.qcloud.la/mpdtest/wddoallstep.mpd?sign=00d198d45c38ece951c35031ce4fa50e&t=1682917156
// const modelFileList = {
//     'Car': 'https://636c-cloudbase-baas-5g5r8gfv6e64cb31-1307054034.tcb.qcloud.la/mpdtest/buyingcao_mpd5.mpd?sign=a1c2b5722158f3974a96ba29d2dae373&t=1689346101',
//     'Lunar Vehicle': 'https://636c-cloudbase-baas-5g5r8gfv6e64cb31-1307054034.tcb.qcloud.la/mpdtest/buyingcao_wedo2_0.ldr?sign=3e98c081f8705285ea93e2b8809cbf1d&t=1689342291',
//     'xhouxiao': 'https://636c-cloudbase-baas-5g5r8gfv6e64cb31-1307054034.tcb.qcloud.la/mpdtest/ZhouXiaoKuaiCwShi%20.mpd?sign=58c402134c51d6f50c9b2bedbcf9f013&t=1682917339',
//     'Trailer': 'models/4838-1-MiniVehicles.mpd_Packed.mpd',
//     'Bulldozer': 'models/4915-1-MiniConstruction.mpd_Packed.mpd',
//     'Helicopter': 'models/4918-1-MiniFlyers.mpd_Packed.mpd',
//     'Plane': 'models/5935-1-IslandHopper.mpd_Packed.mpd',
//     'Lighthouse': 'models/30023-1-Lighthouse.ldr_Packed.mpd',
//     'X-Wing mini': 'models/30051-1-X-wingFighter-Mini.mpd_Packed.mpd',
//     'AT-ST mini': 'models/30054-1-AT-ST-Mini.mpd_Packed.mpd',
//     'AT-AT mini': 'models/4489-1-AT-AT-Mini.mpd_Packed.mpd',
//     'Shuttle': 'models/4494-1-Imperial Shuttle-Mini.mpd_Packed.mpd',
//     'TIE Interceptor': 'models/6965-1-TIEIntercep_4h4MXk5.mpd_Packed.mpd',
//     'Star fighter': 'models/6966-1-JediStarfighter-Mini.mpd_Packed.mpd',
//     'X-Wing': 'models/7140-1-X-wingFighter.mpd_Packed.mpd',
//     'AT-ST': 'models/10174-1-ImperialAT-ST-UCS.mpd_Packed.mpd'
// }

// import tcb from '@cloudbase/js-sdk'
// const app = tcb.init({
//   env:'cloudbase-baas-5g5r8gfv6e64cb31'
// })


export default {

    components: {
        MpdPartList,
        MpdGroupList,
        MpdPartInfoView
    },

    data() {
        return {

            // rotateMode: true,
            // DragMode: false,

            //记录鼠标是否移动，用来打开右键菜单
            // MouseMoved: false,

            //目标步骤数
            // currentStepNum: 0,
            //模型总步数
            modelStepTotal: 0,

            //是否为没有动画的模型自动添加动画数据
            AnimationAuto: true,

            //是否执行动画
            DoAnimation: true,

            //mpddiv（总div）的宽度
            MPDdivWidth: 200,

            mpdUrl: null,

            timer: null,

            //零件列表数据
            partsListImagesData_PartsArr: [],
            partsListImagesData_GroupsArr: [],

            //生成100个数组
            items: Array.from({ length: 300 }, (v, k) => k),

            stepPath: '',
            maxStepPath: '',
            StepSliderValue: 0,
            stepShow: 1,
            stepShow_input: 1,
            stepInputWidth: 30,
            seeAllPars: false,
            // allowButtonPressed: true,

            //是否启用步骤零件最大化显示
            enableAtuoStepBigShow: true,

            //按钮禁用
            bottom_disabled: false,
            //可见性按钮loading标志
            visibleBottomLoading: false,

            // ChooseObj3D_obj: {},

            showPartInfo: false,

            //视图宽高
            viewWidth: null,
            viewHeight: null,

            FileInfoShow: ""



            // style_mainView:''

        };
    },

    mounted() {

        this.checkWebGLSupport()

        // console.log('windowInfo_VUE.innerWidth',this.windowInfo_VUE,this.$windowInfo_VUE,this.$root.$windowInfo_VUE)


        console.log('新生成mpd播放器页面')
        // this.ininThree()

        window.addEventListener('keyup', this.handleKeyPress);
        window.addEventListener('resize', this.windowResize)




        // 监听事件总线上的 window-resized 事件
        // this.$eventBus.$on('window-resized', this.windowResize);
        this.$parent.$on('messageToMpdPlayer', this.handleParentMessage);

        this.$parent.$on('messageToMpdPlayer_inputMpdData', this.handleParentMessage_temp_view_input_text);

        this.timeLooper()

        UserLastOperateTimeStamp = Date.now()

        this.viewWidth = window.innerWidth * 1
        this.viewHeight = window.innerHeight * 1

        console.log('宽高尺寸333333', this.viewWidth, this.viewHeight)



        // this.style_mainView = 'width:' + window.innerWidth*1 + 'px;height:' +  window.innerHeight*1 + 'px'

        // this.init();
        // this.animate();


        // this.creatempdFile()

    },

    beforeDestroy() {

        this.disposeScene()

        if (lDrawLoader) {
            lDrawLoader.disposeData()
        }

        if (DoStepAnimationPlayer) {
            DoStepAnimationPlayer.disposeData()
        }




        console.log('销毁2222')

        window.removeEventListener('keyup', this.handleKeyPress)

        window.removeEventListener("resize", this.windowResize);

        // handleParentMessage_temp_view

        if (OrbitControl) {

            OrbitControl.dispose()
        }

        if (DragControl) {

            DragControl.dispose()
        }

        // Transformcontrol.dispose()

        if (stats) {
            stats.domElement.remove(); // 移除stats的DOM元素
            stats = null; // 清除对stats实例的引用，以便垃圾回收
        }

        // mpdview

        // domElement.remove()


        //以下是乱清除的，不知道有没有效果

        ViewModel = false
        EnableRender = null

        container = null
        progressBarDiv = null

        camera = null
        scene = null
        renderer = null
        OrbitControl = null

        mpdPartListCreater = null
        DoStepAnimationPlayer = null

        // let gui

        model = null

        DragControl = null
        lDrawLoader = null
        // Transformcontrol = null
        stats = null
        // translatePart = null
        // let showGroup
        MyBoxHelperedgesMaterial = null

        // MyBoxHelperedgesMaterial.userData.type = null

        //半透明材质
        material_translucent = null
        material_translucent = null
        // material_translucent.userData.myType = null



        //不透明灰色
        material_opaque = null
        material_opaque = null

        // material_opaque.userData.myType = 'MyOpaqueMaterial'

        Model_children_visibleInfoArr = null

        // const ldrawPath = 'models/ldraw/officialLibrary/'
        ldrawPath = null

        //步骤变量
        stepNumber = null
        //可见性组
        showGroup = null

        //所有的零件
        partArr = null
        //所有的组
        groupArr = null

        partAndGroupArr = null






        loading = null

        // let cameraAnimationTweenArr = []

        // let thisStepPartAnimation = []

        // let modelRotateTweenArr = []

        EnableRender = null
        UserLastOperateTimeStamp = null

        // let loading


        init = false


        // this.$eventBus.$off('window-resized', this.windowResize);


    },

    // updated() {

    //     // this.init();
    //     // this.animate();

    // },

    // animate() {
    //   requestAnimationFrame(this.animate);
    //   controls.update();
    //   this.render();
    // },

    // beforeRouteLeave(to, from, next) {
    //   console.log('路由离开3d界面')
    //   // 在路由离开当前组件前停止动画循环
    //   cancelAnimationFrame(animationFrameId);
    //   animationFrameId = null;

    //   next();
    // },

    // beforeRouteEnter(to, from, next) {
    //   console.log('路由进入3d界面')

    //   // 在路由进入当前组件时重新开始动画循环
    //   this.animate()

    //   next();
    // },


    methods: {

        //上一步
        SetToBeforeStep() {



            const that = this

            this.stepShow--
            if (this.stepShow < 1) {
                this.stepShow = 1

                this.stepShow_input = this.stepShow

                this.$message({
                    message: '这是第一步',
                    type: 'warning'
                });
                return
            }
            this.stepShow_input = this.stepShow

            //避免连续点击
            // let loading1 = this.$loading({
            //     lock: true,
            //     text: '',
            //     spinner: '',
            //     background: 'rgba(0, 0, 0, 0)'
            // });

            // setTimeout(() => {
            //     if (loading1 != null) {
            //         loading1.close();
            //     }
            // }, 5000);

            // this.handleCurrentStepChange(this.stepShow)

            // setTimeout(() => {
            //     if (loading1 != null) {
            //         loading1.close();
            //     }
            // }, 100);

            this.bottom_disabled = true

            let bottom_disabled = true


            // 是否已经允许点击


            setTimeout(() => {
                if (bottom_disabled == true) {
                    // loading1.close();
                    that.bottom_disabled = false
                    bottom_disabled = false
                }
            }, 5000);

            this.handleCurrentStepChange(this.stepShow)

            setTimeout(() => {
                if (bottom_disabled == true) {
                    // loading1.close();
                    that.bottom_disabled = false
                    bottom_disabled = false
                }
            }, 500);


        },
        //下一步
        SetToAfterStep() {
            // this.stepShow = this.stepShow_input

            const that = this
            this.stepShow++

            if (this.stepShow > this.modelStepTotal) {

                this.stepShow = this.modelStepTotal
                this.stepShow_input = this.stepShow
                this.$message({
                    message: '恭喜搭建完成',
                    type: 'success'
                });
                return
            }

            this.stepShow_input = this.stepShow

            //避免连续点击
            // let loading1 = this.$loading({
            //     lock: true,
            //     text: '',
            //     spinner: '',
            //     background: 'rgba(0, 0, 0, 0)'
            // });


            this.bottom_disabled = true

            let bottom_disabled = true


            // 是否已经允许点击


            setTimeout(() => {
                if (bottom_disabled == true) {
                    // loading1.close();
                    that.bottom_disabled = false
                    bottom_disabled = false
                }
            }, 5000);

            this.handleCurrentStepChange(this.stepShow)

            setTimeout(() => {
                if (bottom_disabled == true) {
                    // loading1.close();
                    that.bottom_disabled = false
                    bottom_disabled = false
                }
            }, 500);

        },

        //输入目标步骤时自动调整输入框宽度
        stepInput() {
            // stepShow>modelStepTotal

            // this.stepShow = stepShow_input*1



            // this.stepInputWidth = ((this.stepShow + '').length) * 18
            this.stepInputWidth = ((this.stepShow_input + '').length) * 18


            // console.log('this.stepShow',this.stepShow)

            // console.log(((this.stepShow+'').length))

            // console.log(((this.stepShow+'').length)*15)

            // console.log('输入值的宽度', this.stepInputWidth,' ',this.stepShow.length)


        },

        handleInputToTargetStep() {



            if (this.stepShow_input > this.modelStepTotal) {
                this.stepShow_input = this.modelStepTotal
                // this.stepShow = this.modelStepTotal

            }
            if (this.stepShow_input < 1) {

                // this.stepShow = 1
                this.stepShow_input = 1

            }

            this.stepShow = this.stepShow_input * 1

            this.stepInputWidth = ((this.stepShow + '').length) * 18

            console.log('目标位置step', this.stepShow)

            this.handleCurrentStepChange(this.stepShow)
        },

        //拖动进度条触发事件
        stepChange(e) {
            console.log('进度条拖动事件', e)

            if (!e) {

                return

            }

            this.stepShow_input = this.stepShow
            this.stepInputWidth = ((this.stepShow + '').length) * 18

            this.handleCurrentStepChange(this.stepShow)


        },


        // StepSliderFormatTooltipValue(val) {
        //     console.log('slider值是：', val, '   ', this.StepSliderValue)

        //     this.stepShow = (this.modelStepTotal * (this.StepSliderValue / 100)).toFixed(0)

        //     return this.stepShow;
        // },

        //零件被点击
        partClick(e) {
            console.log('主程序收到零件点击事件3', e)

            let clickObj = model.getObjectById(e.id)

            // this.ChooseObj3D_obj = clickObj.clone()
            console.log('clickObj33', clickObj)
            this.showPartInfo = true

            let cloneobj = clickObj.clone()

            // sendDataToChild() {
            // 假设this.my3DModel是你要传递的3D模型引用或相关数据
            // EventBus.$emit('modelData_showModelInfo', clickObj);
            EnableRender = false
            partInfoView_isVisible = true

            this.$emit("messageToPartInfoView", { type: "import3DData", data: cloneobj });


            // .toJSON ()
            console.log(clickObj, this.showPartInfo)

        },

        windowResize() {
            // console.log('时间总线上的事件11')

            //没有渲染则不会有反应
            if (!camera) { return }

            this.viewWidth = window.innerWidth * 1
            this.viewHeight = window.innerHeight * 1

            // this.style_mainView = 'width:' + window.innerWidth*1 + 'px;height:' +  window.innerHeight*1 + 'px'


            const width = window.innerWidth * 1;
            const height = window.innerHeight * 1;


            this.MPDdivWidth = width

            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            // 更新渲染器的大小
            renderer.setSize(width, height);

            //必须渲染一次，否则画面会出问题
            renderer.render(scene, camera);

        },

        updataRenderer() {

            //正在查看子零件，鼠标移动或者触摸都不要激活渲染，避免性能浪费
            if (partInfoView_isVisible) return



            UserLastOperateTimeStamp = Date.now()
            EnableRender = true
            // console.log('鼠标键盘事件更新时间', UserLastOperateTimeStamp)
            // if (!EnableRender) {

            //     EnableRender = true
            //     this.animate()

            // }


        },

        timeLooper() {

            const that = this

            setTimeout(() => {

                // console.log('每秒钟工作一次')

                if (EnableRender) {


                    if (Date.now() - UserLastOperateTimeStamp > 20000) {

                        // console.log('停止工作')
                        EnableRender = false


                    }
                }

                that.timeLooper()

            }, 1000);

        },


        //计时器，每秒钟启动一次
        //     startTimer() {
        //   this.timerId = setInterval(() => {
        //     const currentTime = Date.now();
        //     if (currentTime - this.lastInteractionTime > 30000) },
        // stopTimer() {
        //   clearInterval(this.timerId);
        //   this.timerId = null;
        // }

        //手动更新场景
        // async updateRander() {
        //     // e.mpdData
        //     // var mpdData = saveText
        //     this.init()
        //     // this.mpdUrl = e.Key
        //     // this.handleParentMessage_temp_view_input_text({mpdData})

        //     EnableRender = false


        //     renderer = 




        //     // loading = this.$loading({
        //     //     lock: true,
        //     //     text: '重新加载中',
        //     //     spinner: 'el-icon-loading',
        //     //     background: 'rgba(0, 0, 0, 0.7)'
        //     // });

        //     // await this.delay(100)


        //     // await this.reloadObject(true, null, mpdData);

        //     // console.log('stateDIV22', document.getElementById("statsView"))







        //     console.log('renderer', renderer)

        //     console.log('scene', scene)



        //     // console.log(init)


        //     EnableRender = true


        //     //

        //     // const width = window.innerWidth * 1;
        //     // const height = window.innerHeight * 1;
        //     // renderer = new THREE.WebGLRenderer({ antialias: true });

        //     // //depthBits:32,提高远处物体的精度（后面的不容易跑到前面来）

        //     // // renderer.setClearColor(0xffffff);
        //     // // renderer.setClearAlpha(1);
        //     // // renderer.autoClear = false;
        //     // // renderer.sortObjects = false;


        //     // renderer.setPixelRatio(window.devicePixelRatio);
        //     // renderer.setSize(width, height);
        //     // // renderer.useLegacyLights = false;
        //     // renderer.toneMapping = THREE.ACESFilmicToneMapping;

        //     // // container = document.querySelector("#three2");


        //     // console.log('container44443322233', container)

        //     // container.appendChild(renderer.domElement);
        //     // //设置id，方便查找
        //     // renderer.domElement.id = "ThreeCanvas";

        //     // scene

        //     // const pmremGenerator = new THREE.PMREMGenerator(renderer);
        //     // renderer.

        // },

        animate() {

            // console.log('启动渲染wwww')
            // this.showPartInfo && OrbitControl,EnableRender,ViewModel

            requestAnimationFrame(this.animate);




            if (EnableRender) {
                this.render()


            }
        },

        render() {
            // this.showPartInfo && OrbitControl,EnableRender
            // if (this.mpdUrl && EnableRender) {


            //没有url则停止渲染

            // console.log('渲染999999')

            // console.log('启动渲染wwww1')


            //只有在零件信息查看不启用的时候才渲染
            // if (this.ChooseObj3D_obj == 0) {
            //     this.render();
            // }

            if (OrbitControl) {

                // if (!this.showPartInfo && OrbitControl) {

                // console.log('启动渲染wwww222')
                console.log('启动渲染wwww2222', scene, camera)
                OrbitControl.update();
                // this.render();
                stats.update();

                // userName = 
                // this.setRandomPosition(fileInfoShow)
                // console.log('scene, camera', scene, camera)

                renderer.render(scene, camera);
                TWEEN.update();
            }









        },


        // 设置随机位置
        // setRandomPosition(element) {
        //     var windowWidth = window.innerWidth;
        //     var windowHeight = window.innerHeight;

        //     // 随机位置
        //     var randomX = Math.floor(Math.random() * (windowWidth - element.offsetWidth));
        //     var randomY = Math.floor(Math.random() * (windowHeight - element.offsetHeight));

        //     // 设置样式
        //     element.style.position = 'absolute';
        //     element.style.left = randomX + 'px';
        //     element.style.top = randomY + 'px';
        // },


        async init() {


            console.log('document4444', document)
            container = document.querySelector("#three2");


            const width = window.innerWidth * 1;
            const height = window.innerHeight * 1;

            console.log('宽度：', width);
            console.log('高度：', height);
            this.MPDdivWidth = width

            camera = new THREE.PerspectiveCamera(
                45,
                width / height,
                1,
                10000
            );
            camera.position.set(150, 200, 250);


            // renderer = new THREE.WebGLRenderer({ antialias: true, depthBits: 32 });
            // renderer = new THREE.WebGLRenderer({ antialias: true });

            renderer = this.$root.$renderer


            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(width, height);
            // renderer.useLegacyLights = false;

            renderer.toneMapping = THREE.ACESFilmicToneMapping;

            // container = document.querySelector("#three2");


            console.log('container44443333', container)

            container.appendChild(renderer.domElement);
            //设置id，方便查找
            renderer.domElement.id = "ThreeCanvas";

            // scene

            const pmremGenerator = new THREE.PMREMGenerator(renderer);

            scene = new THREE.Scene();
            // #ecf0f1
            // scene.background = new THREE.Color(0xbdc3c7);

            scene.background = new THREE.Color(0x34495e);
            // scene.background = new THREE.Color(0x3c3c3c);

            // scene.background = new THREE.Color(0x2c3e50);

            scene.environment = pmremGenerator.fromScene(new RoomEnvironment(renderer)).texture;

            OrbitControl = new OrbitControls(camera, renderer.domElement);



            stats = new Stats();
            // stats = new Stats();
            stats.domElement.style.position = "absolute"; //绝对坐标
            stats.domElement.style.right = "0px"; // (0,0)px,左上角
            stats.domElement.style.top = "200px";
            stats.domElement.style.zIndex = "1000"
            stats.domElement.style.width = 'min-content'

            stats.domElement.id = "statsView"
            container.appendChild(stats.domElement);

            console.log('stateDIV', document.getElementById("statsView"))


            //零件图片创建器
            const part_Container = document.querySelector("#canvas_part_list");
            mpdPartListCreater = new MpdPartListCreater(part_Container)
            DoStepAnimationPlayer = new DoStepAnimation()

            // window.addEventListener("resize", this.onWindowResize);

            progressBarDiv = document.createElement("div");
            progressBarDiv.innerText = "Loading...";
            progressBarDiv.style.fontSize = "3em";
            progressBarDiv.style.color = "#888";
            progressBarDiv.style.display = "block";
            progressBarDiv.style.position = "absolute";
            progressBarDiv.style.top = "50%";
            progressBarDiv.style.width = "100%";
            progressBarDiv.style.textAlign = "center";

            // load materials and then the model
            // mySoftPartBoneSetter = new MySoftPartBoneSetter();
            // myRubber_Band_Calculator = new MyRubber_Band_Calculator()

            // this.reloadObject(true, this.mpdUrl);



            //创建软零件骨骼曲线生成器
        },


        async reloadObject(resetCamera, downloadedURL, textMpd = null) {
            let that = this;

            // this.currentStepNum = 1
            this.stepShow = 1

            this.stepShow_input = this.stepShow

            this.modelStepTotal = 1




            if (downloadedURL == null && textMpd == null) { return }

            // if (downloadedURL == null) {
            //     downloadedURL = 'https://636c-cloudbase-baas-5g5r8gfv6e64cb31-1307054034.tcb.qcloud.la/mpdtest/buyingcao_mpd5.mpd?sign=a1c2b5722158f3974a96ba29d2dae373&t=1689346101'
            // }

            // downloadedURL = "https://636c-cloudbase-baas-5g5r8gfv6e64cb31-1307054034.tcb.qcloud.la/files/1702829848028.mpd?sign=2a64531d9e6b5134eaafee200845e047&t=1702829919"

            console.log('重新加载下载url', downloadedURL)
            // downloadedURL == null ? guiData.modelFileName = guiData.modelFileName : guiData.modelFileName =downloadedURL,

            if (model) {
                scene.remove(model);
            }

            model = null;

            that.updateProgressBar(0);
            that.showProgressBar();

            // only smooth when not rendering with flat colors to improve processing time

            //不要每次重新加载模型都需要新建对象
            if (lDrawLoader == null) {
                console.log('空对象，生成新对象', lDrawLoader)
                lDrawLoader = new LDrawLoader();

                console.log('获取下载链接')

                //获取颜色零件的临时链接
                // const tempurl = await app.getTempFileURL({
                //     fileList: [
                //         "cloud://cloudbase-baas-5g5r8gfv6e64cb31.636c-cloudbase-baas-5g5r8gfv6e64cb31-1307054034/ColorsFile/colors.txt",
                //     ],
                // });
                // console.log("tempurl", tempurl);

                // console.log("colorsFIle", colorsFIle);

                //
                // let res
                // try {
                //     //预加载材质,加载完成后将文件返回回来，用于生产colorPicker数据
                //     res = await this.$root.$MyCloudFiles.getObject('colors.txt', (onprogress) => {

                //         console.log('材质文件下载进度888', onprogress)
                //     })
                // } catch (err) {
                //     res = this.$root.$app.downloadFile({
                //         fileID: "cloud://cloudbase-baas-5g5r8gfv6e64cb31.636c-cloudbase-baas-5g5r8gfv6e64cb31-1307054034/ColorsFile/colors.txt"
                //     }).then((res) => {
                //         console.log('颜色文件下载结果',res);
                //     });


                // }

                // console.log('材质文件下载结果', res)

                // if (!res) {

                //     console.log('材质文件下载错误')

                //     return

                // }

                // const colorFile = await lDrawLoader.preloadMaterials(res.Body);

                console.log('materialLibraryTXT.txt33', materialLibraryTXT.txt)
                const colorFile = await lDrawLoader.preloadMaterials(materialLibraryTXT.txt);

                // ColorMaterialLibrary
                console.log('11colorFile11', colorFile)

                // lDrawLoader.getmpdText()
                //生成颜色文件传递给colorpicker
                // this.createColorsDataForColorPicker(colorFile)

                console.log("materialLibrary", lDrawLoader.materialLibrary);

            }



            // lDrawLoader.smoothNormals = guiData.smoothNormals && !guiData.flatColors;
            lDrawLoader.smoothNormals = false
            if (textMpd != null) {
                //如果是文本加载
                // that.loading = true

                // let TotalPartNum = that.countTotalPart(textMpd)
                // let time = (TotalPartNum / 40).toFixed(0)
                // if (TotalPartNum == 0) {

                //     that.$message({
                //         message: '文件中不包含任何零件',
                //         type: 'warning'
                //     });
                //     loading.close()
                //     loading = null
                //     // that.loading = false
                //     return

                // } else if (TotalPartNum <= 5000) {

                //     that.loadingText = '文件解析中,共' + TotalPartNum + "块零件,大约需要" + time + "秒"

                // } else {

                //     that.$message({
                //         message: '不支持5000块以上零件',
                //         type: 'warning'
                //     });
                //     loading.close()
                //     loading = null
                //     // that.loading = false
                //     return

                // }



                // setTimeout(() => {



                // }, 150);
                // await this.delay(100);


                //
                console.log('以文件txt的方式加载3D对象', textMpd)

                lDrawLoader.loadByText(

                    textMpd,

                    async function (group2) {
                        // that.afterLoadDo(resetCamera, group2)

                        // this.loadingText = '文件解析完成,添加到场景中'
                        await that.afterLoadDo(resetCamera, group2)

                        // this.animate()


                    },
                    // that.onProgress,
                    this.onError
                );





            } else {
                //否则就是下载链接加载

                lDrawLoader.setPath(ldrawPath).load(

                    downloadedURL,

                    function (group2) {

                        // that.loading = true

                        setTimeout(() => {

                            that.afterLoadDo(resetCamera, group2)

                            // this.animate()

                        }, 100);

                    },
                    that.onProgress,
                    that.onError
                );

            }

        },

        //模型文件下载后的操作

        async afterLoadDo(resetCamera, group2) {

            // let that = this

            if (model) {

                model.removeFromParent()
                // scene.remove(model);
            }

            model = group2;

            console.log('model333333', model)

            // this.ChooseObj3D_obj = model.clone()

            // console.log('this.ChooseObj3D_obj',this.ChooseObj3D_obj)


            scene.add(model);







            model.rotation.x = Math.PI;


            // model.updateMatrixWorld()

            // 找到缺失零件
            this.findNotFindPartInModel();
            // 找到缺失材质

            console.log('缺失材质编号', lDrawLoader.getMissingColor())

            stepNumber = 0;
            partAndGroupArr = []
            partArr = []
            groupArr = []
            this.computeConstructionSteps(model);

            model.userData.numBuildingSteps = stepNumber;

            this.maxStepPath = model.userData.ThisFloorStepNumMax + 1

            //生成2-1-2这样的路径步骤存到零件userdata中
            for (let i = 0; i < partAndGroupArr.length; i++) {

                partAndGroupArr[i].userData.stepPath = this.CreateStepByFloor(partAndGroupArr[i])

            }

            showGroup = model;

            console.log(showGroup)

            //保存所有材质
            this.saveArrMaterial(model);



            this.placePositionAndRotation()



            console.log("接收到步骤图片列表", this.leftstepList_Arr);



            this.modelStepTotal = model.userData.numBuildingSteps;

            console.log("modelwwww", model);

            if (!DragControl) {
                this.initdragcontrols();
            }


            // if(!Transformcontrol){
            //     this.initTransformControls();

            // }




            //20240625手动移除，不知道有没有坏处
            //这个不移除重新用分享码打开会报错
            // this.updateBox3Helpers(model, false);



            this.hideProgressBar();

            this.LeftScrollMaxStep = model.userData.ThisFloorStepNumMax + 1;
            this.LeftScrollChooseStep = 1;



            if (resetCamera) {

                this.resetCameraForModel()

            }


            //备份组的安装世界矩阵
            this.backupGroupInstallationInfo(model);

            const axesHelper = new THREE.AxesHelper(50);
            scene.add(axesHelper);

            //从text中查找到模型的名称
            this.findModelName()

            //检查模型有没有被子孙设置旋转
            this.setModelRotateData()

            //应该是标记缺失零件
            this.tipMissingParts()

            this.backupPartPositionAndPosition(model)

            // this.loa = '生成零件图'
            DoStepAnimationPlayer.setModel(model)
            //按照最后一步步骤来隐藏替身
            DoStepAnimationPlayer.setStandInChildrenVisibleInStandInGroup(model.userData.numBuildingSteps)

            loading.close()
            loading = null

            loading = this.$loading({
                lock: true,
                text: '生成零件图,马上就好',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            //等待一会儿，否则提示可能不出现
            await this.delay(100);
            //生成所有零件的图片
            await this.createAllPartImage()

            //生成所具有可见性数据在所有全局步骤中的可见性
            this.partVisibleSetByStep()

            this.setStep(1);

            const h = this.$createElement;
            // 提示
            this.$notify({
                title: '小提示',
                duration: 2000,
                message: h('i', { style: 'color: teal' }, '如果加载出来的模型出现拉丝、穿孔或者坍塌的情况，出现这类问题可更换设备或者更新GPU驱动')
            });


            // this.showPartInfo && OrbitControl,EnableRender,ViewModel

            console.log('ViewModel', ViewModel)
            console.log('this.showPartInfo', this.showPartInfo)

            console.log('OrbitControl', OrbitControl)

            console.log('EnableRender', EnableRender)
            console.log('renderer', renderer)
            console.log('domElement', renderer.domElement)


            ViewModel = true







            loading.close()
            loading = null







        },


        //等待
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        // async exampleFunction() {
        //     console.log('Start');

        //     // 使用await等待delay函数完成
        //     await delay(2000); // 等待2秒

        //     console.log('End'); // 这句话会在2秒后打印
        // },

        // exampleFunction();


        //关闭零件查看视图
        closePartInfoView() {
            renderer = this.$root.$renderer


            const width = window.innerWidth * 1;
            const height = window.innerHeight * 1;

            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(width, height);
            // renderer.useLegacyLights = false;

            // renderer.toneMapping = THREE.ACESFilmicToneMapping;

            // container = document.querySelector("#three2");


            // console.log('container44443333', container)

            container.appendChild(renderer.domElement);
            //设置id，方便查找
            renderer.domElement.id = "ThreeCanvas";



            console.log('主组建接收到零件查看关闭')

            this.showPartInfo = false

            EnableRender = true

            partInfoView_isVisible = false

            //关闭零件查看界面
            // this.$emit("messageToPartInfoView", { type: "closePartInfoView"});

        },







        //模型文件下载后的操作
        // afterLoadDo__(resetCamera, group2) {





        //     // let that = this

        //     if (model) {
        //         scene.remove(model);
        //     }

        //     model = group2;

        //     // const part = model.children[0]

        //     // return

        //     //1600
        //     // for (let i = 0; i < 1600; i++) {

        //     //     model.add(part.clone());

        //     //     part.position.set(Math.random() * 300, Math.random() * 300, Math.random() * 300)

        //     // }

        //     // console.log('克隆了几百个', model)


        //     scene.add(model);

        //     // group2.updateMatrixWorld(true, true)



        //     model.rotation.x = Math.PI;
        //     // model.updateMatrix()
        //     // model.updateMatrixWorld()

        //     // 找到缺失零件
        //     this.findNotFindPartInModel();
        //     // 找到缺失材质
        //     // this.findMissingMaterialInModel(model);
        //     console.log('缺失材质编号', lDrawLoader.getMissingColor())

        //     // demonstrate how to use convert to flat colors to better mimic the lego instructions look
        //     // if (false) {
        //     //     model.traverse((c) => {
        //     //         if (c.isMesh) {
        //     //             if (Array.isArray(c.material)) {
        //     //                 c.material = c.material.map(this.convertMaterial);
        //     //             } else {
        //     //                 c.material = this.convertMaterial(c.material);
        //     //             }
        //     //         }
        //     //     });
        //     // }

        //     // Merge model geometries by material
        //     // if (guiData.mergeModel) model = LDrawUtils.mergeObject(model);

        //     // Convert from LDraw coordinates: rotate 180 degrees around OX


        //     stepNumber = 1;
        //     partAndGroupArr = []
        //     partArr = []
        //     groupArr = []
        //     this.computeConstructionSteps(model);

        //     model.userData.numBuildingSteps = stepNumber;

        //     this.maxStepPath = model.userData.ThisFloorStepNumMax + 1

        //     // this.maxStepPath = 

        //     //这句代码不删除的话会暴露文件地址
        //     // model.userData.fileName = "DaDaStar";

        //     //生成2-1-2这样的路径步骤存到零件userdata中
        //     for (let i = 0; i < partAndGroupArr.length; i++) {

        //         partAndGroupArr[i].userData.stepPath = this.CreateStepByFloor(partAndGroupArr[i])

        //     }




        //     showGroup = model;

        //     console.log(showGroup)

        //     //保存所有材质
        //     this.saveArrMaterial(model);

        //     //传入视图容器
        //     // part_Container = document.querySelector("#canvas_part_list");
        //     // partListCreater = new StepListCreater(part_Container);

        //     this.placePositionAndRotation()

        //     // this.leftstepList_Arr = partListCreater.CreatePartListImagesArr(model);
        //     //为所有的模型添加boxhelper
        //     // bottomPartListCreater = new BottomPartListCreater(part_Container);
        //     // addbox3Helper = new Addbox3Helper()

        //     console.log("接收到步骤图片列表", this.leftstepList_Arr);

        //     // if (model.userData.numBuildingSteps > 0) {
        //     //   this.setstep(2);
        //     // }

        //     this.modelStepTotal = model.userData.numBuildingSteps;

        //     console.log("modelwwww", model);
        //     this.initdragcontrols();

        //     this.initTransformControls();

        //     // guiData.buildingStep = model.userData.numBuildingSteps - 1;

        //     // this.updateObjectsVisibility();

        //     // Adjust camera and light

        //     this.updateBox3Helpers(model, false);


        //     // this.


        //     // this.createGUI();

        //     this.hideProgressBar();

        //     this.LeftScrollMaxStep = model.userData.ThisFloorStepNumMax + 1;
        //     this.LeftScrollChooseStep = 1;




        //     if (resetCamera) {
        //         this.resetCameraForModel()
        //     }




        //     //后期开启，这个现在不需要
        //     // this.makeAnimationDataForPart(model);

        //     //生成假的userdata id
        //     // this.MakeUserDataId(model)

        //     //保存零件内的userdata id
        //     // this.saveAllUserdataId(model)

        //     //备份组的安装世界矩阵
        //     this.backupGroupInstallationInfo(model);

        //     const axesHelper = new THREE.AxesHelper(50);
        //     scene.add(axesHelper);

        //     // this.CreateLins()

        //     //创建贝塞尔控制器
        //     // this.CreateBezierControler();



        //     //从text中查找到模型的名称
        //     this.findModelName()




        //     //检查模型有没有被子孙设置旋转
        //     this.setModelRotateData()


        //     //应该是标记缺失零件
        //     this.tipMissingParts()

        //     //根据零件或者组的userdata中的动画数据（移动和旋转）生成tween动画数据放到零件的userdata中
        //     // this.CreateStepPartsTweenDataSaveInPart()


        //     this.backupPartPositionAndPosition(model)

        //     //生成所有零件的图片
        //     this.createAllPartImage()

        //     //生成所具有可见性数据在所有全局步骤中的可见性
        //     this.partVisibleSetByStep()

        //     this.setStep(1);

        //     const h = this.$createElement;
        //     // 提示
        //     this.$notify({
        //         title: '小提示',
        //         duration: 2000,
        //         message: h('i', { style: 'color: teal' }, '如果加载出来的模型出现拉丝、穿孔或者坍塌的情况，出现这类问题可更换设备或者更新GPU驱动')
        //     });

        //     loading.close()
        //     loading = null

        // },

        //为模型的所有零件生成图片
        // async createAllPartImage() {
        //     //找到所有零件，不需要重复的零件，颜色也不能重复
        //     let PartsArr_ = []
        //     for (let i = 0; i < partArr.length; i++) {


        //         //
        //         let inBool = false
        //         for (let j = 0; j < PartsArr_.length; j++) {

        //             if (PartsArr_[j].userData.fileName == partArr[i].userData.fileName) {
        //                 if (PartsArr_[j].userData.colorCode == partArr[i].userData.colorCode) {

        //                     inBool = true

        //                     break
        //                 }
        //             }

        //         }

        //         if (inBool == false) {
        //             PartsArr_.push(partArr[i])
        //         }

        //         // PartsArr_[0]

        //     }

        //     console.log('生成零件列表的准备零件是:', PartsArr_)
        //     await mpdPartListCreater.CreatePartListImagesArr(PartsArr_)

        // },

        // partAndGroupArr
        async createAllPartImage() {
            //找到所有零件，不需要重复的零件，颜色也不能重复
            let PartsArr_ = []
            for (let i = 0; i < partAndGroupArr.length; i++) {


                //
                let inBool = false
                for (let j = 0; j < PartsArr_.length; j++) {

                    if (partAndGroupArr[i].userData.TYPE && partAndGroupArr[i].userData.TYPE == 'PART') {

                        if (PartsArr_[j].userData.fileName == partAndGroupArr[i].userData.fileName) {
                            if (PartsArr_[j].userData.colorCode == partAndGroupArr[i].userData.colorCode) {

                                inBool = true

                                break
                            }
                        }

                    } else if (partAndGroupArr[i].userData.TYPE && partAndGroupArr[i].userData.TYPE == 'GROUP') {

                        if (PartsArr_[j].userData.fileName == partAndGroupArr[i].userData.fileName) {


                            inBool = true

                            break

                        }

                    }



                }

                if (inBool == false) {
                    PartsArr_.push(partAndGroupArr[i])
                }

                // PartsArr_[0]

            }

            console.log('生成零件列表的准备零件是:', PartsArr_)

            await mpdPartListCreater.CreatePartListImagesArr(PartsArr_)

        },


        //将摄像机和orbit控制器重心设置到模型中心点，同时最大化显示模型
        resetCameraForModel() {


            // OrbitControl.target0.copy(bbox.getCenter(new THREE.Vector3()));
            // OrbitControl.position0
            //     .set(-2.3, 1, 2)
            //     .multiplyScalar(radius)
            //     .add(OrbitControl.target0);
            // OrbitControl.reset();

            // this.intSelectBox();
            model.updateWorldMatrix(true, true)
            const bbox = new THREE.Box3().setFromObject(model);
            // bbox.update ()
            // const size = bbox.getSize(new THREE.Vector3());
            // const radius = Math.max(size.x, Math.max(size.y, size.z)) * 0.5;

            let BindSphere = new THREE.Sphere()
            bbox.getBoundingSphere(BindSphere)

            let radius = BindSphere.radius
            //竖屏下得将尺寸重新算
            // 获取对应的 div 元素
            const div = this.$refs.mpdview;
            // 获取宽高
            const canvasWidth = div.offsetWidth;
            const canvasHeight = div.offsetHeight;
            if (canvasWidth < canvasHeight) {
                radius = radius * (canvasHeight / canvasWidth);
            }


            // const radius = Math.max(size.x, Math.max(size.y, size.z)) * 0.5;



            let cameraLookAt = BindSphere.center.clone()






            const fieldOfView = camera.fov; //
            //* 1.2 这是个系数
            let distance = (radius / 2) * 1 / Math.tan((Math.PI / 180) * fieldOfView / 2) * 2;



            //拍摄角度
            //水平角度
            const horizontalAngle = 135
            //垂直角度
            const verticalAngle = -15


            const cameraPosition = {
                x: cameraLookAt.x - distance * Math.sin((Math.PI / 180) * horizontalAngle),
                y: cameraLookAt.y - distance * Math.sin((Math.PI / 180) * verticalAngle),
                z: cameraLookAt.z - distance * Math.cos((Math.PI / 180) * horizontalAngle),
            };

            // const geometry = new THREE.BoxGeometry(100, 100, 100);
            // const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
            // const cube = new THREE.Mesh(geometry, material);
            // scene.add(cube);
            // cube.position.copy(cameraLookAt)

            // const geometry = new THREE.SphereGeometry(radius, 32, 16);
            // const material = new THREE.MeshBasicMaterial({ color: 0xffff00 });
            // const sphere = new THREE.Mesh(geometry, material);
            // scene.add(sphere);
            // sphere.position.copy(cameraLookAt)


            camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
            camera.lookAt(cameraLookAt);


            // camera.lookAt(cameraAnimationTweenArr[i].lookat)

            camera.updateProjectionMatrix()

            OrbitControl.target.copy(cameraLookAt);

            OrbitControl.update();


        },

        //备份零件的安装位置
        backupPartPositionAndPosition(Group) {

            const backUpDo = function (group) {

                for (let i = 0; i < group.children.length; i++) {

                    if (group.children[i].userData.TYPE && (group.children[i].userData.TYPE == 'PART' || group.children[i].userData.TYPE == 'GROUP')) {

                        group.children[i].updateMatrix()
                        group.children[i].updateWorldMatrix(true, true)

                        console.log('22备份安装位置是', group.children[i].position)
                        console.log('22备份安装旋转是', group.children[i].rotation)


                        //备份零件的位置和旋转
                        group.children[i].userData.placePosition = {
                            x: group.children[i].position.x,
                            y: group.children[i].position.y,
                            z: group.children[i].position.z,
                        };
                        // Group_.children[i].position.clone()
                        // group.children[i].userData.placeRotation = {
                        //     x: group.children[i].rotation.x,
                        //     y: group.children[i].rotation.y,
                        //     z: group.children[i].rotation.z,
                        //     w: group.children[i].rotation.w,
                        // };


                        //备份安装旋转四元数：quaternion
                        // Group_.children[i].position.clone()
                        group.children[i].userData.placeQuaternion = {
                            x: group.children[i].quaternion.x,
                            y: group.children[i].quaternion.y,
                            z: group.children[i].quaternion.z,
                            w: group.children[i].quaternion.w,

                        };

                    }
                    if (group.children[i].userData.TYPE && group.children[i].userData.TYPE == 'GROUP') {

                        backUpDo(group.children[i])

                    }

                }

            }

            backUpDo(Group)

        },


        /**
        * 将下载下来的颜色文件处理，为颜色选择器提供颜色选择参数
        * 
        * 将颜色分为 塑料颜色,橡胶颜色，透明颜色，金属颜色
        */
        // createColorsDataForColorPicker(colorFile) {
        //     console.log('颜色文件colorFile', colorFile)
        //     //将文件按照 0 //
        //     let Colours = []
        //     colorFile = colorFile.replace(/\r\n/g, '\n');

        //     let classifyColors = colorFile.split('0 //')

        //     for (let j = 0; j < classifyColors.length; j++) {


        //         // 将文本按行分割成数组
        //         let lines = classifyColors[j].split("\n");

        //         console.log('lines222', lines)
        //         // const nameLineArr = lines[0].split(' ')
        //         //移除第一位空格
        //         const colorType = lines[0].substring(1)

        //         //没有类型则为空，不存储
        //         if (colorType == '') { continue }
        //         //组合类型名称
        //         console.log('colorType', colorType)
        //         // let colorType = nameLineArr[nameLineArr.length - 1]
        //         // 定义颜色数组
        //         let colors = [];
        //         // 遍历每一行文本
        //         for (let i = 0; i < lines.length; i++) {
        //             let line = lines[i];

        //             // 判断是否为颜色行
        //             if (line.startsWith("0 !COLOUR")) {
        //                 //多个空格变成一个空格
        //                 let newStr = line.replace(/\s+/g, ' ');


        //                 // 提取颜色名称、颜色数字代号和颜色16进制编号
        //                 let colorName = newStr.split(" ")[2];
        //                 let colorNum = newStr.split(" ")[4];
        //                 let colorCode16 = newStr.split(" ")[6];

        //                 // 创建颜色对象并添加到颜色数组中
        //                 let color = {
        //                     colour: colorName,
        //                     colourNum: colorNum,
        //                     colourCode16: colorCode16
        //                 };
        //                 colors.push(color);
        //             }
        //         }

        //         Colours.push({
        //             type: colorType,
        //             colors
        //         })

        //     }

        //     console.log('Colours43333', Colours)

        //     this.Colours = Colours


        //     // materials
        //     // const text = colorFile
        //     // const colorLineRegex = /^0 !COLOUR/;
        //     // const lines = text.split(/[\n\r]/g);
        //     // const materials = [];
        //     // for (let i = 0, l = lines.length; i < l; i++) {

        //     //   const line = lines[i];
        //     //   if (colorLineRegex.test(line)) {

        //     //     const directive = line.replace(colorLineRegex, '');
        //     //     const material = this.parseColorMetaDirective(new LineParser(directive));
        //     //     materials.push(material);

        //     //   }

        //     // }

        //     // console.log('materials11122', materials)

        // },

        //找到缺失零件并替换成box
        // MyNoPartBox
        findNotFindPartInModel() {
            // let nofindPart = [];
            let nopartname = ''
            // model.traverse((c) => {
            //     //后期可以将MyNoPartBox 属性放在别的位置，毕竟作者项本来是有其他作用的
            //     if (c.isGroup && c.userData.MissingReplacement) {
            //         const geometry = new THREE.BoxGeometry(40, 40, 40);
            //         // const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
            //         const material = lDrawLoader.materialLibrary['4']

            //         const cube = new THREE.Mesh(geometry, material);

            //         cube.userData.colorCode = ['4'];

            //         cube.userData.backupColorCode = ['4'];

            //         const Group = new THREE.Group();

            //         Group.add(cube);
            //         // scene.add(cube);
            //         c.parent.add(Group);
            //         Group.position.copy(c.position);
            //         Group.rotation.copy(c.rotation);
            //         Group.userData = c.userData;
            //         Group.name = c.name;

            //         if (nofindPart.indexOf(c) == -1) {

            //             nofindPart.push(c);

            //             nopartname += Group.name + "、"


            //             console.log("找到缺失零件了", c);

            //         }


            //     }
            // });


            let nofindPart = lDrawLoader.getMissingPartsArr()

            console.error('缺失零件', nofindPart)

            //有缺失零件才提醒
            if (nofindPart.length > 0) {

                // this.$alert('这是一段内容', '标题名称', {
                //   confirmButtonText: '确定',
                //   callback: action => {
                //     this.$message({
                //       type: 'info',
                //       message: `action: ${ action }`
                //     });
                //   }
                // });

                for (let i = 0; i < nofindPart.length; i++) {

                    nopartname += nofindPart[i] + '、'

                }



                this.$alert('缺失总数' + nofindPart.length + ':' + nopartname + '缺失零件将用红色方块代替,请联系ldr文件的作者获取缺失零件!', {
                    confirmButtonText: '确定',
                    callback: action => {
                        console.log(action)
                        // this.$message({
                        //   type: 'info',
                        //   message: `action: ${ action }`
                        // });
                    }
                });
            }


            //缺失零件被正方体替换移除所有替换零件
            // for (let i in nofindPart) {
            //     nofindPart[i].removeFromParent();
            // }
        },

        //重新标记步骤
        //ldraw加载器的步骤不符合我的需求
        //传入零件组和这级的开始步骤（递归前的结束步骤）  还有开始的步骤
        computeConstructionSteps(group) {
            let ThisFloorStepNum = 0;

            let thisFloorHaveStep = false

            let i = 0;
            while (i < group.children.length) {
                //如果本层第一步就是startingBuildingStep=true，这会导致第一步为空，导致查找器找不到这个组
                //如果低于个组就开始startingBuildingStep也不正常，所以就干脆去除第一步空步骤
                if (group.children[0].userData.startingBuildingStep == true) {
                    // console.log("新添加组", group.children[0]);
                    group.children[0].userData.startingBuildingStep = false;
                }


                if (this.isDatString(group.children[i].name) || this.isLdrString(group.children[i].name)) {

                    //零件
                    if (group.children[i].userData.startingBuildingStep) {

                        // console.log("startingBuildingStep为true", group.children);

                        thisFloorHaveStep = true

                        stepNumber++;

                        ThisFloorStepNum++;

                    } else if (i == 0 && group.children[i].userData.startingBuildingStep == false && group.children.length > 0) {

                        // console.log("startingBuildingStep为false但我要加1", group.children[i]);
                        //这种情况是子组内的第一个a零件如果没有startingBuildingStep ，但是按照道理我们应该要为a它的步骤增加1
                        //零件才加，组不加，组是里面弄好后回来加的

                        stepNumber++;



                    }

                    // console.log("名字包含dat或者ldr", group.children[i].name);

                    group.children[i].userData.TYPE = "PART";

                    partArr.push(group.children[i])

                    partAndGroupArr.push(group.children[i])


                    group.children[i].userData.MyStep = stepNumber;

                    group.children[i].userData.ThisFloorStepNum = ThisFloorStepNum;
                } else if (group.children[i].userData.type && group.children[i].userData.type == "Model") {
                    //组
                    //零件
                    if (group.children[i].userData.startingBuildingStep) {

                        console.log("startingBuildingStep为true", group.children);

                        thisFloorHaveStep = true

                        // stepNumber++;

                        ThisFloorStepNum++;

                    }

                    if (group.children[i].isGroup) {
                        // stepNumber = 1
                        // ThisFloorStepNum = 1

                        group.children[i].userData.TYPE = "GROUP";

                        groupArr.push(group.children[i])
                        partAndGroupArr.push(group.children[i])

                        //子组内是否有步骤
                        const ChildrenFloorHaveStep = this.computeConstructionSteps(group.children[i]);

                        if (ChildrenFloorHaveStep) {
                            stepNumber++;

                        }


                        group.children[i].userData.MyStep = stepNumber;

                        group.children[i].userData.ThisFloorStepNum = ThisFloorStepNum;

                    }
                }

                i++;
            }

            group.userData.ThisFloorStepNumMax = ThisFloorStepNum;

            return thisFloorHaveStep

        },

        //传入一个零件，通过找父级的方式，生成1-1-2这种格式的步骤
        CreateStepByFloor(PartOrGroup) {
            const loop = true

            let StepPath = ''

            StepPath = PartOrGroup.userData.ThisFloorStepNum + 1 + ''

            // let parent = PartOrGroup.parent

            let parent = PartOrGroup.parent



            while (loop) {




                //不是主模型
                if (parent.parent == scene) {
                    //     console.log
                    // if (parent == scene||parent.parent == scene) {


                    break


                } else {

                    StepPath = parent.userData.ThisFloorStepNum + 1 + '-' + StepPath

                }

                //继续往上
                parent = parent.parent

            }



            console.log('步骤路径:', StepPath)

            return StepPath






        },

        // 判断字符串是否以".dat"结尾，并且可能带有n个空格
        isDatString(str) {
            // 使用正则表达式进行匹配
            const regex = /\.dat\s*$/;
            return regex.test(str);
        },
        // 判断字符串是否以".ldr"结尾，并且可能带有n个空格
        isLdrString(str) {
            // 使用正则表达式进行匹配
            const regex = /\.ldr\s*$/;
            return regex.test(str);
        },

        /***
        * 保存材质
        */
        saveArrMaterial(Group) {
            let that = this
            // materialArr
            // group.traverse(c=>{
            //     if(c.){

            //     }
            // })
            Group.traverse(function (object) {
                // let materialNameArr = [];

                // 检查如果对象是一个网格对象
                if (object.isMesh) {
                    // console.log('mesh11223', object)

                    // 检查对象是否有材质，并且材质不为空
                    if (object.material) {
                        if (object.material.length > 1) {
                            // object.userData.materialNameArr = [];
                            object.userData.colorCode = [];

                            // 遍历对象的每个材质
                            object.material.forEach(function (material) {
                                // 检查材质是否已经在分类中存在
                                // if (!materialArr[material.name]) {
                                //   // 如果不存在，将材质添加到分类中
                                //   materialArr[material.name] = material.clone();
                                // }
                                // object.userData.materialNameArr.push(material.name);
                                object.userData.colorCode.push(material.userData.code);

                                // console.log("material.name", material.name);
                                // materialNameArr.push(material.name);
                            });
                        } else {
                            // if (!materialArr[object.material.name]) {
                            //   // 如果不存在，将材质添加到分类中
                            //   materialArr[object.material.name] = object.material.clone();
                            // }

                            // object.userData.materialName = object.material.name;
                            object.userData.colorCode = [];
                            object.userData.colorCode.push(object.material.userData.code);

                            // console.log('object.material', object.material.name)
                        }
                    }
                    // console.log('materialNameArr',materialNameArr)
                    // 将材质名数组保存到userData中
                    // object.userData.materialNameArr = materialNameArr;

                    object.userData.backupColorCode = that.copyArray(object.userData.colorCode)
                    // this.copy
                } else if (object.isGroup) {

                    //组也要备份颜色
                    if (object.userData.colorCode) {

                        object.userData.backupColorCode = that.copyArray(object.userData.colorCode)

                    }

                }
            });

            // console.log("materialArr", materialArr);
        },

        /**
        * 复制数组
        * 元素内不能有对象哈
        */
        copyArray(array) {

            console.log('Array6666', array)
            if (!Array.isArray(array)) {

                //传入的不是数组则原样返回
                return array

            }
        },

        //还原零件的安装位置和安装旋转
        placePositionAndRotation() {

            //还原到安装位置后再生成图片
            model.traverse(c => {
                if (c.isGroup) {
                    if (c.userData.TYPE == 'GROUP' || c.userData.TYPE == 'PART') {
                        if (c.userData.placePosition && c.userData.placeRotation) {

                            console.log('placePosition5555 ', c.userData.placePosition)
                            console.log('placeRotation5555 ', c.userData.placeRotation)

                            let placePosition = c.userData.placePosition
                            // let placeRotation = c.userData.placeRotation
                            let placeQuaternion = c.userData.placeQuaternion

                            //设四元数稳定些，设欧拉角会有极少数情况的旋转错误，比如调试使用的相机模型
                            c.position.set(placePosition.x, placePosition.y, placePosition.z)

                            // c.rotation.set(placeRotation.x, placeRotation.y, placeRotation.z)

                            //设四元数稳定些，设欧拉角会有极少数情况的旋转错误，比如调试使用的相机模型
                            c.quaternion.set(
                                placeQuaternion.x,
                                placeQuaternion.y,
                                placeQuaternion.z,
                                placeQuaternion.w
                            )

                            // placeQuaternion

                            // c.updateMatrix()

                        }
                    }
                }

            })

        },

        initdragcontrols() {
            // let that = this

            console.log('初始化dragcontrol')

            console.log("model2", model);

            DragControl = new DragControls(model.children, camera, renderer.domElement);

            DragControl.enabled = false;

            DragControl.addEventListener("hoveron", function (event) {
                console.log("hoveron", event);
            });

            DragControl.addEventListener("hoveroff", function (event) {
                console.log("hoveroff", event);
            });

            DragControl.addEventListener("dragstart", function (event) {
                console.log("dragstart", event);

                // event.object.material.emissive.set(0xaaaaaa);
            });

            DragControl.addEventListener("dragend", function (event) {
                console.log("dragend", event);

                // event.object.material.emissive.set(0x000000);
            });
        },

        //操作旋转移动控制器初始化
        // initTransformControls() {
        //     let that = this;

        //     Transformcontrol = new TransformControls(camera, renderer.domElement);

        //     // Transformcontrol.addEventListener('change', renderer);

        //     Transformcontrol.addEventListener("dragging-changed", function (event) {
        //         console.log("event", event);

        //         // showGroup.

        //         // geometry_Helper_Rotate

        //         // OrbitControl.enabled = !event.value;

        //         // DragControl.enabled = !event.value;
        //     });

        //     Transformcontrol.addEventListener("mouseUp", function (event) {
        //         console.log("eventUP", event);

        //         //由于零件被拖动旋转，所以需要更新以正常求出模型当前显示部分的包围框（求摄像机的直视点）
        //         // model.updateMatrixWorld();

        //         // console.log("更新model");

        //         // if (that.viewRotateMode === true) {
        //         //     return;
        //         // }
        //         // //这些情况不刷新左侧图片步骤列表
        //         // if (that.TransformRotateMode || that.transformDragMode) {
        //         //     //旋转移动模型
        //         //     return;
        //         // } else if (that.viewRotateMode) {
        //         //     //临时旋转模式
        //         //     return;
        //         // }

        //         // OrbitControl.enabled = !event.value;

        //         // DragControl.enabled = !event.value;
        //         // that.loading = true;
        //         // that.placePositionAndRotation()
        //         // 更新左侧菜单栏
        //         // that.leftstepList_Arr = partListCreater.CreatePartListImagesArr(showGroup);

        //         // console.log("更新完成左侧列表图");

        //         // that.loading = false;
        //     });
        //     // change

        //     Transformcontrol.addEventListener("change", function (event) {
        //         // if (!showGroup) { return }
        //         // if (!geometry_Helper_Rotate) { return }

        //         console.log("Transformcontrol_change", event);

        //         let TransformObj;

        //         if (that.TransformRotateMode || that.transformDragMode) {
        //             //旋转移动模型
        //             TransformObj = translatePart;
        //         } else if (that.viewRotateMode) {
        //             //临时旋转模式
        //             TransformObj = showGroup;
        //         }

        //         if (!TransformObj) {
        //             return;
        //         }

        //         //如果没有生成旋转帮助盒子则返回
        //         // if (!geometry_Helper_Rotate) {
        //         //   return;
        //         // }

        //         console.log("等待被操控的零件是", TransformObj);

        //     });


        // },

        updateBox3Helpers(group, Visible) {
            for (let i in group.children) {
                if (
                    group.children[i].name.indexOf(".ldr") != -1 ||
                    group.children[i].name.indexOf(".dat") != -1
                ) {
                    const cloneobj = group.children[i].clone();

                    cloneobj.rotation.set(Math.PI, 0, 0);

                    cloneobj.position.set(0, 0, 0);

                    let boxhelper = this.addBox3(cloneobj);

                    boxhelper.name = "MyBoxHelper";

                    // console.log('box31122', boxhelper)

                    group.children[i].add(boxhelper);

                    boxhelper.visible = Visible;
                } else if (group.children[i].userData.type === "Model") {
                    const cloneobj = group.children[i].clone();

                    cloneobj.rotation.set(Math.PI, 0, 0);

                    cloneobj.position.set(0, 0, 0);

                    let boxhelpermodel = this.addBox3(cloneobj);

                    boxhelpermodel.name = "MyBoxHelper";

                    // console.log('box31122', boxhelpermodel)

                    group.children[i].add(boxhelpermodel);

                    boxhelpermodel.visible = Visible;

                    this.updateBox3Helpers(group.children[i], Visible);
                }
            }
        },
        //底部步骤栏
        handleCurrentStepChange(val) {
            // console.log(`当前页: ${val} `);
            // this.currentStepNum = val;
            this.stepShow = val

            // this.stepShow = stepShow_input*1
            // showGroup = null
            // this.setstep(val);
            this.setStep(val)
        },

        // setstep(Targetstep) {
        //     //先将所有的有步骤地组隐藏（可以是组也可以是零件）
        //     this.hideAllpart(model);
        //     let showgroup = this.findParentOfShowGroup(model, Targetstep);
        //     console.log("步骤显示组是", showgroup);
        //     //将除可见组的更高级别的组进行隐藏
        //     showgroup.visible = true;

        //     this.partVisible(showgroup, Targetstep);
        // },
        //为组添加box3
        addBox3(group) {
            // 创建包围盒相关参数
            let boxSize = new THREE.Vector3(); // 包围盒的尺寸
            let boxCenter = new THREE.Vector3(); // 包围盒的中心点

            let boxGroup = new THREE.Box3();

            boxGroup.expandByObject(group);

            boxGroup.getSize(boxSize);
            boxGroup.getCenter(boxCenter);

            //// 定义包围盒的8个顶点
            let vertices = [
                new THREE.Vector3(
                    boxCenter.x - boxSize.x / 2,
                    boxCenter.y - boxSize.y / 2,
                    boxCenter.z - boxSize.z / 2
                ),
                new THREE.Vector3(
                    boxCenter.x + boxSize.x / 2,
                    boxCenter.y - boxSize.y / 2,
                    boxCenter.z - boxSize.z / 2
                ),
                new THREE.Vector3(
                    boxCenter.x - boxSize.x / 2,
                    boxCenter.y + boxSize.y / 2,
                    boxCenter.z - boxSize.z / 2
                ),
                new THREE.Vector3(
                    boxCenter.x + boxSize.x / 2,
                    boxCenter.y + boxSize.y / 2,
                    boxCenter.z - boxSize.z / 2
                ),
                new THREE.Vector3(
                    boxCenter.x - boxSize.x / 2,
                    boxCenter.y - boxSize.y / 2,
                    boxCenter.z + boxSize.z / 2
                ),
                new THREE.Vector3(
                    boxCenter.x + boxSize.x / 2,
                    boxCenter.y - boxSize.y / 2,
                    boxCenter.z + boxSize.z / 2
                ),
                new THREE.Vector3(
                    boxCenter.x - boxSize.x / 2,
                    boxCenter.y + boxSize.y / 2,
                    boxCenter.z + boxSize.z / 2
                ),
                new THREE.Vector3(
                    boxCenter.x + boxSize.x / 2,
                    boxCenter.y + boxSize.y / 2,
                    boxCenter.z + boxSize.z / 2
                ),
            ];

            // 定义包围盒的12条边
            let edgesGeometry = new THREE.BufferGeometry().setFromPoints([
                vertices[0],
                vertices[1],
                vertices[1],
                vertices[3],
                vertices[3],
                vertices[2],
                vertices[2],
                vertices[0],
                vertices[4],
                vertices[5],
                vertices[5],
                vertices[7],
                vertices[7],
                vertices[6],
                vertices[6],
                vertices[4],
                vertices[0],
                vertices[4],
                vertices[1],
                vertices[5],
                vertices[2],
                vertices[6],
                vertices[3],
                vertices[7],
            ]);

            // 创建包围盒的线段
            // let MyBoxHelperedgesMaterial = new THREE.LineBasicMaterial({ color: 0x00ff00 });
            let edges = new THREE.LineSegments(edgesGeometry, MyBoxHelperedgesMaterial.clone());

            scene.add(edges);

            group.attach(edges)

            // edges

            //这个旋转应该是由于model相对于世界坐标的旋转产生的
            edges.rotation.set(Math.PI, 0, 0);
            return edges;
        },


        //根据全局步骤在模型中找到对应的零件组
        //如果找不到则不返回值
        GetObjectsByGlobleStep(GlobleStepNum) {

            console.log('查找全局步骤对应的零件', GlobleStepNum)

            let FindObjectsArr = []


            //找到所有零件或者组，如果是替身组，则不找替身组的后代了
            let ObjectsArrWithoutStandInGroupCHildren = []



            let stepParent

            // let children = []

            let findPartOrGroup = false

            let checkChildren = function (group, parenIsStandInGroup = false) {

                for (let i = 0; i < group.children.length; i++) {

                    if (group.children[i].userData.TYPE && (group.children[i].userData.TYPE == 'PART' || group.children[i].userData.TYPE == 'GROUP')) {

                        if (group.children[i].userData.MyStep == GlobleStepNum) {

                            FindObjectsArr.push(group.children[i])
                            // parenIsStandInGroup

                            //如果父级不是替身组，才储存
                            if (!parenIsStandInGroup) {
                                ObjectsArrWithoutStandInGroupCHildren.push(group.children[i])
                            }

                            // if (!stepParent) {

                            //     stepParent = group.children[i].parent

                            // }


                            findPartOrGroup = true

                        }

                    }

                    if (group.children[i].userData.TYPE && group.children[i].userData.TYPE == 'GROUP') {


                        if (group.children[i].userData.MyStep == GlobleStepNum) {

                            console.log('group.children[i]2223333', group.children[i])

                            // FindObjectsArr.push(group.children[i])

                            // if (!stepParent) {

                            //     stepParent = group.children[i].parent

                            // }


                            findPartOrGroup = true

                        }

                        //没找到目标零件中的任何零件则继续递归
                        if (findPartOrGroup == false) {

                            // if (!stepParent) {
                            //     stepParent = group.children[i].parent
                            // }

                            // if(group.children[i].userData.isStandInGroup){

                            // }

                            //如果本级是替身组，则其所有子孙都是替身
                            if (parenIsStandInGroup) {

                                checkChildren(group.children[i], true)

                            } else {

                                //是否是替身组
                                let isStandInGroup = group.children[i].TYPE == "GROUP" && group.children[i].userData.isStandInGroup ? true : false
                                // parenIsStandInGroup
                                checkChildren(group.children[i], isStandInGroup)
                            }
                        }

                    }

                }

            }

            checkChildren(model)

            console.log('FindObjectsArr', FindObjectsArr)

            stepParent = FindObjectsArr[0].parent


            return {
                stepParent,
                FindObjectsArr,
                //没有替身组子孙后代的组或零件后代
                ObjectsArrWithoutStandInGroupCHildren
            }


        },

        visible_see_thisStep() {

            //正在执行全部零件可见则返回
            // if(this.seeAllPars == true){

            //     return

            // }

            console.log('只显示本步骤零件')

            const targetGlobleStepNum = this.stepShow


            const stepPartsInfo = this.GetObjectsByGlobleStep(targetGlobleStepNum)

            let stepParent = stepPartsInfo.stepParent

            //设置零件可见性
            this.setStepPartsVisible(stepParent, targetGlobleStepNum)


        },

        // SetThisStepAndBeforeOnStep() {


        //     //禁用状态就返回
        //     // if (this.bottom_disabled) {

        //     //     return

        //     // }


        //     const that = this

        //     //防止连续点击
        //     // if (this.allowButtonPressed == false) {
        //     //     return
        //     // }

        //     // this.allowButtonPressed = false

        //     //5秒还没反应就允许下次按钮事件
        //     // let timer = setTimeout(() => {
        //     //     that.allowButtonPressed = true
        //     // }, 5000);

        //     this.bottom_disabled = true

        //     let bottom_disabled = true

        //     this.visibleBottomLoading = true


        //     // 是否已经允许点击
        //     setTimeout(() => {
        //         if (bottom_disabled == true) {
        //             // loading1.close();

        //             //如果还没解开按钮功能则解开

        //             bottom_disabled = false

        //             that.bottom_disabled = false

        //             that.visibleBottomLoading = false
        //         }
        //     }, 5000);


        //     if (this.seeAllPars == false) {

        //         //全部显示
        //         //避免ipad端多次点击是方法洁面



        //         this.visible_see_thisStepAndBefore()

        //         // that.seeAllPars = true




        //         //如果还没解开按钮功能则解开
        //         if (bottom_disabled == true) {
        //             // loading1.close();
        //             this.bottom_disabled = false
        //             bottom_disabled = false

        //             this.seeAllPars = true

        //             this.visibleBottomLoading = false
        //         }



        //         //如果还没解开按钮功能则解开
        //         // if (bottom_disabled == true) {
        //         //     // loading1.close();
        //         //     that.bottom_disabled = false
        //         //     bottom_disabled = false
        //         // }




        //         // this.seeAllPars = true
        //         // setTimeout(() => {

        //         //     that.seeAllPars = true

        //         //     clearTimeout(timer)

        //         //     that.allowButtonPressed = true

        //         // }, 2500);



        //     } else {



        //         // that.visible_see_thisStepAndBefore()

        //         //避免ipad端多次点击是方法洁面
        //         //正常显示
        //         this.visible_see_thisStep()

        //         // that.seeAllPars = false

        //         //慢点解锁，避免接所有接着又是一次点击，连续点击会导致ipad放大

        //         //如果还没解开按钮功能则解开
        //         if (bottom_disabled == true) {
        //             // loading1.close();
        //             this.bottom_disabled = false
        //             bottom_disabled = false

        //             this.seeAllPars = false

        //             this.visibleBottomLoading = false
        //         }





        //         // setTimeout(() => {

        //         //     that.seeAllPars = false

        //         //     clearTimeout(timer)

        //         //     that.allowButtonPressed = true

        //         // }, 2500);

        //     }

        //     // this.handleCurrentStepChange(this.stepShow)

        //     // setTimeout(() => {
        //     //     if (bottom_disabled == true) {
        //     //         // loading1.close();
        //     //         that.bottom_disabled = false
        //     //         bottom_disabled = false
        //     //     }
        //     // }, 300);









        //     // this.allowButtonPressed = true

        //     // clearTimeout(timer)






        // },








        SetThisStepAndBeforeOnStep() {


            //禁用状态就返回
            // if (this.bottom_disabled) {

            //     return

            // }


            const that = this

            //防止连续点击
            // if (this.allowButtonPressed == false) {
            //     return
            // }

            // this.allowButtonPressed = false

            //5秒还没反应就允许下次按钮事件
            // let timer = setTimeout(() => {
            //     that.allowButtonPressed = true
            // }, 5000);

            this.bottom_disabled = true

            let bottom_disabled = true

            this.visibleBottomLoading = true


            // 是否已经允许点击
            setTimeout(() => {
                if (bottom_disabled == true) {
                    // loading1.close();

                    //如果还没解开按钮功能则解开

                    bottom_disabled = false

                    that.bottom_disabled = false

                    that.visibleBottomLoading = false
                }
            }, 5000);



            if (this.seeAllPars == false) {

                //全部显示
                //避免ipad端多次点击是方法洁面

                setTimeout(() => {

                    that.visible_see_thisStepAndBefore()

                    // that.seeAllPars = true


                    setTimeout(() => {

                        //如果还没解开按钮功能则解开
                        if (bottom_disabled == true) {
                            // loading1.close();
                            that.bottom_disabled = false
                            bottom_disabled = false

                            that.seeAllPars = true
                            //将状态保存到userdata中
                            // model.userData.seeAllPars = true

                            that.visibleBottomLoading = false
                        }

                    }, 50)

                    //如果还没解开按钮功能则解开
                    // if (bottom_disabled == true) {
                    //     // loading1.close();
                    //     that.bottom_disabled = false
                    //     bottom_disabled = false
                    // }

                }, 50);


                // this.seeAllPars = true
                // setTimeout(() => {

                //     that.seeAllPars = true

                //     clearTimeout(timer)

                //     that.allowButtonPressed = true

                // }, 2500);



            } else {

                setTimeout(() => {

                    // that.visible_see_thisStepAndBefore()

                    //避免ipad端多次点击是方法洁面
                    //正常显示
                    that.visible_see_thisStep()

                    // that.seeAllPars = false

                    //慢点解锁，避免接所有接着又是一次点击，连续点击会导致ipad放大
                    setTimeout(() => {

                        //如果还没解开按钮功能则解开
                        if (bottom_disabled == true) {
                            // loading1.close();
                            that.bottom_disabled = false
                            bottom_disabled = false

                            that.seeAllPars = false
                            // model.userData.seeAllPars = false

                            that.visibleBottomLoading = false
                        }

                    }, 50)




                }, 50);



                // setTimeout(() => {

                //     that.seeAllPars = false

                //     clearTimeout(timer)

                //     that.allowButtonPressed = true

                // }, 2500);

            }

            // this.handleCurrentStepChange(this.stepShow)

            // setTimeout(() => {
            //     if (bottom_disabled == true) {
            //         // loading1.close();
            //         that.bottom_disabled = false
            //         bottom_disabled = false
            //     }
            // }, 300);









            // this.allowButtonPressed = true

            // clearTimeout(timer)

        },


        SetStepPartsBigShow() {

            this.enableAtuoStepBigShow = !this.enableAtuoStepBigShow

            DoStepAnimationPlayer.setAutoCamera(this.enableAtuoStepBigShow)

        },

        // SetThisStepAndBeforeOnStep_________() {

        //     let loading1 = this.$loading({
        //         lock: true,
        //         text: '',
        //         spinner: '',
        //         background: 'rgba(0, 0, 0, 0)'
        //     });
        //     setTimeout(() => {
        //         if (loading1 != null) {
        //             loading1.close();
        //         }
        //     }, 5000);

        //     // const that = this

        //     //防止连续点击
        //     // if (this.allowButtonPressed == false) {
        //     //     return
        //     // }

        //     // this.allowButtonPressed = false

        //     //5秒还没反应就允许下次按钮事件
        //     // let timer = setTimeout(() => {
        //     //     that.allowButtonPressed = true
        //     // }, 5000);

        //     if (this.seeAllPars == false) {

        //         //全部显示
        //         this.visible_see_thisStepAndBefore()

        //         this.seeAllPars = true
        //         // setTimeout(() => {

        //         //     that.seeAllPars = true

        //         //     clearTimeout(timer)

        //         //     that.allowButtonPressed = true

        //         // }, 2500);



        //     } else {

        //         //正常显示
        //         this.visible_see_thisStep()

        //         this.seeAllPars = false

        //         // setTimeout(() => {

        //         //     that.seeAllPars = false

        //         //     clearTimeout(timer)

        //         //     that.allowButtonPressed = true

        //         // }, 2500);

        //     }

        //     setTimeout(() => {
        //         if (loading1 != null) {
        //             loading1.close();
        //             loading1 = null
        //         }
        //     }, 200);



        //     // this.allowButtonPressed = true

        //     // clearTimeout(timer)






        // },


        //设置全部零件都可见（前面已经搭建好的是灰色的，本步骤是彩色的，后面步骤是透明的）

        visible_see_thisStepAndBefore() {

            // const that = this

            // //防止重复点击
            // if (this.seeAllPars == true) {

            //     return

            // }

            // this.seeAllPars = true


            console.log('显示所有步骤零件')

            const targetGlobleStepNum = this.stepShow

            console.log('targetGlobleStepNum3333', targetGlobleStepNum)

            for (let i = 0; i < groupArr.length; i++) {

                console.log('partAndGroupArr[i].userData.MyStep', partAndGroupArr[i].userData.MyStep)

                if (groupArr[i].visible != true) {

                    groupArr[i].visible = true;

                }
            }


            //从外往内递归，找到步骤相等的对象后就跳出，哪一层找到目标步骤后就不往下级找了

            // let ThisFloorFind = false

            // const FindObjInModelByStep = function (Group) {

            //     for (let i = 0; i < Group.children.length; i++) {

            //         if (Group.children[i].isGroup) {

            //             if (Group.children[i].userData.MyStep && Group.children[i].userData.MyStep == targetGlobleStepNum) {

            //                 ThisFloorFind = true

            //                 if () {




            //                 }

            //             }

            //         }


            //     }

            // }

            //显示出本步骤的零件
            // console.log('partArr444', partArr)
            for (let i = 0; i < partArr.length; i++) {

                if (partArr[i].visible != true) {

                    partArr[i].visible = true;

                }

                console.log('循环查找数组内的所有对象', partArr[i].userData.MyStep)

                if (partArr[i].userData.MyStep < targetGlobleStepNum) {

                    console.log('前面步骤变色')

                    //是灰色就跳过
                    if (partArr[i].userData.ColorState && partArr[i].userData.ColorState == 'OpaqueColor') { continue }

                    //设为灰色颜色

                    partArr[i].userData.ColorState = 'OpaqueColor'



                    //灰色
                    partArr[i].traverse((c) => {
                        // 缺失模型代替方块不参与变透明
                        // 没有名字的mesh无法设为半透明，因为如果它被设为半透明有将无法再恢复了
                        // if (c.isLine && c.name != 'MyBoxHelper') {

                        //     if (c.visible != false) {

                        //         c.visible = false

                        //     }

                        // } else 
                        if (c.isMesh && c.material.name != "") {
                            console.log("设为灰色", c);
                            //修改材质的颜色
                            if (Array.isArray(c.material)) {
                                c.material = c.material.map(
                                    function () {
                                        return material_opaque;
                                    }
                                );
                            } else {
                                c.material = material_opaque;
                                console.log(c.material.name);
                            }
                        }
                    });

                } else if (partArr[i].userData.MyStep > targetGlobleStepNum) {

                    //是透明色就跳过
                    if (partArr[i].userData.ColorState && partArr[i].userData.ColorState == 'TranslucentColor') { continue }

                    //设透明色颜色


                    partArr[i].userData.ColorState = 'TranslucentColor'



                    //***************改为半透明材质,不要删，这是低于选中步骤变色的逻辑*********************不要删
                    partArr[i].traverse((c) => {
                        // 缺失模型代替方块不参与变透明
                        // 没有名字的mesh无法设为半透明，因为如果它被设为半透明有将无法再恢复了
                        // if (c.isLine && c.name != 'MyBoxHelper') {
                        if (c.isLine) {


                            if (c.visible != false) {

                                c.visible = false

                            }

                        } else if (c.isMesh && c.material.name != "") {
                            console.log("设为半透明22222", c);
                            //修改材质的颜色
                            if (Array.isArray(c.material)) {
                                c.material = c.material.map(
                                    function () {
                                        return material_translucent;
                                    }
                                );
                            } else {
                                c.material = material_translucent;
                                console.log(c.material.name);
                            }
                        }

                    });

                }

            }



            // 找到本步骤的零件或者组，恢复颜色
            // 如果是组，则将组的颜色还原，如果是零件，则将零件的父级内的所有的的颜色还原
            // let thisStepPartArr = []
            // let GroupArr = []
            // for (let i = 0; i < partAndGroupArr.length; i++) {

            //     if (partAndGroupArr[i].userData.MyStep == targetGlobleStepNum) {

            //         if (partAndGroupArr[i].userData.TYPE == 'GROUP') {

            //             GroupArr.push(partAndGroupArr[i])

            //         } else if (partAndGroupArr[i].userData.TYPE == 'PART') {

            //             thisStepPartArr.push(partAndGroupArr[i])

            //         }

            //     }

            // }

            // if(GroupArr.length){

            // }

            for (let i = 0; i < partAndGroupArr.length; i++) {
                //本步骤设为原色
                if (partAndGroupArr[i].userData.MyStep == targetGlobleStepNum) {

                    let RestoreColorObj = partAndGroupArr[i]


                    // if (partAndGroupArr[i].userData.TYPE == 'GROUP') {

                    //     RestoreColorObj = partAndGroupArr[i]

                    // } else if (partAndGroupArr[i].userData.TYPE == 'PART') {

                    //     RestoreColorObj = partAndGroupArr[i].parent
                    // }

                    // let RestoreColorObj

                    //是原色就跳过
                    if (partAndGroupArr[i].userData.TYPE == 'PART' && partAndGroupArr[i].userData.ColorState && partAndGroupArr[i].userData.ColorState == 'OriginalColor') { continue }

                    //是原始颜色
                    if (partAndGroupArr[i].userData.TYPE == 'PART') {
                        RestoreColorObj.userData.ColorState = 'OriginalColor'
                    }


                    //原色
                    RestoreColorObj.traverse((c) => {
                        // 缺失模型代替方块不参与变透明
                        // 没有名字的mesh无法设为半透明，因为如果它被设为半透明有将无法再恢复了
                        // if (c.isLine && c.name != 'MyBoxHelper') {

                        //     if (c.visible != true) {

                        //         c.visible = true

                        //     }

                        // } else 

                        if (c.isMesh && c.userData.colorCode && Array.isArray(c.userData.colorCode)) {


                            console.log("设为原色3333", c);
                            //修改材质的颜色
                            if (c.userData.colorCode.length > 1) {
                                c.material = c.userData.colorCode.map(
                                    function (color) {

                                        return lDrawLoader.materialLibrary[color];
                                        // return material_opaque;
                                    }
                                );
                            } else {
                                c.material = lDrawLoader.materialLibrary[c.userData.colorCode[0]];
                                // console.log(c.material.name);
                            }
                        }
                        // }
                    });
                }
            }


            // setTimeout(() => {

            //     that.seeAllPars = false

            // }, 200);
        },
        //播放步骤动画
        // DoStepAnimationPlayer.playStepAnimation(stepPartsInfo,model,camera,targetGlobleStepNum,partAndGroupArr,OrbitControl,Model_children_visibleInfoArr,lDrawLoader_ )
        async setStep(targetGlobleStepNum) {


            //选择步骤的时候如果是全部零件可见的话设为本步骤可见，同时将零件的材质修改成正常颜色
            if (this.seeAllPars == true) {
                this.visible_see_thisStep()
                this.seeAllPars = false
            }




            const stepPartsInfo = this.GetObjectsByGlobleStep(targetGlobleStepNum)

            //启动渲染（如果渲染没启用的话）
            // EnableRender

            this.updataRenderer()


            // let ObjArrInThisGlobleStep_arr = stepPartsInfo.FindObjectsArr

            let ObjArrInThisGlobleStep_arr = stepPartsInfo.ObjectsArrWithoutStandInGroupCHildren

            console.log('ObjArrInThisGlobleStep_ar3r333', ObjArrInThisGlobleStep_arr)

            if (ObjArrInThisGlobleStep_arr == null) {

                //空组则返回

                return

            }

            // let stepParent = stepPartsInfo.stepParent

            this.stepPath = ObjArrInThisGlobleStep_arr[0].userData.stepPath


            // console.log('stepParent030303', stepParent)
            // // stepParent,
            // //     FindObjectsArr
            // console.log('ObjArrInThisGlobleStep_arr222', ObjArrInThisGlobleStep_arr)

            // let stepParent = ObjArrInThisGlobleStep_arr[0].parent

            //生成本步骤的零件列表
            // this.partsListImagesData
            let partsListImagesData_All = await mpdPartListCreater.getImagesArr(ObjArrInThisGlobleStep_arr)

            //按照零件和组分类
            let GroupsArr = []
            let PartsArr = []
            console.log('零件图333', partsListImagesData_All)
            for (let i = 0; i < partsListImagesData_All.length; i++) {

                if (partsListImagesData_All[i].type == 'PART') {

                    PartsArr.push(partsListImagesData_All[i])

                } else if (partsListImagesData_All[i].type == 'GROUP') {

                    //如果是替身零件组，则归类到零件PART 类中
                    if (partsListImagesData_All[i].isStandInGroup) {

                        PartsArr.push(partsListImagesData_All[i])

                    } else {

                        GroupsArr.push(partsListImagesData_All[i])

                    }

                }

            }

            this.partsListImagesData_PartsArr = PartsArr
            this.partsListImagesData_GroupsArr = GroupsArr



            // this.partsListImagesDataOBJ = {
            //     GroupsArr,
            //     GroupsArr
            // }



            // // 获取对应的 div 元素
            // const div = this.$refs.mpdview;
            let div_three = this.$refs.mpdview;

            console.log(DoStepAnimationPlayer, div_three)



            await DoStepAnimationPlayer.playStepAnimation(stepPartsInfo, model, camera, targetGlobleStepNum, partAndGroupArr, OrbitControl, Model_children_visibleInfoArr, lDrawLoader, div_three)


            console.log('零件动画执行完毕')

        },


        // async setStep(targetGlobleStepNum) {



        //     this.seeAllPars = false


        //     const stepPartsInfo = this.GetObjectsByGlobleStep(targetGlobleStepNum)


        //     //启动渲染（如果渲染没启用的话）
        //     // EnableRender

        //     this.updataRenderer()





        //     let ObjArrInThisGlobleStep_arr = stepPartsInfo.FindObjectsArr

        //     if (ObjArrInThisGlobleStep_arr == null) {

        //         //空组则返回

        //         return

        //     }

        //     let stepParent = stepPartsInfo.stepParent

        //     this.stepPath = ObjArrInThisGlobleStep_arr[0].userData.stepPath


        //     console.log('stepParent030303', stepParent)
        //     // stepParent,
        //     //     FindObjectsArr
        //     console.log('ObjArrInThisGlobleStep_arr222', ObjArrInThisGlobleStep_arr)


        //     // let stepParent = ObjArrInThisGlobleStep_arr[0].parent




        //     //生成本步骤的零件列表
        //     this.partsListImagesData = mpdPartListCreater.getImagesArr(ObjArrInThisGlobleStep_arr)
        //     // mpdPartListCreater.CreatePartListImagesArr(ObjArrInThisGlobleStep_arr)
        //     console.log('生成的零件列表是：', this.partsListImagesData)
        //     //设置零件可见性
        //     this.setStepPartsVisible(stepParent, targetGlobleStepNum)




        //     //停止所有的动画
        //     this.stopAllTweenAnimation()
        //     // 
        //     // //停止所有的相机动画
        //     this.stopStepCameraTweenData()

        //     //停止所有的主模型旋转动画
        //     this.stopAllModelRotateAnimation()





        //     //显示或者隐藏本步骤中需要根据userdata中动态显示或者隐藏的零件
        //     this.PartOrGroupVisibleByStepData(model, ObjArrInThisGlobleStep_arr[0].userData.MyStep, Model_children_visibleInfoArr)

        //     //还原零件的安装位置
        //     this.placePositionAndRotation()



        //     if (ObjArrInThisGlobleStep_arr.length > 15) {

        //         this.$message({
        //             message: '本步骤中零件数大于15,不执行动画',
        //             type: 'warning'
        //         });

        //         return

        //     }

        //     //从该步骤第一个零件的userdata中获取到该步骤的camera的动画信息并更新到右侧摄像机动画列表中
        //     // this.getCameraAnimatinFromPart(StepCameraData_Part);

        //     this.CreateStepPartsTweenDataOnStep(ObjArrInThisGlobleStep_arr)

        //     //主模型旋转动画
        //     await this.rotateParentAnimation(ObjArrInThisGlobleStep_arr);



        //     this.CreateStepCameraTweenData(ObjArrInThisGlobleStep_arr);




        //     //播放摄像机动画
        //     await this.CamreaTweenArrAnimationsDo(cameraAnimationTweenArr);

        //     //重新确认显示状况，如果不对则更正（不对的情况主要是出现在重复点击步骤来回切换的时候，有些不该显示的显示了）
        //     // this.setStepPartsVisible(ObjArrInThisGlobleStep_arr, stepParent, targetGlobleStepNum)



        //     //播放本步骤的动画
        //     await this.playThisStepPartOrGroupAnimation(ObjArrInThisGlobleStep_arr)

        //     // const that = this
        //     // setTimeout(() => {
        //     //     that.playThisStepPartOrGroupAnimation(ObjArrInThisGlobleStep_arr)

        //     // }, 5000);

        //     //重新确认显示状况，如果不对则更正（不对的情况主要是出现在重复点击步骤来回切换的时候，有些不该显示的显示了）
        //     // this.setStepPartsVisible(stepParent, targetGlobleStepNum)


        //     //显示或者隐藏本步骤中需要根据userdata中动态显示或者隐藏的零件
        //     // this.PartOrGroupVisibleByStepData(model, ObjArrInThisGlobleStep_arr[0].userData.MyStep)



        //     console.log('零件动画执行完毕')




        //     // //为没有动画的零件设置步骤动画，设在userdata中
        //     // if (this.AnimationAuto) {
        //     //     this.CreateAnimationListData(ObjArrInThisGlobleStep_arr);
        //     // }

        //     // //存放相机动画的零件
        //     // let StepCameraData_Part = StepAnimationInfo[0]

        //     // //如果设置为执行动画才执行动画
        //     // if (this.DoAnimation) {

        //     //     //创建步骤中的零件动画数据
        //     //     this.CreateStepPartsTweenData(ObjArrInThisGlobleStep_arr);


        //     //     // 这个功能暂时还没有调试，先隐藏，下一版本开放
        //     //     // 就算下方函数去除注释也还不能用哈
        //     //     // this.AddChildrenAnimation()



        //     //     //主模型旋转动画
        //     //     await this.rotateParentAnimation();




        //     //     //从该步骤第一个零件的userdata中获取到该步骤的camera的动画信息并更新到右侧摄像机动画列表中
        //     //     this.getCameraAnimatinFromPart(StepCameraData_Part);

        //     //     this.CreateStepCameraTweenData();

        //     //     //执行它摄像机动画---异步执行
        //     //     // this.TweenArrAnimationsDo(cameraAnimationTweenArr)

        //     //     ////执行它摄像机动画---异步执行
        //     //     await this.CamreaTweenArrAnimationsDo(cameraAnimationTweenArr);

        //     //     //执行步骤中的零件动画
        //     //     await this.TweenArrAnimationsDo(StepAnimationTweenArr);

        //     //     console.log("所有动画完成2");
        //     // }





        //     // ChooseObjArrBottom = [];


        //     // let myEvent = {
        //     //     parentId: showgroup_.id,
        //     //     partEmpty: ChoosePartIDArr.length == 0,
        //     //     partIDArr: ChoosePartIDArr,
        //     //     partName: showgroup_.name,
        //     //     stepNum: targetStepNum - 1,
        //     // };

        //     //将该组移动到指定步骤

        //     //3d图设置步骤
        //     // this.handleLeftStepImageClicked(myEvent, true);

        //     // set3DShow会调用这个方法handleLeftStepImageClicked()，这个方法会将ThisFoorChooseStepNum减小1，所以我们现在要将它增加1
        //     // showgroup_.userData.ThisFoorChooseStepNum += 1;
        // },

        //根据零件内的userdata内的PartVisibleInStepArr数组信息在合适的步骤显示零件
        // showGlobalStep 显示的步骤
        PartOrGroupVisibleByStepData(Group, showGlobalStep) {


            //本步骤中需要修改可见性的零件
            console.log('Model_children_visibleInfoArr222', Model_children_visibleInfoArr, showGlobalStep, 1, '1')
            let NeedSetVisibleInThisStep = Model_children_visibleInfoArr[showGlobalStep]
            //没有则可以跳过了
            if (NeedSetVisibleInThisStep == null) { return }

            for (let i = 0; i < Group.children.length; i++) {

                //只处理零件和组
                if (Group.children[i].userData.TYPE == 'PART') {

                    // 小于本步骤的零件才需要根据PartVisibleInStepArr信息显示或者隐藏
                    // 大于等于本步骤的零件不需要根据PartVisibleInStepArr显示或隐藏，因为零件都还没安装呢
                    if (Group.children[i].userData.MyStep < showGlobalStep) {

                        //开始根据Model_children_visibleInfoArr数组内的信息来显示或者隐藏前面步骤的某些零件了


                        console.log('看看本步骤中有哪些需要修改可见性的零件', NeedSetVisibleInThisStep)
                        //看看本步骤中有哪些需要修改可见性的零件
                        for (let j = 0; j < NeedSetVisibleInThisStep.length; j++) {

                            if (NeedSetVisibleInThisStep[j].id == Group.children[i].id) {

                                console.log('设置该零件的可见性', j, Group.children[i], NeedSetVisibleInThisStep[j])

                                if (Group.children[i].visible != NeedSetVisibleInThisStep[j].visible) {
                                    Group.children[i].visible = NeedSetVisibleInThisStep[j].visible

                                }

                            }

                        }

                    }

                } else if (Group.children[i].userData.TYPE == 'GROUP') {

                    this.PartOrGroupVisibleByStepData(Group.children[i], showGlobalStep)

                }


            }
        },

        //正常的显示规则是零件安装以后，后面的所有步骤中本零件都应该是显示状态（半透明也算显示状态）
        //但正常显示还不够，不足以应付各种使用场景，比如导线，导线在步骤2设为卷曲状态，在步骤10导线要安装了，这个时候我们就要隐藏卷曲导线，显示安装的导线了
        //所有可以在右侧设置零件在各种状态下的显示状体
        //所以各个零件或者组都有一个隐藏的功能，在规定的全局步骤开始隐藏
        //数据在userdata中的PartVisibleInStep属性和PartVisibleInStepArr属性中
        partVisibleSetByStep() {
            //算法设计：递归找到所有的零件和组
            //将零件的所有子零件，当读取到零件A（id：22110）下有[12;15] 类似于这样的数据(本步骤显示，12步隐藏，13步隐藏，15步显示)后
            //我们就在本函数中数组Model_children_visibleInfoArr 的 index12存入{visible:false,id:22110}
            //index13存入{visible:false,id:22110}
            //index14存入{visible:false,id:22110}
            //index15存入{visible:true,id:22110}

            //这样子我们在后面的每一步都能找到零件对应的显示状态了

            Model_children_visibleInfoArr = []

            //模型中的最大步骤
            const Model_maxstep = model.userData.numBuildingSteps

            console.log('可见性数据处理333,', Model_maxstep, partAndGroupArr)

            // model.traverse(c => {
            for (let i = 0; i < partAndGroupArr.length; i++) {

                const c = partAndGroupArr[i]
                //只处理零件和组
                // if (c.userData.TYPE == 'PART' || c.userData.TYPE == 'GROUP') {
                //如果有显示和隐藏的设置
                if (c.userData.PartVisibleInStepArr && c.userData.PartVisibleInStepArr.length > 0) {

                    //零件的隐藏显示设置信息
                    const PartVisibleInStepArr = c.userData.PartVisibleInStepArr
                    let min = Math.min(...PartVisibleInStepArr);
                    let max = Model_maxstep;
                    let visible = true
                    for (let j = min; j < max + 1; j++) {

                        //包含则说明显示状态要切换了显示-不显示-显示-。。。。。。
                        if (PartVisibleInStepArr.indexOf(j) != -1) { visible = !visible }

                        //没有的步骤加上新的空数组
                        if (Model_children_visibleInfoArr[j] == null) { Model_children_visibleInfoArr[j] = [] }

                        Model_children_visibleInfoArr[j].push({
                            visible: visible,
                            id: c.id
                        })

                    }


                }
                // }
            }

            // })


            console.log('Model_children_visibleInfoArr', Model_children_visibleInfoArr)

            // return Model_children_visibleInfoArr
            // this.CreatePartOrGroupVisibleData(model)
        },

        setStepPartsVisible(stepParent, targetGlobleStepNum) {
            for (let i = 0; i < partAndGroupArr.length; i++) {

                let c = partAndGroupArr[i]
                if (c.userData.TYPE && c.userData.TYPE == 'PART') {
                    c.visible = false;

                    //是原色就跳过
                    if (c.userData.ColorState && c.userData.ColorState == 'OriginalColor') { continue }

                    //是原始颜色


                    c.userData.ColorState = 'OriginalColor'



                    partAndGroupArr[i].traverse(c => {

                        if (c.isLine) {

                            if (c.name == 'MyBoxHelper') {

                                if (c.visible != false) {

                                    c.visible = false

                                }

                            } else {

                                if (c.visible != true) {

                                    c.visible = true

                                }

                            }

                            // if()

                        } else if (c.isMesh && Array.isArray(c.userData.colorCode)) {

                            console.log("设为原色", c);
                            //修改材质的颜色
                            if (c.userData.colorCode.length > 1) {
                                c.material = c.userData.colorCode.map(
                                    function (color) {

                                        return lDrawLoader.materialLibrary[color];
                                        // return material_opaque;
                                    }
                                );
                            } else {
                                c.material = lDrawLoader.materialLibrary[c.userData.colorCode[0]];
                                // console.log(c.material.name);
                            }

                        }

                    })



                } else if (c.userData.TYPE && c.userData.TYPE == 'GROUP') {
                    //如果是零件组，都显示
                    c.visible = true;
                }

            }

            // 先把stepParent内的所有零件或者组都可见
            stepParent.traverse((c) => {
                if (c.isGroup && c.userData.TYPE) {
                    if (c.userData.TYPE && c.userData.TYPE == 'PART') {
                        c.visible = true;
                    } else if (c.userData.TYPE && c.userData.TYPE == 'GROUP') {
                        c.visible = true;
                    }
                }
            });

            //显示出本步骤的零件
            // if (e.x == true) {
            for (let i in stepParent.children) {
                //本步骤先隐藏
                if (stepParent.children[i].userData.MyStep == targetGlobleStepNum) {


                    if (stepParent.children[i].visible != true) {

                        stepParent.children[i].visible = true;

                    }


                    // //看看是不是动画执行完后的确认部分
                    // if (endSet) {

                    //     if (stepParent.children[i].visible != true) {

                    //         stepParent.children[i].visible = true;

                    //     }

                    // } else {



                    // }


                    // stepParent.children[i].traverse((c) => {
                    //     console.log('23333c2222', c)
                    //     // if(c.isLine){return}

                    //     if (c.isMesh && c.userData.colorCode) {

                    //         console.log('c.isMesh && c.userData.colorCode', c.userData.colorCode)



                    //         //**************这段代码不要删除，是选中步骤显示，这是变色后恢复颜色的逻辑 */

                    //         // // 修改材质为本来的颜色
                    //         // if (c.userData.colorCode.length > 1) {
                    //         //     c.material = c.userData.colorCode.map(function (color) {
                    //         //         return lDrawLoader.materialLibrary[color];
                    //         //     });
                    //         // } else if (c.userData.colorCode) {
                    //         //     c.material = lDrawLoader.materialLibrary[c.userData.colorCode];
                    //         // }



                    //     }
                    // });

                    //本级零件中，如果不是左侧菜单选中的对象，则将本级零件中，ThisFloorStepNum低于选中
                    //步骤的组设为本透明材质
                    //高于ThisFloorStepNum的零件隐藏visible=false
                } else if (stepParent.children[i].userData.MyStep < targetGlobleStepNum) {

                    console.log('前面步骤变色')

                    if (stepParent.children[i].visible != true) {

                        stepParent.children[i].visible = true

                    }


                    //***************改为半透明材质,不要删，这是低于选中步骤变色的逻辑*********************不要删
                    // stepParent.children[i].traverse((c) => {
                    //缺失模型代替方块不参与变透明
                    //没有名字的mesh无法设为半透明，因为如果它被设为半透明有将无法再恢复了
                    //     if (c.isMesh && c.material.name != "") {
                    //         console.log("设为半透明22222", c);
                    //         //修改材质的颜色
                    //         if (Array.isArray(c.material)) {
                    //             c.material = c.material.map(
                    //                 function () {
                    //                     return material_translucent;
                    //                 }
                    //             );
                    //         } else {
                    //             c.material = material_translucent;
                    //             console.log(c.material.name);
                    //         }
                    //     }
                    //     // }
                    // });
                } else {
                    //高于ThisFloorStepNum的零件隐藏visible=false
                    if (stepParent.children[i].visible != false) {

                        stepParent.children[i].visible = false;

                    }
                }

            }
        },

        //执行零件父级旋转动画
        //按照最小幅度旋转
        // async rotateParentAnimation(ObjArrInThisGlobleStep_arr) {


        //     modelRotateTweenArr = []

        //     console.log("执行父级旋转动画");

        //     //找到本步骤的第一个零件

        //     let ThisStepAnimationInfoPart = ObjArrInThisGlobleStep_arr[0]

        //     // for (let i in showGroup.children) {

        //     //     if (showGroup.children[i].userData.TYPE == "PART" || showGroup.children[i].userData.TYPE == "GROUP") {
        //     //         // if (model.children[i].userData.startingBuildingStep == true) {
        //     //         if (
        //     //             showGroup.children[i].userData.ThisFloorStepNum + 1 ==
        //     //             showGroup.userData.ThisFoorChooseStepNum
        //     //         ) {
        //     //             ThisStepAnimationInfoPart = showGroup.children[i];

        //     //             console.log("获取父级旋转数据成功2", showGroup.children[i]);

        //     //             break;
        //     //         }
        //     //     }
        //     // }

        //     if (ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set) {
        //         //看看父级旋转目标旋转与当前的旋转是否相同
        //         //如果相同则返回
        //         //不需要执行旋转主模型的旋转动画
        //         let nowQua = new THREE.Quaternion();
        //         model.getWorldQuaternion(nowQua);

        //         let TarQua = new THREE.Quaternion();

        //         TarQua.setFromRotationMatrix(
        //             ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix
        //         );
        //         let angel = nowQua.angleTo(TarQua);

        //         //看看主模型与目标旋转位移之后相差多少距离，如果距离是0，且夹角是0则不用执行旋转位移动画
        //         //经测试，坐标点会有6以内的误差
        //         const targetPosition = new THREE.Vector3();

        //         targetPosition.setFromMatrixPosition(
        //             ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix
        //         );

        //         let distance_ = targetPosition.distanceTo(model.position);

        //         console.log("targetPosition", targetPosition);
        //         console.log(model.position);
        //         console.log("两个四元数之间的距离是", distance_);

        //         console.log("两个四元数之间的夹角是", angel);
        //         //夹角是0，不需要执行模型旋转动画
        //         if (angel == 0 && distance_ < 6) {
        //             model.position.set(targetPosition.x, targetPosition.y, targetPosition.z);
        //             model.quaternion.copy(TarQua);
        //             console.log("不用执行主模型旋转动画");
        //             // continue
        //             return;
        //         }



        //         //没有相关的数据则返回
        //         if (!(ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set && ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix)) {
        //             return;
        //         }


        //         let PromiseTweenArr = [];

        //         let Model_tagetPosition = new THREE.Vector3()

        //         Model_tagetPosition.setFromMatrixPosition(ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix)

        //         console.log('model旋转的目标位置是', Model_tagetPosition)

        //         let position_tween = new TWEEN.Tween(model.position)
        //             .to({
        //                 x: Model_tagetPosition.x,
        //                 y: Model_tagetPosition.y,
        //                 z: Model_tagetPosition.z,
        //             }, 500
        //             )

        //             .start();

        //         // 执行动画后放入promise
        //         PromiseTweenArr.push(
        //             new Promise(function (resolve) {
        //                 position_tween.onComplete(resolve);
        //             })
        //         );

        //         model.updateWorldMatrix(true, true)
        //         let startMatrix = model.matrixWorld.clone()
        //         let targetMatrix = ThisStepAnimationInfoPart.userData.step_Model_MatrixWorld_set.matrix.clone()


        //         let starQ = new THREE.Quaternion()
        //         starQ.setFromRotationMatrix(startMatrix)
        //         let targetQ = new THREE.Quaternion()
        //         targetQ.setFromRotationMatrix(targetMatrix)

        //         console.log('starQ,targetQ', starQ, targetQ)

        //         // 创建一个数组来存储插值后的四元数
        //         let interpolatedQuaternions = [];


        //         // 进行60个线性插值
        //         for (let i = 0; i <= 60; i++) {
        //             let t = i / 60; // 插值参数，范围从0到1
        //             let interpolatedQuaternion = new THREE.Quaternion();

        //             // 使用slerp方法进行线性插值
        //             interpolatedQuaternion.slerpQuaternions(starQ, targetQ, t);

        //             // 将插值后的四元数添加到数组中
        //             interpolatedQuaternions.push(interpolatedQuaternion);
        //         }

        //         console.log('interpolatedQuaternions', interpolatedQuaternions)


        //         console.log('开始矩阵与目标矩阵', startMatrix, targetMatrix)
        //         // 创建Tween对象
        //         let tweenMatrix4 = new TWEEN.Tween({ x: 0 })
        //             .to({ x: 60 }, 500) // 设置动画的时间为2秒

        //             .onUpdate(function () {
        //                 // 在每一帧更新物体的矩阵
        //                 console.log('model.matrix32333', interpolatedQuaternions[Math.ceil(this.x)])

        //                 model.quaternion.x = interpolatedQuaternions[Math.ceil(this.x)].x
        //                 model.quaternion.y = interpolatedQuaternions[Math.ceil(this.x)].y
        //                 model.quaternion.z = interpolatedQuaternions[Math.ceil(this.x)].z
        //                 model.quaternion.w = interpolatedQuaternions[Math.ceil(this.x)].w

        //             })
        //         // .start(); // 开始动画


        //         //目前只有一个动画
        //         modelRotateTweenArr.push({
        //             tween: tweenMatrix4,
        //             targetQuaternion: targetQ
        //         })

        //         await this.doRotateModelAnimation()

        //         console.log('主模型旋转动画结束')
        //         // //执行动画后放入promise
        //         // PromiseTweenArr.push(
        //         //     new Promise(function (resolve) {
        //         //         tweenMatrix4.onComplete(resolve);
        //         //     })
        //         // );

        //         // //等待所有的promise完成
        //         // await Promise.all(PromiseTweenArr);

        //         // console.log(model);
        //         // console.log(
        //         //     "model.rotation",
        //         //     model.rotation.x,
        //         //     model.rotation.y,
        //         //     model.rotation.z
        //         // );

        //     }
        // },

        //执行主模型旋转动画
        // async doRotateModelAnimation() {

        //     let PromiseTweenArr = []

        //     for (let i = 0; i < modelRotateTweenArr.length; i++) {

        //         modelRotateTweenArr[i].tween.start()

        //         PromiseTweenArr.push(
        //             new Promise(function (resolve) {
        //                 modelRotateTweenArr[i].tween.onComplete(resolve);
        //             })
        //         );

        //     }


        //     // //等待所有的promise完成
        //     await Promise.all(PromiseTweenArr);

        // },

        //停止所有主模型旋转动画
        // stopAllModelRotateAnimation() {

        //     console.log('停止动画旋转的参数', modelRotateTweenArr)

        //     for (let i = 0; i < modelRotateTweenArr.length; i++) {

        //         modelRotateTweenArr[i].tween.stop()

        //         model.quaternion.copy(modelRotateTweenArr[i].targetQuaternion)

        //         model.updateMatrix()

        //         // PromiseTweenArr.push(
        //         //     new Promise(function (resolve) {
        //         //         modelRotateTweenArr[i].tween.onComplete(resolve);
        //         //     })
        //         // );

        //     }



        // },

        //停止所有的相机动画
        // stopStepCameraTweenData() {

        //     if (!cameraAnimationTweenArr) { return }

        //     // targetPosition: CameraTween2.targetPosition,
        //     //                     tween: CameraTween2.CameraTween,
        //     //                     lookat: worldCamerSphereCenter_1.clone(),

        //     for (let i = 0; i < cameraAnimationTweenArr.length; i++) {

        //         cameraAnimationTweenArr[i].tween.stop()

        //         console.log('停止相机动画', cameraAnimationTweenArr[i], cameraAnimationTweenArr[i].tween)

        //         camera.lookAt(cameraAnimationTweenArr[i].lookat)

        //         camera.updateProjectionMatrix()

        //         OrbitControl.target.copy(cameraAnimationTweenArr[i].lookat);

        //         OrbitControl.update();

        //     }

        //     cameraAnimationTweenArr = []

        // },


        //创建本步骤中摄像机的动画
        // CreateStepCameraTweenData(ObjArrInThisGlobleStep_arr) {
        //     // let chooseStepNum = showGroup.userData.ThisFoorChooseStepNum;

        //     // console.log("chooseStepNum111", chooseStepNum);

        //     // if (chooseStepNum == null) {
        //     //     return;
        //     // }

        //     //本步骤中具有动画信息的零件
        //     let stepCameraAnimationPart = ObjArrInThisGlobleStep_arr[0]

        //     // for (let i in showGroup.children) {
        //     //     if (showGroup.children[i].userData.ThisFloorStepNum + 1 == chooseStepNum) {
        //     //         // if (showGroup.children[i].userData.startingBuildingStep == true) {

        //     //         stepCameraAnimationPart = showGroup.children[i];

        //     //         break;

        //     //         // }
        //     //     }
        //     // }

        //     console.log("stepCameraAnimationPart", stepCameraAnimationPart);

        //     const camera_animationInfoDataArr = stepCameraAnimationPart.userData.CameraAnimationArr;

        //     if (camera_animationInfoDataArr == null) {
        //         return;
        //     }

        //     // let CameraAnimationTweenArr = []

        //     // container = document.querySelector("#three2");

        //     // console.log("container333", container);
        //     // console.log("container333", container.offsetWidth);


        //     // 获取对应的 div 元素
        //     const div = this.$refs.mpdview;
        //     // 获取宽高
        //     const canvasWidth = div.offsetWidth;
        //     const canvasHeight = div.offsetHeight;

        //     console.log('显示模型的屏幕宽高是', canvasWidth, canvasHeight)

        //     // const canvasWidth = container.offsetWidth;
        //     // const canvasHeight = container.offsetHeight;

        //     // console.log(canvasWidth, canvasHeight);

        //     //现阶段只计划支持一个相机动画，似乎并不需要多个相机动画
        //     //这个相机动画可以是转场过渡动画，也可以不是过度动画，过度动画是具有阻塞性的，需要过度动画执行完成后零件动画才会启动

        //     //相机的动画组（理论上支持多个相机动画，现阶段只计划支持一个动画）
        //     cameraAnimationTweenArr = [];
        //     //相机的过渡动画组（理论上只需要一个过渡）
        //     // cameraTransitionAnimationTweenArr = []

        //     for (let i in camera_animationInfoDataArr) {
        //         //将摄像机动画组成组添加到tween动画数组中

        //         // const CameraTween = new TWEEN.Tween({ x: startPosition.x, y: startPosition.y, z: startPosition.z })
        //         //     .to({ x: endPosition.x, y: endPosition.y, z: endPosition.z }, stepAnimationInfo_obj.duration)

        //         //     .onUpdate(function () {
        //         //         AutoAnimationGroup.children[i].visible = true
        //         //         // 更新物体的位置等
        //         //         AutoAnimationGroup.children[i].position.set(this.x, this.y, this.z);

        //         //         // AutoAnimationGroup.children[i].updateMatrixWorld()
        //         //     })

        //         let i_info = camera_animationInfoDataArr[i];

        //         if (!i_info) {
        //             continue;
        //         }

        //         //看是否启用动画
        //         if (i_info.CameraAnimationEnable == false) {
        //             console.log("未启用动画");

        //             //未启用动画，则直接将摄像机对准目标点，然后计算射线机位置
        //             console.log("步骤动画摄像机的查看角度参数是", i_info);

        //             if (
        //                 i_info.CameraSphereRadius > 0 &&
        //                 i_info.CameraLookAt.x != null &&
        //                 i_info.CameraLookAt.y != null &&
        //                 i_info.CameraLookAt.z != null
        //             ) {
        //                 let worldCamerSphereCenter = model.localToWorld(
        //                     new THREE.Vector3(
        //                         i_info.CameraLookAt.x,
        //                         i_info.CameraLookAt.y,
        //                         i_info.CameraLookAt.z
        //                     )
        //                 );

        //                 // this.showCameraSphere(
        //                 //     i_info.CameraSphereRadius,
        //                 //     worldCamerSphereCenter.x,
        //                 //     worldCamerSphereCenter.y,
        //                 //     worldCamerSphereCenter.z
        //                 // );

        //                 camera.lookAt(worldCamerSphereCenter);

        //                 OrbitControl.target.copy(worldCamerSphereCenter);

        //                 OrbitControl.update();
        //             } else {
        //                 this.$message({
        //                     message: "无法计算摄像机坐标，原因:缺少半径或包围球中心坐标",
        //                     type: "warning",
        //                 });
        //             }

        //             //摄像机最大化显示包围球
        //             this.cameraLookAtSphere(i_info);
        //         } else if (i_info.CameraAnimationEnable == true) {
        //             //启用动画的话，看看有没有这只自动包围球，如果设置了自动包围球，则求出自动包围球
        //             if (i_info.CameraAutoSphere == true) {
        //                 console.log("启用动画，自动包围球开启");

        //                 let CameraTween = this.CameraAnimation_AutoMode_true(i_info, canvasWidth, canvasHeight, stepCameraAnimationPart);

        //                 if (CameraTween) {
        //                     // if (i_info.CameraAnimationTransition == true) {

        //                     //     //这是运镜动画
        //                     //     cameraTransitionAnimationTweenArr.push(CameraTween)

        //                     // } else {

        //                     console.log(
        //                         "i_info.CameraLookAt",
        //                         i_info.CameraLookAt.x,
        //                         i_info.CameraLookAt.y,
        //                         i_info.CameraLookAt.z
        //                     );

        //                     let worldCamerSphereCenter_ = model.localToWorld(
        //                         new THREE.Vector3(
        //                             i_info.CameraLookAt.x,
        //                             i_info.CameraLookAt.y,
        //                             i_info.CameraLookAt.z
        //                         )
        //                     );

        //                     console.log(
        //                         worldCamerSphereCenter_.x,
        //                         worldCamerSphereCenter_.y,
        //                         worldCamerSphereCenter_.z
        //                     );

        //                     console.log(model);

        //                     console.log(model.position.x, model.position.y, model.position.z);

        //                     console.log(model.rotation.x, model.rotation.y, model.rotation.z);

        //                     console.log("CameraTween", CameraTween);
        //                     // return {
        //                     //     CameraTween,
        //                     //     targetPosition: new THREE.Vector3(cameraPosition.x, cameraPosition.y, cameraPosition.z)
        //                     // }

        //                     cameraAnimationTweenArr.push({
        //                         targetPosition: CameraTween.targetPosition,
        //                         tween: CameraTween.CameraTween,
        //                         lookat: worldCamerSphereCenter_.clone(),
        //                     });
        //                     // }
        //                 }

        //                 // cameraAnimationTweenArr
        //                 // cameraTransitionAnimationTweenArr
        //             } else {
        //                 //如果没设置自动包围球，则按照零件内的userdata内的相机动画数据应用到tween动画中

        //                 console.log("启用动画，自动包围球关闭");
        //                 //自动包围球关闭的模式下，这种模式依赖于零件中userdata中存储的相机动画数组数据
        //                 let CameraTween2 = this.CameraAnimation_AutoMode_false(
        //                     i_info,
        //                     canvasWidth,
        //                     canvasHeight
        //                 );

        //                 if (CameraTween2) {
        //                     // if (i_info.CameraAnimationTransition == true) {

        //                     //     //这是运镜动画
        //                     //     cameraTransitionAnimationTweenArr.push(CameraTween2)

        //                     // } else {
        //                     let worldCamerSphereCenter_1 = model.localToWorld(
        //                         new THREE.Vector3(
        //                             i_info.CameraLookAt.x,
        //                             i_info.CameraLookAt.y,
        //                             i_info.CameraLookAt.z
        //                         )
        //                     );

        //                     // cameraAnimationTweenArr.push({
        //                     //     tween: CameraTween2,
        //                     //     lookat: worldCamerSphereCenter_1.clone()
        //                     // })
        //                     cameraAnimationTweenArr.push({
        //                         targetPosition: CameraTween2.targetPosition,
        //                         tween: CameraTween2.CameraTween,
        //                         lookat: worldCamerSphereCenter_1.clone(),
        //                     });

        //                     // }
        //                 }
        //             }
        //         }

        //         //         AutoAnimationGroup.children[i].visible = true
        //         //         // 更新物体的位置等
        //         //         AutoAnimationGroup.children[i].position.set(this.x, this.y, this.z);

        //         //         // AutoAnimationGroup.children[i].updateMatrixWorld()
        //         //     }
        //     }
        // },

        //专门用来执行相机动画
        // async CamreaTweenArrAnimationsDo(tweenArray) {
        //     let PromiseTweenArr = [];

        //     for (let j in tweenArray) {
        //         console.log("23执行动画23222", j, tweenArray[j]);

        //         if (tweenArray[j].tween) {
        //             //***********************以下解决两个动画的lookAt点不同而造成的画面快速切换造成的闪烁问题**************** */
        //             //以射线机为中心制作一个包围球
        //             //包围球的半径就是相机现在距离目标点的距离
        //             //然后通过摄像机发出去一条射线，摄像机与包围球将会有个交点
        //             //然后就可以通过这个交点 慢慢转向目标点

        //             // let worldCamerSphereCenter = model.localToWorld(new THREE.Vector3(info_item.CameraLookAt.x, info_item.CameraLookAt.y, info_item.CameraLookAt.z))

        //             let radius = camera.position.distanceTo(
        //                 new THREE.Vector3(
        //                     tweenArray[j].lookat.x,
        //                     tweenArray[j].lookat.y,
        //                     tweenArray[j].lookat.z
        //                 )
        //             );

        //             let sphere = new THREE.Sphere(camera.position, radius);

        //             const raycaster = new THREE.Raycaster();
        //             raycaster.setFromCamera({ x: 0, y: 0 }, camera);

        //             // //包围球与摄像头中心射线的交叉点
        //             let intersectionPoint = new THREE.Vector3();

        //             raycaster.ray.intersectSphere(sphere, intersectionPoint);

        //             // let a = raycaster.ray.distanceToPoint(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z))
        //             // console.log('射线离点的距离是', a)

        //             // //求 摄像机-交点 和 摄像机-目标点 之间的夹角，如果夹角很小，则不需要执行过渡动画
        //             // //判断部分以后有需要再做，感觉不做也行，100毫秒过渡够了

        //             // // camera.updateMatrix()

        //             // const v3_camera_to_intersect = new THREE.Vector3()
        //             // v3_camera_to_intersect.subVectors(intersectionPoint, camera.position)

        //             // const v3_camera_to_sphere = new THREE.Vector3()
        //             // v3_camera_to_sphere.subVectors(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z), camera.position)

        //             // let angle_ = v3_camera_to_intersect.angleTo(v3_camera_to_sphere)

        //             // console.log('夹角angle_是', angle_)

        //             // //夹角是0，不用镜头过渡动画
        //             // //实际应用中，不知道为什么会产生一个小夹角，导致产生了一个不应该出现的中心点过渡动画，即使是同一个步骤连续播两次的情况也会出现过渡动画
        //             // //所以只要夹角在0.06弧度（约为3度）内就不会产生过渡动画

        //             // console.log('tweenArray[j].lookat', tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z)

        //             // //世界坐标转局部坐标
        //             // //调试查看数据用
        //             // let localAMatrix = model.worldToLocal(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z))

        //             // console.log('局部的坐标是:', localAMatrix)

        //             // if (angle_ > 0.1) {

        //             await new Promise((resolve) => {
        //                 new TWEEN.Tween({
        //                     x: intersectionPoint.x,
        //                     y: intersectionPoint.y,
        //                     z: intersectionPoint.z,
        //                 })
        //                     .to(
        //                         {
        //                             x: tweenArray[j].lookat.x,
        //                             y: tweenArray[j].lookat.y,
        //                             z: tweenArray[j].lookat.z,
        //                         },
        //                         200
        //                     )

        //                     .onUpdate(function () {
        //                         // AutoAnimationGroup.children[i].visible = true
        //                         // // 更新物体的位置等
        //                         // AutoAnimationGroup.children[i].position.set(this.x, this.y, this.z);
        //                         console.log(
        //                             "更新摄像机坐标过渡",
        //                             intersectionPoint,
        //                             tweenArray[j].lookat,
        //                             this.x,
        //                             this.y,
        //                             this.z
        //                         );
        //                         camera.lookAt(new THREE.Vector3(this.x, this.y, this.z));
        //                         OrbitControl.target.copy(new THREE.Vector3(this.x, this.y, this.z));
        //                         OrbitControl.update();
        //                     })
        //                     .onComplete(() => {
        //                         resolve();
        //                     })
        //                     .start();
        //             });

        //             // } else {
        //             //     camera.lookAt(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z))
        //             //     OrbitControl.target.copy(new THREE.Vector3(tweenArray[j].lookat.x, tweenArray[j].lookat.y, tweenArray[j].lookat.z))
        //             //     OrbitControl.update()
        //             // }

        //             //***********************以上解决两个动画的lookAt点不同而造成的画面快速切换造成的闪烁问题**************** */

        //             //看看现在的位置是不是就是动画的目标位置，如果是，则跳过这个动画执行
        //             // cameraAnimationTweenArr.push({
        //             //             targetPosition: CameraTween2.targetPosition,
        //             //             tween: CameraTween2.CameraTween,
        //             //             lookat: worldCamerSphereCenter_1.clone()
        //             //         })

        //             console.log(
        //                 "摄像机动画离目标位置的距离",
        //                 tweenArray[j].targetPosition.distanceTo(camera.position)
        //             );
        //             //如果摄像机在目标位置，则不用执行该摄像机动画
        //             if (tweenArray[j].targetPosition.distanceTo(camera.position) > 10) {
        //                 tweenArray[j].tween.start();

        //                 // tweenArray[j].tween.delay()

        //                 PromiseTweenArr.push(
        //                     new Promise(function (resolve) {
        //                         tweenArray[j].tween.onComplete(resolve);
        //                     })
        //                 );
        //             } else {
        //                 camera.lookAt(
        //                     new THREE.Vector3(
        //                         tweenArray[j].lookat.x,
        //                         tweenArray[j].lookat.y,
        //                         tweenArray[j].lookat.z
        //                     )
        //                 );

        //                 // tweenArray[j].tween.setAnimationDuraction(100),
        //                 // tweenArray[j].tween.start()

        //                 // PromiseTweenArr.push(new Promise(function (resolve) {
        //                 //     tweenArray[j].tween.onComplete(resolve);
        //                 // }));
        //                 // camera.position.copy(weenArray[j].targetPosition)
        //             }
        //         }
        //     }

        //     //等待所有的promise完成
        //     await Promise.all(PromiseTweenArr);
        //     console.log("相机动画添加执行完毕");
        // },


        //传入右侧动画，即可将摄像机最大化显示包围球
        // cameraLookAtSphere(info_item) {
        //     //根据观察角计算观察角
        //     if (
        //         info_item.CameraAngel.HorizontalAngel != null &&
        //         info_item.CameraAngel.VerticalAngel != null &&
        //         info_item.CameraSphereRadius != null &&
        //         info_item.CameraSphereRadius * 1 > 0 &&
        //         info_item.CameraLookAt.x != null &&
        //         info_item.CameraLookAt.y != null &&
        //         info_item.CameraLookAt.z != null
        //     ) {
        //         //字符转数字
        //         info_item.CameraAngel.HorizontalAngel *= 1;

        //         info_item.CameraAngel.VerticalAngel *= 1;

        //         info_item.CameraSphereRadius *= 1;

        //         // info_item.CameraSphereRadius *= 1

        //         info_item.CameraLookAt.x *= 1;

        //         info_item.CameraLookAt.y *= 1;

        //         info_item.CameraLookAt.z *= 1;

        //         let radius = info_item.CameraSphereRadius;

        //         container = document.querySelector("#three2");

        //         console.log("container333", container);
        //         console.log("container333", container.offsetWidth);

        //         const canvasWidth = container.offsetWidth;
        //         const canvasHeight = container.offsetHeight;

        //         console.log(canvasWidth, canvasHeight);

        //         if (canvasWidth < canvasHeight) {
        //             radius = radius * (canvasHeight / canvasWidth);
        //         }

        //         //如果是竖屏，则修改这个半径以达到包围球在竖屏中正常显示的效果
        //         //1.1是一个系数
        //         const distance = (radius * 1.1) / Math.tan(((Math.PI / 180) * camera.fov) / 2);

        //         //水平和垂直角度都是0的时候，以世界坐标的z轴负方向相同
        //         const horizontalAngle = info_item.CameraAngel.HorizontalAngel;
        //         //垂直角度
        //         const verticalAngle = info_item.CameraAngel.VerticalAngel;

        //         //调整办法
        //         //先确定y在经过包围盒中心点的水平面上的投影度
        //         //然后根据该投影长度去求x和z的值
        //         //distance其实就是包围球的半径
        //         const y_distanceToCenterOnXZ_Plane =
        //             distance * Math.cos((Math.PI / 180) * verticalAngle);

        //         console.log("y_distanceToCenterOnXZ_Plane", y_distanceToCenterOnXZ_Plane);

        //         let worldCamerSphereCenter = model.localToWorld(
        //             new THREE.Vector3(
        //                 info_item.CameraLookAt.x,
        //                 info_item.CameraLookAt.y,
        //                 info_item.CameraLookAt.z
        //             )
        //         );

        //         const cameraPosition = {
        //             x:
        //                 worldCamerSphereCenter.x +
        //                 y_distanceToCenterOnXZ_Plane * Math.sin((Math.PI / 180) * horizontalAngle),
        //             y:
        //                 worldCamerSphereCenter.y +
        //                 distance * Math.sin((Math.PI / 180) * verticalAngle),
        //             z:
        //                 worldCamerSphereCenter.z +
        //                 y_distanceToCenterOnXZ_Plane * Math.cos((Math.PI / 180) * horizontalAngle),
        //         };

        //         camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);

        //         console.log(
        //             "lookat1221222",
        //             worldCamerSphereCenter.x,
        //             worldCamerSphereCenter.y,
        //             worldCamerSphereCenter.z
        //         );

        //         console.log(
        //             "info_item.CameraLookAt",
        //             info_item.CameraLookAt.x,
        //             info_item.CameraLookAt.y,
        //             info_item.CameraLookAt.z
        //         );

        //         camera.lookAt(worldCamerSphereCenter);

        //         OrbitControl.target.copy(worldCamerSphereCenter);

        //         OrbitControl.update();
        //     } else {
        //         this.$message({
        //             message: "无法计算摄像机坐标，原因:缺少观察角或半径或包围球中心坐标",
        //             type: "warning",
        //         });
        //     }
        // },

        //将步骤中的动画数据生成出来（自动包围球关闭的模式下）
        // CameraAnimation_AutoMode_false(i_info, canvasWidth, canvasHeight) {
        //     //先找到本步骤的动画数据组

        //     if (
        //         i_info.CameraAngel == null ||
        //         i_info.CameraAngel.HorizontalAngel == null ||
        //         i_info.CameraAngel.VerticalAngel == null ||
        //         !i_info.CameraAnimationEnable ||
        //         i_info.CameraLookAt == null ||
        //         i_info.CameraLookAt.x == null ||
        //         i_info.CameraLookAt.y == null ||
        //         i_info.CameraLookAt.z == null ||
        //         i_info.CameraSphereRadius == null ||
        //         i_info.Duration == null
        //     ) {
        //         return;
        //     }

        //     //根据参数计算摄像机的目标位置

        //     let radius = i_info.CameraSphereRadius * 1;

        //     //竖屏状态下要缩小半径，要不然包围球会超出
        //     if (canvasWidth < canvasHeight) {
        //         radius = radius * (canvasHeight / canvasWidth);
        //     }

        //     // console.log('camera.position', camera.position.x, camera.position.y, camera.position.z)
        //     // const lookAt = new THREE.Vector3(i_info.CameraLookAt.x * 1, i_info.CameraLookAt.y * 1, i_info.CameraLookAt.z * 1)
        //     const lookAt = model.localToWorld(
        //         new THREE.Vector3(
        //             i_info.CameraLookAt.x * 1,
        //             i_info.CameraLookAt.y * 1,
        //             i_info.CameraLookAt.z * 1
        //         )
        //     );
        //     console.log(
        //         "lookat334444,",
        //         new THREE.Vector3(
        //             i_info.CameraLookAt.x * 1,
        //             i_info.CameraLookAt.y * 1,
        //             i_info.CameraLookAt.z * 1
        //         )
        //     );
        //     console.log(lookAt.x, lookAt.y, lookAt.z);
        //     // const cameraLookAt = lookAt

        //     const distance = (radius * 1.1) / Math.tan(((Math.PI / 180) * camera.fov) / 2);

        //     //水平和垂直角度都是0的时候，以世界坐标的z轴负方向相同
        //     const horizontalAngle = i_info.CameraAngel.HorizontalAngel * 1;

        //     //垂直角度
        //     const verticalAngle = i_info.CameraAngel.VerticalAngel * 1;

        //     //原创，后期可能会忘，希望以下说明能尽可能清楚
        //     //这段代码会导致摄像机没办法正常的到达模型的顶部和底部，只能保证前后左右的视角是正常的，所以需要调整

        //     //调整办法
        //     //先确定y在经过包围盒中心点的水平面上的投影度
        //     //然后根据该投影长度去求x和z的值
        //     //distance其实就是包围球的半径
        //     const y_distanceToCenterOnXZ_Plane =
        //         distance * Math.cos((Math.PI / 180) * verticalAngle);

        //     console.log("y_distanceToCenterOnXZ_Plane999", y_distanceToCenterOnXZ_Plane);

        //     const cameraPosition = {
        //         x:
        //             lookAt.x +
        //             y_distanceToCenterOnXZ_Plane * Math.sin((Math.PI / 180) * horizontalAngle),
        //         y: lookAt.y + distance * Math.sin((Math.PI / 180) * verticalAngle),
        //         z:
        //             lookAt.z +
        //             y_distanceToCenterOnXZ_Plane * Math.cos((Math.PI / 180) * horizontalAngle),
        //     };

        //     let duration = i_info.Duration > 0 ? i_info.Duration : 1000;

        //     const CameraTween = new TWEEN.Tween(camera.position);

        //     CameraTween.to(
        //         { x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z },
        //         duration
        //     ).onUpdate(function () {
        //         camera.lookAt(lookAt);
        //         // camera.lookAt(cameraLookAt);

        //         OrbitControl.target.copy(lookAt);

        //         OrbitControl.update();

        //         // camera.lookAt(new THREE.Vector3())
        //     });

        //     //慢入慢出情况
        //     if (i_info.Effects == "lowSpeedInlowSpeedOut") {
        //         CameraTween.easing(TWEEN.Easing.Quadratic.InOut); // 设置插值方式 慢入慢出 lowSpeedInlowSpeedOut

        //         //匀速情况
        //     } else if (i_info.Effects == "uniformSpeed") {
        //         CameraTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed

        //         //未定义情况，默认匀速
        //     } else {
        //         CameraTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed
        //     }

        //     // CameraTween.start()
        //     // return CameraTween
        //     return {
        //         CameraTween,
        //         targetPosition: new THREE.Vector3(
        //             cameraPosition.x,
        //             cameraPosition.y,
        //             cameraPosition.z
        //         ),
        //     };
        // },

        //将步骤中的动画数据生成出来（自动包围球开启的模式下）
        // CameraAnimation_AutoMode_true(i_info, canvasWidth, canvasHeight, stepCameraAnimationPart) {
        //     //先找到本步骤的动画数据组

        //     // if (i_info.CameraAngel == null || i_info.CameraAngel.HorizontalAngel == null || i_info.CameraAngel.VerticalAngel == null || !i_info.CameraAnimationEnable || i_info.CameraLookAt == null
        //     //     || i_info.CameraLookAt.x == null || i_info.CameraLookAt.y == null || i_info.CameraLookAt.z == null || i_info.CameraSphereRadius == null || i_info.Duration == null) { return }

        //     const sphere = this.CreateSphereFromThisAndBeforeStep(stepCameraAnimationPart);
        //     //根据参数计算摄像机的目标位置

        //     let radius = sphere.radius;

        //     //竖屏状态下要缩小半径，要不然包围球会超出
        //     if (canvasWidth < canvasHeight) {
        //         radius = radius * (canvasHeight / canvasWidth);
        //     }

        //     // console.log('camera.position', camera.position.x, camera.position.y, camera.position.z)
        //     // const lookAt = new THREE.Vector3(i_info.CameraLookAt.x * 1, i_info.CameraLookAt.y * 1, i_info.CameraLookAt.z * 1)

        //     // const lookAt = new THREE.Vector3(sphere.center.x, sphere.center.y, sphere.center.z)
        //     const lookAt = model.localToWorld(
        //         new THREE.Vector3(
        //             i_info.CameraLookAt.x * 1,
        //             i_info.CameraLookAt.y * 1,
        //             i_info.CameraLookAt.z * 1
        //         )
        //     );

        //     // const cameraLookAt = lookAt

        //     const distance = (radius * 1.1) / Math.tan(((Math.PI / 180) * camera.fov) / 2);

        //     //水平和垂直角度都是0的时候，以世界坐标的z轴负方向相同
        //     const horizontalAngle =
        //         i_info.CameraAngel.HorizontalAngel != null
        //             ? i_info.CameraAngel.HorizontalAngel * 1
        //             : 45;

        //     //垂直角度
        //     const verticalAngle =
        //         i_info.CameraAngel.VerticalAngel != null
        //             ? i_info.CameraAngel.VerticalAngel * 1
        //             : 45;

        //     //原创，后期可能会忘，希望以下说明能尽可能清楚
        //     //这段代码会导致摄像机没办法正常的到达模型的顶部和底部，只能保证前后左右的视角是正常的，所以需要调整

        //     //调整办法
        //     //先确定y在经过包围盒中心点的水平面上的投影度
        //     //然后根据该投影长度去求x和z的值
        //     //distance其实就是包围球的半径
        //     const y_distanceToCenterOnXZ_Plane =
        //         distance * Math.cos((Math.PI / 180) * verticalAngle);

        //     console.log("y_distanceToCenterOnXZ_Plane999", y_distanceToCenterOnXZ_Plane);

        //     const cameraPosition = {
        //         x: lookAt.x + y_distanceToCenterOnXZ_Plane * Math.sin((Math.PI / 180) * horizontalAngle),
        //         y: lookAt.y + distance * Math.sin((Math.PI / 180) * verticalAngle),
        //         z: lookAt.z + y_distanceToCenterOnXZ_Plane * Math.cos((Math.PI / 180) * horizontalAngle),
        //     };

        //     let duration = i_info.Duration > 0 ? i_info.Duration : 1000;

        //     const CameraTween = new TWEEN.Tween(camera.position);

        //     CameraTween.to(
        //         { x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z },
        //         duration
        //     ).onUpdate(function () {
        //         camera.lookAt(lookAt);
        //         // camera.lookAt(cameraLookAt);

        //         OrbitControl.target.copy(lookAt);

        //         OrbitControl.update();

        //         // camera.lookAt(new THREE.Vector3())
        //     });

        //     //慢入慢出情况
        //     if (i_info.Effects == "lowSpeedInlowSpeedOut") {
        //         CameraTween.easing(TWEEN.Easing.Quadratic.InOut); // 设置插值方式 慢入慢出 lowSpeedInlowSpeedOut

        //         //匀速情况
        //     } else if (i_info.Effects == "uniformSpeed") {
        //         CameraTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed

        //         //未定义情况，默认匀速
        //     } else {
        //         CameraTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed
        //     }

        //     // CameraTween.start()

        //     // return CameraTween

        //     return {
        //         CameraTween,
        //         targetPosition: new THREE.Vector3(
        //             cameraPosition.x,
        //             cameraPosition.y,
        //             cameraPosition.z
        //         ),
        //     };
        // },

        //根据本步骤和前面的步骤求包围球
        // CreateSphereFromThisAndBeforeStep(stepCameraAnimationPart) {
        //     console.log('stepCameraAnimationPart999', stepCameraAnimationPart)
        //     //求出中心点
        //     const showgroup = stepCameraAnimationPart.parent;

        //     const bbox = new THREE.Box3();

        //     const chooseStep = stepCameraAnimationPart.userData.MyStep;

        //     let ThisAndBeforeStepPartArr = [];

        //     let sphere;

        //     for (let i = 0; i < showgroup.children.length; i++) {
        //         //将本步骤及小于本步骤的所有零件存起来
        //         if (showgroup.children[i].userData.MyStep <= chooseStep) {

        //             ThisAndBeforeStepPartArr.push(showgroup.children[i]);
        //             console.log('ThisAndBeforeStepPartArr', ThisAndBeforeStepPartArr)

        //             //将本层的本步骤以前的所有零件的坐标和旋转都设为安装时的坐标和旋转，保证求出来的包围盒可靠
        //             const i_userData = showgroup.children[i].userData;

        //             // //求出包围盒后将零件移动回之前的旋转和位置，从而保证动画前零件保持在原来 位置（为 延迟期间是否变换功能 服务）
        //             const backupPosition = showgroup.children[i].position.clone();
        //             const backupRotation = showgroup.children[i].quaternion.clone();

        //             // console.log('i_userData.placePosition', i_userData.placePosition)
        //             showgroup.children[i].position.set(
        //                 i_userData.placePosition.x,
        //                 i_userData.placePosition.y,
        //                 i_userData.placePosition.z
        //             );
        //             showgroup.children[i].quaternion.set(
        //                 i_userData.placeQuaternion.x,
        //                 i_userData.placeQuaternion.y,
        //                 i_userData.placeQuaternion.z,
        //                 i_userData.placeQuaternion.w

        //             );

        //             //先将showgroup.children[i]更新，避免包围盒不准确
        //             showgroup.children[i].updateMatrixWorld();

        //             showgroup.children[i].traverse((c) => {
        //                 if (c.isMesh) {
        //                     bbox.expandByObject(c);
        //                 }
        //             });

        //             //求出安装状态的包围盒后将零件还原到上面求包围盒前的备份状态,备份及还原的操作主要是保证动画时不影响到包围盒尺寸
        //             showgroup.children[i].position.copy(backupPosition);
        //             showgroup.children[i].rotation.copy(backupRotation);
        //         }
        //     }

        //     sphere = new THREE.Sphere();

        //     bbox.getBoundingSphere(sphere);


        //     console.log('本步骤前步骤的包围盒参数是', bbox, sphere)

        //     //自动填入包围球的中心坐标
        //     // info_item.CameraLookAt.x = sphere.center.x
        //     // info_item.CameraLookAt.y = sphere.center.y
        //     // info_item.CameraLookAt.z = sphere.center.z

        //     // camera.lookAt(new THREE.Vector3(cameraLookAt_x,cameraLookAt_y,cameraLookAt_z));

        //     // camera.updateProjectionMatrix ()

        //     // OrbitControl.target.copy(sphere.center)

        //     // OrbitControl.update()

        //     return sphere;
        // },

        //根据mpd数据为零件添加动画数据，将tween动画数据保存到零件中，这样任何时候要用这个动画我们只用执行动画就行了
        //RestorePartsPositionRotation 是否还原模型零件内的所有零件的旋转和位置
        // CreateStepPartsTweenDataSaveInPart() {
        // CreateStepPartsTweenDataOnStep(partsInStep_arr) {

        //     thisStepPartAnimation = []
        //     //所有的tween只能执行一次，本来是计划stop 后start 可以实现重复使用的，结果发现不行，只能执行一遍就不能执行第二遍了，不知为啥
        //     //所以每次播放前都会重新生成tween动画

        //     // 
        //     //现在我们可以将该步骤中的动画信息提取出来后执行显示出来

        //     // let ThisAndBeforeStepPartArr = [];

        //     // let StepAnimationInfo = []

        //     //还原位置和旋转

        //     //移除所有正在执行的动画
        //     //这一步要改，要将所有的零件的或者组的安装动画停止
        //     // this.removeAllStepAnimationAndPartRestorePositionAndRotation();

        //     //此处还需要停止所有动画

        //     //所有组和零件还原到备份位置
        //     for (let i = 0; i < partAndGroupArr.length; i++) {

        //         if (!partAndGroupArr[i].userData.placePosition) { continue }
        //         let placePosition = partAndGroupArr[i].userData.placePosition


        //         if (partAndGroupArr[i].position.x != placePosition.x || partAndGroupArr[i].position.y != placePosition.y || partAndGroupArr[i].position.z != placePosition.z) {
        //             partAndGroupArr[i].position.set(placePosition.x, placePosition.y, placePosition.z)
        //         }

        //         if (!partAndGroupArr[i].position) { continue }

        //         if (!partAndGroupArr[i].userData.placeRotation) { continue }

        //         let placeRotation = partAndGroupArr[i].userData.placeRotation

        //         if (partAndGroupArr[i].rotation.x != placeRotation.x || partAndGroupArr[i].rotation.y != placeRotation.y || partAndGroupArr[i].rotation.z != placeRotation.z) {
        //             partAndGroupArr[i].rotation.set(placeRotation.x, placeRotation.y, placeRotation.z)
        //         }
        //     }


        //     //根据动画信息生成tween动画放置到零件或者组的userdata中
        //     for (let i = 0; i < partsInStep_arr.length; i++) {

        //         console.log('partsInStep_arr0000', partsInStep_arr[i])

        //         if (partsInStep_arr[i].userData.stepAnimationInfo) {

        //             partsInStep_arr[i].userData.stepAnimationTween = []

        //             const PartOrGroupAnimationInfoArr = partsInStep_arr[i].userData.stepAnimationInfo
        //             //遍历该零件或者组的动画数组生成生成TWEEN动画数据存放到userdata中
        //             for (let j = 0; j < PartOrGroupAnimationInfoArr.length; j++) {

        //                 // for (let j in PartOrGroupAnimationInfoArr_jArr) {
        //                 let PartOrGroupAnimationInfoArr_j = PartOrGroupAnimationInfoArr[j];

        //                 const endPosition = PartOrGroupAnimationInfoArr_j.endPosition;

        //                 const endRotation = PartOrGroupAnimationInfoArr_j.endRotation;

        //                 console.log('endPosition12112', endPosition)

        //                 //延迟动画参数
        //                 let startDelay;
        //                 if (PartOrGroupAnimationInfoArr_j.startDelay) {
        //                     startDelay = PartOrGroupAnimationInfoArr_j.startDelay;
        //                 } else {
        //                     startDelay = 0;
        //                 }

        //                 //延迟期间的可见性 PartOrGroupAnimationInfoArr_j.startDelay 延迟启动才能使用可见性false
        //                 // if (PartOrGroupAnimationInfoArr_j.delayVisible == false && startDelay != 0 && PartOrGroupAnimationInfoArr_j.startDelay) {
        //                 //

        //                 //延迟期间的可见性 stepAnimationInfo_obj.startDelay 延迟启动才能使用可见性false
        //                 // if (stepAnimationInfo_obj.delayVisible == false && startDelay != 0 && stepAnimationInfo_obj.startDelay) {
        //                 //
        //                 if (PartOrGroupAnimationInfoArr_j.delayVisible) {
        //                     partsInStep_arr[i].visible = true;
        //                 } else {
        //                     partsInStep_arr[i].visible = false;
        //                 }


        //                 console.log(
        //                     "动画前的可见性 ",
        //                     PartOrGroupAnimationInfoArr_j,
        //                     PartOrGroupAnimationInfoArr_j.delayVisible
        //                 );

        //                 // if (PartOrGroupAnimationInfoArr_j.delayVisible) {
        //                 //     showgroup.children[i].visible = true;
        //                 // } else {
        //                 //     showgroup.children[i].visible = false;
        //                 // }

        //                 // 创建一个Tween对象用于修改位置
        //                 // showgroup.children[i].position.copy(PartOrGroupAnimationInfoArr_j.startPosition)

        //                 let startPosition = new THREE.Vector3(
        //                     PartOrGroupAnimationInfoArr_j.startPosition.x,
        //                     PartOrGroupAnimationInfoArr_j.startPosition.y,
        //                     PartOrGroupAnimationInfoArr_j.startPosition.z
        //                 );

        //                 let startRoatatin = new THREE.Quaternion(
        //                     PartOrGroupAnimationInfoArr_j.startRotation.x,
        //                     PartOrGroupAnimationInfoArr_j.startRotation.y,
        //                     PartOrGroupAnimationInfoArr_j.startRotation.z,
        //                     PartOrGroupAnimationInfoArr_j.startRotation.w,
        //                 );

        //                 //直接将物体移动到 startPosition和rotation
        //                 // stepAnimationInfo.delayTransformation = false
        //                 // stepAnimationInfo.delayVisible = false

        //                 // if (!PartOrGroupAnimationInfoArr_j.delayTransformation) {
        //                 //     //     console.log('设置延迟前变换前的状态', startPosition)

        //                 //     showgroup.children[i].position.copy(startPosition);

        //                 //     showgroup.children[i].rotation.copy(startRoatatin);
        //                 // }

        //                 console.log(
        //                     "PartOrGroupAnimationInfoArr_j.duration",
        //                     PartOrGroupAnimationInfoArr_j.duration
        //                 );
        //                 // const positionTween = new TWEEN.Tween(showgroup.children[i].position);
        //                 // positionTween.to({ x: endPosition.x, y: endPosition.y, z: endPosition.z }, PartOrGroupAnimationInfoArr_j.duration);
        //                 const positionTween = new TWEEN.Tween({
        //                     x: startPosition.x,
        //                     y: startPosition.y,
        //                     z: startPosition.z

        //                 })
        //                     .to(
        //                         { x: endPosition.x, y: endPosition.y, z: endPosition.z },
        //                         PartOrGroupAnimationInfoArr_j.duration
        //                     )
        //                     // .onStart(function () {
        //                     //     partsInStep_arr[i].position.set(startPosition.x,startPosition.y,startPosition.z)
        //                     //     console.log('tween动画执行开始事件')

        //                     // })

        //                     // .easing(TWEEN.Easing.Quadratic.InOut) // 缓动函数，可根据需要选择合适的缓动函数
        //                     .onUpdate(function () {
        //                         // showgroup.children[i].visible = true;
        //                         // 在Tween对象的每个时间步骤更新中执行的操作
        //                         // 更新物体的位置等
        //                         partsInStep_arr[i].position.set(this.x, this.y, this.z);

        //                         partsInStep_arr[i].visible = true;


        //                     });

        //                 //慢入慢出情况
        //                 if (PartOrGroupAnimationInfoArr_j.AnimationEffects == "lowSpeedInlowSpeedOut") {
        //                     positionTween.easing(TWEEN.Easing.Quadratic.InOut); // 设置插值方式 慢入慢出 lowSpeedInlowSpeedOut

        //                     //匀速情况
        //                 } else if (PartOrGroupAnimationInfoArr_j.AnimationEffects == "uniformSpeed") {
        //                     positionTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed

        //                     //未定义情况，默认匀速
        //                 } else {
        //                     positionTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed
        //                 }

        //                 positionTween.delay(startDelay);
        //                 // positionTween.start();

        //                 // 创建一个Tween对象用于修改方位
        //                 // showgroup.children[i].rotation.copy(startRoatatin)

        //                 const rotationTween = new TWEEN.Tween(startRoatatin);
        //                 rotationTween.to(
        //                     { x: endRotation.x, y: endRotation.y, z: endRotation.z },
        //                     PartOrGroupAnimationInfoArr_j.duration
        //                 ).onUpdate(function () {
        //                     // showgroup.children[i].visible = true;
        //                     // 在Tween对象的每个时间步骤更新中执行的操作
        //                     // 更新物体的位置等
        //                     partsInStep_arr[i].rotation.set(this.x, this.y, this.z);

        //                     // partsInStep_arr[i].updateMatrixWorld();
        //                 });



        //                 //慢入慢出情况
        //                 if (PartOrGroupAnimationInfoArr_j.AnimationEffects == "lowSpeedInlowSpeedOut") {
        //                     rotationTween.easing(TWEEN.Easing.Quadratic.InOut); // 设置插值方式 慢入慢出 lowSpeedInlowSpeedOut

        //                     //匀速情况
        //                 } else if (PartOrGroupAnimationInfoArr_j.AnimationEffects == "uniformSpeed") {
        //                     rotationTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed

        //                     //未定义情况，默认匀速
        //                 } else {
        //                     rotationTween.easing(TWEEN.Easing.Linear.None); //匀速uniformSpeed
        //                 }

        //                 rotationTween.delay(startDelay);

        //                 // partsInStep_arr[i].userData.stepAnimationTween.push({
        //                 //     positionTween,
        //                 //     rotationTween,
        //                 // });

        //                 thisStepPartAnimation.push(
        //                     {
        //                         positionTween,
        //                         rotationTween,
        //                     }
        //                 )

        //             }

        //         }

        //     }



        // },

        //停止所有动画
        // stopAllTweenAnimation() {

        //     for (let i = 0; i < thisStepPartAnimation.length; i++) {

        //         if (thisStepPartAnimation[i].positionTween) {

        //             console.log('停止位置动画', thisStepPartAnimation[i].positionTween)
        //             thisStepPartAnimation[i].positionTween.stop()
        //         }

        //         if (thisStepPartAnimation[i].rotationTween) {
        //             console.log('停止旋转动画', thisStepPartAnimation[i].positionTween)

        //             thisStepPartAnimation[i].rotationTween.stop()
        //         }



        //     }

        //     // for (let i = 0; i < partAndGroupArr.length; i++) {

        //     //     if (Array.isArray(partAndGroupArr[i].userData.stepAnimationTween)) {

        //     //         const partOrGroupAnimationArr = partAndGroupArr[i].userData.stepAnimationTween

        //     //         for (let j = 0; j < partOrGroupAnimationArr.length; j++) {



        //     //             if (partOrGroupAnimationArr[j].positionTween) {

        //     //                 console.log('停止位置动画', partOrGroupAnimationArr[j].positionTween)
        //     //                 partOrGroupAnimationArr[j].positionTween.stop()
        //     //             }

        //     //             if (partOrGroupAnimationArr[j].rotationTween) {
        //     //                 console.log('停止旋转动画', partOrGroupAnimationArr[j].positionTween)

        //     //                 partOrGroupAnimationArr[j].rotationTween.stop()
        //     //             }

        //     //         }

        //     //     }

        //     // }

        // },

        //播放本步骤零件动画
        // async playThisStepPartOrGroupAnimation(thisStepPartsArr) {

        //     console.log('播放本步骤动画哟:', thisStepPartsArr)

        //     let tweenArray = thisStepPartAnimation

        //     // for (let i = 0; i < thisStepPartsArr.length; i++) {

        //     //     if (!thisStepPartsArr[i].userData.stepAnimationTween) { continue }

        //     //     const PartOrGroupUserdataTweenArr = thisStepPartsArr[i].userData.stepAnimationTween

        //     //     if (Array.isArray(PartOrGroupUserdataTweenArr)) {

        //     //         for (let j = 0; j < PartOrGroupUserdataTweenArr.length; j++) {

        //     //             // const TWEEN_j = 

        //     //             tweenArray.push(PartOrGroupUserdataTweenArr[j])



        //     //         }

        //     //     }
        //     // }

        //     let PromiseTweenArr = [];

        //     for (let j in tweenArray) {
        //         console.log("23执行动画23", j, tweenArray[j]);

        //         //看看i是不是带有position或者ratation（零件动画）
        //         // let positionOrRotationTween_i_bool = false;

        //         if (tweenArray[j].positionTween) {
        //             // positionOrRotationTween_i_bool = true;
        //             // tweenArray[j].rotationTween.startPosition.x = 100

        //             // tweenArray[j].rotationTween.startPosition.y = 100
        //             // tweenArray[j].rotationTween.startPosition.z = 100
        //             // tweenArray[j].positionTween.onStart(function () {
        //             //     this.x = 100
        //             //     this.y = 100
        //             //     this.z = 100
        //             // })


        //             tweenArray[j].positionTween.start();
        //             //执行动画后放入promise
        //             PromiseTweenArr.push(
        //                 new Promise(function (resolve) {
        //                     tweenArray[j].positionTween.onComplete(resolve);
        //                 })
        //             );
        //         }

        //         if (tweenArray[j].rotationTween) {
        //             // positionOrRotationTween_i_bool = true;

        //             // tweenArray[j].rotationTween.startPosition = { x: 100, y: 100, z: 100 };
        //             tweenArray[j].rotationTween.start();

        //             PromiseTweenArr.push(
        //                 new Promise(function (resolve) {
        //                     tweenArray[j].rotationTween.onComplete(resolve);
        //                 })
        //             );
        //         }

        //         //表示i不是零件动画，因为零件动画带有position和rotation属性
        //         // if (positionOrRotationTween_i_bool == false) {
        //         //     if (tweenArray[j]) {
        //         //         tweenArray[j].start();

        //         //         PromiseTweenArr.push(
        //         //             new Promise(function (resolve) {
        //         //                 tweenArray[j].onComplete(resolve);
        //         //             })
        //         //         );
        //         //     }
        //         // }
        //     }

        //     //等待所有的promise完成
        //     await Promise.all(PromiseTweenArr);

        //     console.log("动画添加执行完毕");

        // },

        //处理步骤动画
        //执行完成本组动画后执行下一组动画
        // async TweenArrAnimationsDo(tweenArray) {
        //     let PromiseTweenArr = [];

        //     for (let j in tweenArray) {
        //         console.log("23执行动画23", j, tweenArray[j]);

        //         //看看i是不是带有position或者ratation（零件动画）
        //         let positionOrRotationTween_i_bool = false;

        //         if (tweenArray[j].positionTween) {
        //             positionOrRotationTween_i_bool = true;
        //             tweenArray[j].positionTween.start();
        //             //执行动画后放入promise
        //             PromiseTweenArr.push(
        //                 new Promise(function (resolve) {
        //                     tweenArray[j].positionTween.onComplete(resolve);
        //                 })
        //             );
        //         }

        //         if (tweenArray[j].rotationTween) {
        //             positionOrRotationTween_i_bool = true;

        //             tweenArray[j].rotationTween.start();

        //             PromiseTweenArr.push(
        //                 new Promise(function (resolve) {
        //                     tweenArray[j].rotationTween.onComplete(resolve);
        //                 })
        //             );
        //         }

        //         //表示i不是零件动画，因为零件动画带有position和rotation属性
        //         if (positionOrRotationTween_i_bool == false) {
        //             if (tweenArray[j]) {
        //                 tweenArray[j].start();

        //                 PromiseTweenArr.push(
        //                     new Promise(function (resolve) {
        //                         tweenArray[j].onComplete(resolve);
        //                     })
        //                 );
        //             }
        //         }
        //     }

        //     //等待所有的promise完成
        //     await Promise.all(PromiseTweenArr);

        //     console.log("动画添加执行完毕");
        // },

        //退出所有步骤动画并将所有步骤还原
        // removeAllStepAnimationAndPartRestorePositionAndRotation(group) {
        //     console.log("11StepAnimationTweenArr33", StepAnimationTweenArr);

        //     for (let i in StepAnimationTweenArr) {
        //         StepAnimationTweenArr[i].positionTween.stop();
        //         StepAnimationTweenArr[i].rotationTween.stop();
        //     }

        //     //清空数组
        //     StepAnimationTweenArr.length = 0;


        //     group.traverse((c) => {
        //         if (c.isGroup) {
        //             if (c.userData.TYPE == "GRPUP" || c.userData.TYPE == "PART") {
        //                 //恢复原来的位置
        //                 if (c.userData.placePosition) {
        //                     //恢复位置
        //                     c.position.set(
        //                         c.userData.placePosition.x,
        //                         c.userData.placePosition.y,
        //                         c.userData.placePosition.z
        //                     );
        //                 }
        //                 if (c.userData.placeRotation) {
        //                     //恢复旋转
        //                     c.rotation.set(
        //                         c.userData.placeRotation.x,
        //                         c.userData.placeRotation.y,
        //                         c.userData.placeRotation.z
        //                     );
        //                 }
        //             }
        //         }
        //     });
        // },





        //这个不用 点击步骤相应逻辑代码要参考用 后期不用了就删除
        // async handleLeftStepImageClicked(e, imitate = false) {

        //     console.log('主程序接收到左侧的点击数据', e)
        //     //parentId partEmpty partIDArr partName stepNum
        //     //底部被选择的零件组清空组

        //     console.log('GlobalStepNum', e.GlobalStepNum)

        //     // const LeftScrollChooseGlobalStep = e.GlobalStepNum
        //     //在这里要立刻将右侧修改的可见性数据写入零件，否则下面部分代码将ChooseObjArrBottom选中零件切换后将不能写入可见性数据
        //     this.PartVisibleInStep_input()


        //     ChooseObjArrBottom = [];
        //     // this.bottomChoosePartArr = [];

        //     // this.LeftScrollChooseStep = e.stepNum;



        //     //右下角动画零件列表选中的index
        //     // Animation_PartListChooseIndex: null,
        //     // this.Animation_PartListChooseIndex = null;
        //     // this.Animation_PartListHoverIndex = null;

        //     //向底部列表发送消息
        //     // this.$emit("messageToBottom", { type: "LeftImageClick" });
        //     // SetBottomChooseHoverNull

        //     console.log("左侧步骤图被点击212", e);


        //     const ChooseStepIdArr = e.partIDArr;

        //     let stepParent = model.getObjectById(e.parentId);

        //     //选中的步骤保存到父组中
        //     stepParent.userData.ThisFoorChooseStepNum = e.stepNum;


        //     const ChoosePartIDArr = e.partIDArr;

        //     // this.ChoosePartIDArr = ChoosePartIDArr

        //     console.log("ChoosePartIDArr", ChoosePartIDArr);

        //     // console.log('leftStepChooseGroupArr', leftStepChooseGroupArr)
        //     console.log("stepParent", stepParent);

        //     //隐藏所有的包围盒子
        //     this.hideAllMyBoxHelperAndScllectFalse(model);

        //     //首先要显示所有零件
        //     //避免某些零件被隐藏导致显示错误
        //     //另外，如果零件是stepParent的父级，或者父级的父级，都要保证显示，
        //     //所以可以查找场景内所有dat或者ldr零件，只要不在stepParent内的零件都要隐藏

        //     // this.hidePartExcludeStepParent(model, stepParent, false)

        //     //低于选择零件总步骤的零件都隐藏
        //     // const choostepobj = stepParent.getObjectById(e.partIDArr[0])
        //     // let chooseStep = choostepobj.userData.MyStep
        //     model.traverse((c) => {
        //         if (c.isGroup) {
        //             if (c.name.indexOf(".dat") != -1 || c.name.indexOf(".ldr") != -1) {
        //                 // if(c.userData.MyStep&&c.userData.MyStep<){

        //                 // }
        //                 // console.log('显示性',c,false)

        //                 c.visible = false;
        //             } else if (c.userData.type && c.userData.type == "Model") {
        //                 //如果是零件组，都显示
        //                 c.visible = true;
        //                 // console.log('显示性',c,true)
        //             }
        //         }
        //     });

        //     //先把stepParent内的所有零件或者组都可见
        //     stepParent.traverse((c) => {
        //         if (c.isGroup) {
        //             if (c.name.indexOf(".dat") != -1 || c.name.indexOf(".ldr") != -1) {
        //                 c.visible = true;
        //             } else if (c.userData.type && c.userData.type == "Model") {
        //                 c.visible = true;
        //             }
        //         }
        //     });

        //     // 记录本步骤需要用到的零件组

        //     // materialArr[material.name]
        //     //显示出本步骤的零件
        //     // if (e.x == true) {
        //     for (let i in stepParent.children) {
        //         //本级零件中，如果左侧选中了某个步骤，则将该步骤的所有零件材质设为本来的颜色
        //         if (ChooseStepIdArr.indexOf(stepParent.children[i].id) != -1) {
        //             stepParent.children[i].visible = true;

        //             stepParent.children[i].traverse((c) => {
        //                 console.log('23333c2222', c)
        //                 // if(c.isLine){return}

        //                 if (c.isMesh && c.userData.colorCode) {

        //                     console.log('c.isMesh && c.userData.colorCode', c.userData.colorCode)

        //                     // if(lDrawLoader.materialLibrary.indexOf(c.userData.colorCode[0]) == -1){

        //                     //   console.log('不包含这个颜色值',c.userData.colorCode)

        //                     // }

        //                     // // 修改材质为本来的颜色
        //                     if (c.userData.colorCode.length > 1) {
        //                         // console.log("移除前", c, c.material);
        //                         c.material = c.userData.colorCode.map(function (color) {
        //                             // console.log("1提取材质组内的元素1", materialArr[color]);

        //                             return lDrawLoader.materialLibrary[color];

        //                         });

        //                         // console.log("恢复后的原色数组", c.material);
        //                     } else if (c.userData.colorCode) {

        //                         c.material = lDrawLoader.materialLibrary[c.userData.colorCode];

        //                     }
        //                 }
        //             });

        //             //本级零件中，如果不是左侧菜单选中的对象，则将本级零件中，ThisFloorStepNum低于选中
        //             //步骤的组设为本透明材质
        //             //高于ThisFloorStepNum的零件隐藏visible=false
        //         } else {
        //             //步骤的组设为本透明材质
        //             if (stepParent.children[i].userData.ThisFloorStepNum < e.stepNum) {
        //                 //改为半透明材质
        //                 stepParent.children[i].traverse((c) => {
        //                     //缺失模型代替方块不参与变透明
        //                     //没有名字的mesh无法设为半透明，因为如果它被设为半透明有将无法再恢复了
        //                     if (c.isMesh && c.material.name != "") {
        //                         console.log("设为半透明22222", c);
        //                         //修改材质的颜色
        //                         if (Array.isArray(c.material)) {
        //                             c.material = c.material.map(
        //                                 function () {
        //                                     return material_translucent;
        //                                 }
        //                             );
        //                         } else {
        //                             c.material = material_translucent;
        //                             console.log(c.material.name);
        //                         }
        //                     }
        //                     // }
        //                 });
        //             } else {
        //                 //高于ThisFloorStepNum的零件隐藏visible=false
        //                 stepParent.children[i].visible = false;
        //             }
        //         }
        //     }


        //     //显示或者隐藏本步骤中需要根据userdata中动态显示或者隐藏的零件
        //     this.PartOrGroupVisibleByStepData(model, LeftScrollChooseGlobalStep)

        //     this.placePositionAndRotation()
        //     //将底部的本步骤零件显示出来
        //     // if (e.name) {
        //     this.bottomPartList_Arr = bottomPartListCreater.CreatePartListImagesArr(
        //         stepParent,
        //         ChoosePartIDArr
        //     );

        //     console.log('this.bottomPartList_Arr ', this.bottomPartList_Arr)

        //     console.log("22stepParent11", stepParent);

        //     //临时旋转模型

        //     let bottomPartArr = [];

        //     for (let i in ChoosePartIDArr) {
        //         const part = showGroup.getObjectById(ChoosePartIDArr[i]);

        //         bottomPartArr.push(part);
        //     }

        //     console.log("2bottomPart1Arr1", bottomPartArr);

        //     if (bottomPartArr.length > 0) {
        //         let targetRotation_temp = bottomPartArr[0].userData.step_parent_rotate;

        //         if (targetRotation_temp) {
        //             showGroup.rotation.set(
        //                 targetRotation_temp.x,
        //                 targetRotation_temp.y,
        //                 targetRotation_temp.z
        //             );
        //         }
        //     }
        //     //更新后动画部分求出的物体的包围盒才正常
        //     // showGroup.updateMatrixWorld()

        //     console.log("1");

        //     //自定义坐标系z轴的按钮的背景颜色
        //     this.customCoordinateSystemBackColor = "";

        //     //清除操控帮助器
        //     this.exitParentRotateGroup_forView();

        //     //生成动画编辑右下角零件列表的数据
        //     if (imitate) {
        //         //模拟需要加1，不知道为什么
        //         this.CreateRightAnimationListData(
        //             showGroup,
        //             stepParent.userData.ThisFoorChooseStepNum + 1
        //         );
        //     } else {

        //         this.CreateRightAnimationListData(
        //             showGroup,
        //             stepParent.userData.ThisFoorChooseStepNum
        //         );

        //     }



        //     //更新右侧的软零件列表
        //     // this.ClickStep_updateSoftPartsInThisStep()
        //     // this.updateSoftPartsInThisStep()

        //     //更新右侧模型旋转按钮颜色
        //     // this.updateThisStepModelRotateState()


        //     // //获取动画信息并执行动画

        //     this.bottomChooseType = null
        //     // messageToBottom

        //     //存放相机动画的零件
        //     let StepCameraData_Part;

        //     //第一步，将本步骤的第一个零件内存入相机的数组信息
        //     for (let i in showGroup.children) {
        //         if (
        //             showGroup.children[i].userData.ThisFloorStepNum + 1 ==
        //             this.LeftScrollChooseStep
        //         ) {
        //             //后面将会把步骤的相机动画信息放置到每个步骤第一个零件的组内userdata内
        //             //只存储本步骤第一个零件
        //             if (!StepCameraData_Part) {
        //                 StepCameraData_Part = showGroup.children[i];
        //                 break;
        //             }
        //         }
        //     }

        //     console.log("点击步骤后，在步骤中找到的相机文件是", StepCameraData_Part);

        //     console.log('ModelRotateSetInChildren' in model.userData)


        //     //如果主模型内没有子孙对主模型旋转设置的参数，则将模型恢复到默认方位和位置（加载模型时的位置和方位）
        //     //模型内没有任何模型旋转设定
        //     //判断 model.userData 内有没有'ModelRotateSetInChildren'属性
        //     if ('ModelRotateSetInChildren' in model.userData && model.userData.ModelRotateSetInChildren == false) {
        //         //将模型设为默认的旋转和位置
        //         model.position.set(0, 0, 0)
        //         model.rotation.set(Math.PI, 0, 0)
        //     }



        //     //如果是模拟点击则不执行动画
        //     if (!imitate) {


        //         //创建步骤中的零件动画数据
        //         this.CreateStepPartsTweenData(
        //             stepParent,
        //             stepParent.userData.ThisFoorChooseStepNum
        //         );


        //         // 这个功能暂时还没有调试，先隐藏，下一版本开放
        //         // 就算下方函数去除注释也还不能用哈
        //         // this.AddChildrenAnimation()



        //         //主模型旋转动画
        //         await this.rotateParentAnimation();




        //         //从该步骤第一个零件的userdata中获取到该步骤的camera的动画信息并更新到右侧摄像机动画列表中
        //         this.getCameraAnimatinFromPart(StepCameraData_Part);

        //         this.CreateStepCameraTweenData();

        //         //执行它摄像机动画---异步执行
        //         // this.TweenArrAnimationsDo(cameraAnimationTweenArr)

        //         ////执行它摄像机动画---异步执行
        //         await this.CamreaTweenArrAnimationsDo(cameraAnimationTweenArr);

        //         //执行步骤中的零件动画
        //         await this.TweenArrAnimationsDo(StepAnimationTweenArr);

        //         console.log("所有动画完成2");
        //     }


        // },

        // CreateRightAnimationListData(showgroup, chooseStep).userData.ThisFoorChooseStepNum
        //将动画信息更新到右下角列表中 从零件userdata中提取动画信息
        // CreateAnimationListData(ObjArrInThisGlobleStep_arr) {
        //     // console.log("showgroup, chooseGlobleStep", showgroup, chooseGlobleStep);

        //     // let StepAnimationInfo = [];

        //     //按照数组顺序来执行动画

        //     for (let i in ObjArrInThisGlobleStep_arr) {
        //         if (ObjArrInThisGlobleStep_arr[i].userData.MyStep == chooseGlobleStep) {
        //             console.log(
        //                 "ObjArrInThisGlobleStep_arr[i].userData.ThisFloorStepNum",
        //                 ObjArrInThisGlobleStep_arr[i].userData.Mystep == chooseGlobleStep,
        //                 ObjArrInThisGlobleStep_arr[i].userData.Mystep,
        //                 chooseGlobleStep
        //             );

        //             // console.log("11ObjArrInThisGlobleStep_arr[i]11", ObjArrInThisGlobleStep_arr[i]);

        //             console.log(!ObjArrInThisGlobleStep_arr[i].userData.stepAnimationInfo);

        //             // let thisPartAnimationList = {}
        //             // console.log('查找本步骤的零件动画信息', ObjArrInThisGlobleStep_arr[i])

        //             //如果没有动画信息则生成简单的动画信息
        //             if (!ObjArrInThisGlobleStep_arr[i].userData.stepAnimationInfo) {

        //                 console.log("没有动画数据,设置自动动画");

        //                 if (
        //                     ObjArrInThisGlobleStep_arr[i].userData.TYPE == "GROUP" ||
        //                     ObjArrInThisGlobleStep_arr[i].userData.TYPE == "PART"
        //                 ) {
        //                     // console.log('满足条件的零件12', ObjArrInThisGlobleStep_arr[i])

        //                     //在这里先添加假数据
        //                     console.log(
        //                         "由于该组没有动画数据，所以添加动画的假数据，简单数据",
        //                         ObjArrInThisGlobleStep_arr[i]
        //                     );

        //                     let stepAnimationInfo = {};

        //                     //在本步骤中该零件的动画执行顺序
        //                     // stepAnimationInfo.stepNumber = 0
        //                     // { value: 'stepBegin', label: '步骤打开时' }, { value: 'DelayStart', label: '延迟启动' }
        //                     stepAnimationInfo.startOption = "stepBegin";

        //                     //延迟启动
        //                     // stepAnimationInfo.startDelay = 1000

        //                     //'stepBegin'、'withLastAnimation'、'afterLastAnimation'
        //                     //步骤开始时候 、 和上一个动画一起开始 、 在上一个动画之后开始
        //                     // stepAnimationInfo.startEvent = 'stepstart'

        //                     //开始位置和旋转
        //                     const new_start_Position = new THREE.Vector3()
        //                         .copy(ObjArrInThisGlobleStep_arr[i].position)
        //                         .add(new THREE.Vector3(0, -100, 0));

        //                     stepAnimationInfo.startPosition = {
        //                         x: new_start_Position.x,
        //                         y: new_start_Position.y,
        //                         z: new_start_Position.z,
        //                     };

        //                     stepAnimationInfo.startRotation = {
        //                         x: ObjArrInThisGlobleStep_arr[i].rotation.x,
        //                         y: ObjArrInThisGlobleStep_arr[i].rotation.y,
        //                         z: ObjArrInThisGlobleStep_arr[i].rotation.z,
        //                     };
        //                     // ObjArrInThisGlobleStep_arr[i].rotation.clone()

        //                     //结束位置和旋转
        //                     // const new_end_Position
        //                     stepAnimationInfo.endPosition = {
        //                         x: ObjArrInThisGlobleStep_arr[i].position.x,
        //                         y: ObjArrInThisGlobleStep_arr[i].position.y,
        //                         z: ObjArrInThisGlobleStep_arr[i].position.z,
        //                     };
        //                     // ObjArrInThisGlobleStep_arr[i].position.clone()
        //                     stepAnimationInfo.endRotation = {
        //                         x: ObjArrInThisGlobleStep_arr[i].rotation.x,
        //                         y: ObjArrInThisGlobleStep_arr[i].rotation.y,
        //                         z: ObjArrInThisGlobleStep_arr[i].rotation.z,
        //                     };
        //                     // ObjArrInThisGlobleStep_arr[i].rotation.clone()
        //                     //工作时长2秒钟
        //                     stepAnimationInfo.duration = 1000;
        //                     //动画效果
        //                     stepAnimationInfo.AnimationEffects = "lowSpeedInlowSpeedOut";

        //                     ObjArrInThisGlobleStep_arr[i].userData.stepAnimationInfo = [];

        //                     ObjArrInThisGlobleStep_arr[i].userData.stepAnimationInfo.push(stepAnimationInfo);

        //                     //备份零件的位置和旋转
        //                     ObjArrInThisGlobleStep_arr[i].userData.placePosition = {
        //                         x: ObjArrInThisGlobleStep_arr[i].position.x,
        //                         y: ObjArrInThisGlobleStep_arr[i].position.y,
        //                         z: ObjArrInThisGlobleStep_arr[i].position.z,
        //                     };
        //                     // ObjArrInThisGlobleStep_arr[i].position.clone()
        //                     ObjArrInThisGlobleStep_arr[i].userData.placeRotation = {
        //                         x: ObjArrInThisGlobleStep_arr[i].rotation.x,
        //                         y: ObjArrInThisGlobleStep_arr[i].rotation.y,
        //                         z: ObjArrInThisGlobleStep_arr[i].rotation.z,
        //                     };

        //                     // ObjArrInThisGlobleStep_arr[i].rotation.clone()

        //                     ObjArrInThisGlobleStep_arr[i].userData.placeMatrix4World = ObjArrInThisGlobleStep_arr[i].matrixWorld.clone();
        //                 }
        //             }


        //         }
        //     }
        //     //右下角本步骤动画相关的零件列表
        //     // this.ThisStepAnimationInfo = StepAnimationInfo;
        //     // console.log("ThisStepAnimationInfo", ThisStepAnimationInfo);

        //     // return StepAnimationInfo
        // },


        /***********************************************零件在各个步骤中的可见性设置 以下********** */

        //右侧输入可见性数据
        //检测输入的合法性
        // PartVisibleInStep_input(ObjArrInThisGlobleStep_arr) {

        //     console.log('PartVisibleInStep4444', this.PartVisibleInStep)

        //     if (this.PartVisibleInStep == null || this.PartVisibleInStep == '') {
        //         if (ChooseObjArrBottom.length == 1) {

        //             // this.PartVisibleInStep = resStr

        //             ChooseObjArrBottom[0].userData.PartVisibleInStep = null
        //             ChooseObjArrBottom[0].userData.PartVisibleInStepArr = null

        //             // console.log('选中的零件组是2111', ChooseObjArrBottom, this.PartVisibleInStep)

        //             //更新零件可见性数组
        //             this.partVisibleSetByStep()


        //             // 更新右侧动画数据

        //             this.CreateRightAnimationListData(
        //                 showGroup,
        //                 showGroup.userData.ThisFoorChooseStepNum
        //             );

        //         }
        //         return
        //     }

        //     // 去除空格
        //     this.PartVisibleInStep = this.PartVisibleInStep.replace(/\s/g, '');
        //     // 将中文分号替换为英文分号
        //     this.PartVisibleInStep = this.PartVisibleInStep.replace(/；/g, ';');
        //     // 只允许输入数字和分号
        //     this.PartVisibleInStep = this.PartVisibleInStep.replace(/[^0-9;]/g, '');
        //     //这是一个字符串;;包含多个分号;;挨着的情况;;"
        //     this.PartVisibleInStep = this.PartVisibleInStep.replace(/;+/g, ';');

        //     let numArr = this.PartVisibleInStep.split(';')




        //     //去除重合的数字和小于等于0的数字
        //     let numArr_no_same = []

        //     for (let i = 0; i < numArr.length; i++) {

        //         if (numArr_no_same.indexOf(numArr[i]) != -1) { continue }

        //         if (numArr[i] <= 0) { continue }

        //         numArr_no_same.push(numArr[i])

        //     }


        //     //从低到高排序
        //     let LowToHeightArr = numArr_no_same.sort((a, b) => a - b);

        //     //转换成数字
        //     for (let k = 0; k < LowToHeightArr.length; k++) {
        //         LowToHeightArr[k] *= 1
        //     }

        //     let resStr = ''

        //     //重新组合成 1；2；3；4；5的格式
        //     for (let i = 0; i < LowToHeightArr.length; i++) {

        //         resStr += LowToHeightArr[i]

        //         if (i < LowToHeightArr.length - 1) {

        //             resStr += ';'

        //         }

        //     }




        //     //将输入的可见性字符串值写入零件uesedata中



        //     if (ChooseObjArrBottom.length == 1) {

        //         this.PartVisibleInStep = resStr

        //         ChooseObjArrBottom[0].userData.PartVisibleInStep = resStr
        //         ChooseObjArrBottom[0].userData.PartVisibleInStepArr = LowToHeightArr

        //         console.log('选中的零件组是2111', ChooseObjArrBottom, this.PartVisibleInStep)

        //         //更新零件可见性数组
        //         this.partVisibleSetByStep()


        //         // 更新右侧动画数据

        //         this.CreateRightAnimationListData(
        //             showGroup,
        //             showGroup.userData.ThisFoorChooseStepNum
        //         );

        //     }


        // },

        //正常的显示规则是零件安装以后，后面的所有步骤中本零件都应该是显示状态（半透明也算显示状态）
        //但正常显示还不够，不足以应付各种使用场景，比如导线，导线在步骤2设为卷曲状态，在步骤10导线要安装了，这个时候我们就要隐藏卷曲导线，显示安装的导线了
        //所有可以在右侧设置零件在各种状态下的显示状体
        //所以各个零件或者组都有一个隐藏的功能，在规定的全局步骤开始隐藏
        //数据在userdata中的PartVisibleInStep属性和PartVisibleInStepArr属性中
        // partVisibleSetByStep() {
        //     //算法设计：递归找到所有的零件和组
        //     //将零件的所有子零件，当读取到零件A（id：22110）下有[12;15] 类似于这样的数据(本步骤显示，12步隐藏，13步隐藏，15步显示)后
        //     //我们就在本函数中数组Model_children_visibleInfoArr 的 index12存入{visible:false,id:22110}
        //     //index13存入{visible:false,id:22110}
        //     //index14存入{visible:false,id:22110}
        //     //index15存入{visible:true,id:22110}

        //     //这样子我们在后面的每一步都能找到零件对应的显示状态了

        //     Model_children_visibleInfoArr = []

        //     //模型中的最大步骤
        //     const Model_maxstep = model.userData.numBuildingSteps

        //     model.traverse(c => {
        //         // for (let i = 0; i < Group.children.length; i++) {
        //         //只处理零件和组
        //         if (c.userData.TYPE == 'PART' || c.userData.TYPE == 'GROUP') {
        //             //如果有显示和隐藏的设置
        //             if (c.userData.PartVisibleInStepArr && c.userData.PartVisibleInStepArr.length > 0) {

        //                 //零件的隐藏显示设置信息
        //                 const PartVisibleInStepArr = c.userData.PartVisibleInStepArr
        //                 let min = Math.min(...PartVisibleInStepArr);
        //                 let max = Model_maxstep;
        //                 let visible = true
        //                 for (let j = min; j < max + 1; j++) {

        //                     //包含则说明显示状态要切换了显示-不显示-显示-。。。。。。
        //                     if (PartVisibleInStepArr.indexOf(j) != -1) { visible = !visible }

        //                     //没有的步骤加上新的空数组
        //                     if (Model_children_visibleInfoArr[j] == null) { Model_children_visibleInfoArr[j] = [] }

        //                     Model_children_visibleInfoArr[j].push({
        //                         visible: visible,
        //                         id: c.id
        //                     })

        //                 }


        //             }
        //         }

        //     })

        //     console.log('Model_children_visibleInfoArr', Model_children_visibleInfoArr)

        //     // this.CreatePartOrGroupVisibleData(model)
        // },


        //根据零件内的userdata内的PartVisibleInStepArr数组信息在合适的步骤显示零件
        // showGlobalStep 显示的步骤
        // PartOrGroupVisibleByStepData(Group, showGlobalStep) {
        //     //本步骤中需要修改可见性的零件
        //     console.log('Model_children_visibleInfoArr222', Model_children_visibleInfoArr, showGlobalStep, 1, '1')
        //     let NeedSetVisibleInThisStep = Model_children_visibleInfoArr[showGlobalStep]
        //     //没有则可以跳过了
        //     if (NeedSetVisibleInThisStep == null) { return }

        //     for (let i = 0; i < Group.children.length; i++) {

        //         //只处理零件和组
        //         if (Group.children[i].userData.TYPE == 'PART') {

        //             // 小于本步骤的零件才需要根据PartVisibleInStepArr信息显示或者隐藏
        //             // 大于等于本步骤的零件不需要根据PartVisibleInStepArr显示或隐藏，因为零件都还没安装呢
        //             if (Group.children[i].userData.MyStep < showGlobalStep) {

        //                 //开始根据Model_children_visibleInfoArr数组内的信息来显示或者隐藏前面步骤的某些零件了


        //                 console.log('看看本步骤中有哪些需要修改可见性的零件', NeedSetVisibleInThisStep)
        //                 //看看本步骤中有哪些需要修改可见性的零件
        //                 for (let j = 0; j < NeedSetVisibleInThisStep.length; j++) {

        //                     if (NeedSetVisibleInThisStep[j].id == Group.children[i].id) {

        //                         console.log('设置该零件的可见性', j, Group.children[i], NeedSetVisibleInThisStep[j])

        //                         Group.children[i].visible = NeedSetVisibleInThisStep[j].visible

        //                     }

        //                 }

        //             }

        //         } else if (Group.children[i].userData.TYPE == 'GROUP') {

        //             this.PartOrGroupVisibleByStepData(Group.children[i], showGlobalStep)

        //         }


        //     }
        // },


        /***********************************************零件在各个步骤中的可见性设置 以上********** */


        // updateObjectsVisibility() {
        //     model.traverse((c) => {
        //         if (c.isLineSegments) {
        //             if (c.isConditionalLine) {
        //                 c.visible = guiData.conditionalLines;
        //             } else {
        //                 c.visible = guiData.displayLines;
        //             }
        //         } else if (c.isGroup) {
        //             // Hide objects with building step > gui setting
        //             c.visible = c.userData.buildingStep <= guiData.buildingStep;
        //         }
        //     });
        // },
        //为组生产假的动画数据，在加载模型的时候给零件加入假数据
        makeAnimationDataForPart(Group_) {
            for (let i in Group_.children) {
                if (
                    Group_.children[i].userData.TYPE == "PART" ||
                    Group_.children[i].userData.TYPE == "GROUP"
                ) {
                    // if (Group_.children[i].userData.ThisFloorStepNum + 1 == chooseStep) {

                    console.log("满足条件的零件12", Group_.children[i]);

                    //在这里先添加假数据
                    //后期去除后，将信息放入mpd文件中，然后在加载文件的时候解析到model文件中即可

                    // if (Group_.children[i].userData.startingBuildingStep == true) {

                    let stepAnimationInfo = {};

                    // let stepNumber = 0
                    //在本步骤中该零件的动画执行顺序
                    // stepAnimationInfo.stepNumber = 0
                    // { value: 'stepBegin', label: '步骤打开时' }, { value: 'DelayStart', label: '延迟启动' }
                    stepAnimationInfo.startOption = "stepBegin";

                    //延迟启动
                    // stepAnimationInfo.startDelay = 1000

                    //'stepBegin'、'withLastAnimation'、'afterLastAnimation'
                    //步骤开始时候 、 和上一个动画一起开始 、 在上一个动画之后开始
                    // stepAnimationInfo.startEvent = 'stepstart'
                    Group_.children[i].updateMatrixWorld()
                    //开始位置和旋转
                    const newstartposition = new THREE.Vector3()
                        .copy(Group_.children[i].position)
                        .add(new THREE.Vector3(0, -100, 0));
                    stepAnimationInfo.startPosition = {
                        x: newstartposition.x,
                        y: newstartposition.y,
                        z: newstartposition.z,
                    };
                    stepAnimationInfo.startRotation = {
                        x: Group_.children[i].quaternion.x,
                        y: Group_.children[i].quaternion.y,
                        z: Group_.children[i].quaternion.z,
                        w: Group_.children[i].quaternion.w,

                    };
                    // Group_.children[i].rotation.clone()

                    //结束位置和旋转
                    stepAnimationInfo.endPosition = {
                        x: Group_.children[i].position.x,
                        y: Group_.children[i].position.y,
                        z: Group_.children[i].position.z,
                    };
                    // Group_.children[i].position.clone()
                    stepAnimationInfo.endRotation = {
                        x: Group_.children[i].quaternion.x,
                        y: Group_.children[i].quaternion.y,
                        z: Group_.children[i].quaternion.z,
                        w: Group_.children[i].quaternion.w,

                    };
                    //  Group_.children[i].rotation.clone()
                    //工作时长2秒钟
                    stepAnimationInfo.duration = 1000;
                    //动画效果
                    stepAnimationInfo.AnimationEffects = "lowSpeedInlowSpeedOut";

                    // ChildrenAnimationStart ChildrenAnimationStart_startDelay
                    // }

                    Group_.children[i].userData.stepAnimationInfo = [];

                    Group_.children[i].userData.stepAnimationInfo.push(stepAnimationInfo);

                    //备份零件的位置和旋转
                    Group_.children[i].userData.placePosition = {
                        x: Group_.children[i].position.x,
                        y: Group_.children[i].position.y,
                        z: Group_.children[i].position.z,
                    };
                    // Group_.children[i].position.clone()
                    Group_.children[i].userData.placeRotation = {
                        x: Group_.children[i].rotation.x,
                        y: Group_.children[i].rotation.y,
                        z: Group_.children[i].rotation.z,
                    };
                    // Group_.children[i].rotation.clone()
                    Group_.children[i].userData.placeMatrix4World = Group_.children[
                        i
                    ].matrixWorld.clone();

                    //是否启动组的子零件动画
                    Group_.children[i].userData.ChildrenAnimationStart = true;
                    Group_.children[i].userData.ChildrenAnimationStart_startDelay = 1000;
                }

                //如果是组，则递归
                if (Group_.children[i].userData.TYPE == "GROUP") {
                    this.makeAnimationDataForPart(Group_.children[i]);
                }
            }

            // console.log('33AnimationFParts33', showgroup)
        },

        //备份组的安装位置 ，为步骤的父级临时旋转备份信息
        backupGroupInstallationInfo(group) {
            group.userData.InstallationMatrixWorld = group.matrixWorld.clone();

            for (let i in group.children) {
                if (group.children[i].userData.TYPE == "GROUP") {
                    this.backupGroupInstallationInfo(group.children[i]);
                }
            }
        },

        // //还原零件的安装位置和安装旋转
        // placePositionAndRotation() {

        //     //还原到安装位置后再生成图片
        //     model.traverse(c => {
        //         if (c.isGroup) {
        //             if (c.userData.TYPE && (c.userData.TYPE == 'GROUP' || c.userData.TYPE == 'PART')) {
        //                 if (c.userData.placePosition && c.userData.placeRotation) {

        //                     // console.log('placePosition5555 ', c.userData.placePosition)
        //                     // console.log('placeRotation5555 ', c.userData.placeRotation)

        //                     let placePosition = c.userData.placePosition
        //                     let placeRotation = c.userData.placeRotation

        //                     c.position.set(placePosition.x, placePosition.y, placePosition.z)

        //                     c.rotation.set(placeRotation.x, placeRotation.y, placeRotation.z)

        //                     // c.updateMatrix()

        //                 }
        //             }
        //         }

        //     })

        // },

        findModelName() {

            this.modelName = '未命名'
            model.name = this.modelName

            let MPDtext = lDrawLoader.getmpdText()

            if (MPDtext.indexOf('\r\n') !== - 1) {

                // This is faster than String.split with regex that splits on both
                MPDtext = MPDtext.replace(/\r\n/g, '\n');

            }

            const lines = MPDtext.split('\n');

            //只查找10行，10行内没名字就不找了

            for (let i = 0; i < lines.length; i++) {

                if (i == 10) {

                    break

                }

                //1 开头说明到步骤零件了
                if (lines[i].startsWith('1 ')) {

                    break

                }

                if (lines[i].startsWith('0 Name')) {

                    let namearr = lines[i].split(' ')

                    this.modelName = namearr[namearr.length - 1]

                    if (this.modelName == null) { this.modelName = '未命名' }

                    model.name = this.modelName

                    break

                }

            }

            // this.modelName = model.name ? model.name : '未命名'

        },

        /*
        * 点击模型旋转锁定后
        * 递归model，将所有的步骤内的模型旋转参数整合：按照全局步骤顺序，比如模型共有10步，1其中1，5，7三个步骤锁定了旋转参数
        * 我们就将1，2，3，4设为1的旋转，5，6设为5的旋转，7，8，9，10设为7的旋转参数
        * 如果10个步骤中只有2，4有旋转参数，则将步骤1的旋转参数设为2的旋转
        * 所以按照这个逻辑，我们先确认步骤1有设置旋转参数，如果没有，先找到离步骤1最近的那个步骤n去找参数，设为1...n的所有旋转参数为n的参数
        * 这个参数只用设在组或者零件的userdata中就可以了
        */

        //为没有设置旋转的步骤计算步骤旋转
        setModelRotateData() {
            //先找到第一步的旋转状态(第一步没有，就找离步骤1最近的旋转状体，如果全部模型都没有设置旋转状态，则不用执行后面的每个步骤标记了)
            //  model.
            let FirstSetRotatePartOrGroup = this.findFirstSetModelRotateDataPartOrGroup(model)

            console.log('FirstSetRotatePartOrGroup', FirstSetRotatePartOrGroup)

            //如果模型中完全没有设置旋转状体的步骤零件，不用设置其它零件的旋转状态了
            //既然没有设置任何模型旋转信息，则清除所有的模型旋转设置
            if (!FirstSetRotatePartOrGroup) {
                //清除模型中所有的零件或者组的旋转参数
                model.traverse(c => {
                    if (c.isGroup) {
                        if (c.userData.TYPE && (c.userData.TYPE == 'GROUP' || c.userData.TYPE == 'PART')) {

                            if (c.userData.step_Model_MatrixWorld_set) {

                                c.userData.step_Model_MatrixWorld_set = null

                            }

                        }
                    }
                })

                //设为模型内没有任何模型旋转设定的状态
                model.userData.ModelRotateSetInChildren = false

                //将模型设为默认的旋转和位置
                model.position.set(0, 0, 0)
                model.rotation.set(Math.PI, 0, 0)

                return
            }

            //模型内有模型旋转设定
            model.userData.ModelRotateSetInChildren = true

            console.log('step_Model_MatrixWorld_set.matrix', FirstSetRotatePartOrGroup.userData.step_Model_MatrixWorld_set)


            console.log('2', FirstSetRotatePartOrGroup.userData.step_Model_MatrixWorld_set.matrix)
            let new_matrix = new THREE.Matrix4()
            new_matrix.copy(FirstSetRotatePartOrGroup.userData.step_Model_MatrixWorld_set.matrix)
            console.log('new_matrix', new_matrix)
            // console.log('3',FirstSetRotatePartOrGroup.userData.step_Model_MatrixWorld_set.matrix.clone())


            //为模型中所有还没有设置旋转参数的步骤设置最近的旋转设置 FirstSetRotatePartOrGroup.userData.
            this.setRotateForNoRotatStatPartOrGroup(model, new_matrix)



        },

        //找到第一个被设置步骤旋转的零件或者组后
        //接下来我们就可以从第一步开始，不断地为后面的所有步骤零件设置搭建旋转参数
        //为没有设置旋转状态的所有组来设置旋转状态（步骤的第一个零件设置旋转状态）
        setRotateForNoRotatStatPartOrGroup(Group, firstStepRotateMatrix4) {

            let StepRotateMatrix4 = firstStepRotateMatrix4
            let stateMatrixNew = false

            for (let i = 0; i < Group.children.length; i++) {

                if (!(Group.children[i].userData.TYPE && (Group.children[i].userData.TYPE == 'PART' || Group.children[i].userData.TYPE == 'GROUP'))) { continue }

                // console.log('组或者零件',Group.children[i])
                //如果是本步骤的第一个零件，则设置旋转参数,其中有可能有的零件设置了旋转，则将旋转StepRotateMatrix4更新
                // if (Group.children[i].userData.startingBuildingStep || i == 0) {
                if (Group.children[i].userData.startingBuildingStep || i == 0) {


                    //本步骤具有旋转设置，则将本步骤的旋转设置赋值到StepRotateMatrix4变量中，后边可以用
                    if (Group.children[i].userData.step_Model_MatrixWorld_set
                        && Group.children[i].userData.step_Model_MatrixWorld_set.thisStepSetMatrixWorld == true) {

                        StepRotateMatrix4 = new THREE.Matrix4().copy(Group.children[i].userData.step_Model_MatrixWorld_set.matrix)

                        stateMatrixNew = true

                    } else {

                        // console.log('为没有设置模型旋转的零件设置模型旋转',Group.children[i])
                        //本步骤没有旋转设置，则设置为前面最近的旋转状态
                        Group.children[i].userData.step_Model_MatrixWorld_set = {

                            thisStepSetMatrixWorld: false,

                            matrix: StepRotateMatrix4.clone(),

                        }

                    }

                    //如果是组，则继续往组里面找，如果组里有最新状态，则返回回来
                    if (Group.children[i].userData.TYPE == 'GROUP') {

                        let newStepRotateMatrix4 = this.setRotateForNoRotatStatPartOrGroup(Group.children[i], StepRotateMatrix4)

                        //如果组内有新的旋转设置，则将本层的后面步骤的旋转设置设为最新的
                        // if (newStepRotateMatrix4) {

                        //     StepRotateMatrix4 = newStepRotateMatrix4.clone()

                        //     //子代的旋转影响子代父级的旋转
                        //     Group.children[i].userData.step_Model_MatrixWorld_set = {

                        //         thisStepSetMatrixWorld: false,

                        //         matrix: StepRotateMatrix4.clone(),

                        //     }

                        // }
                        if (newStepRotateMatrix4) {

                            //子代的旋转影响子代父级的旋转
                            //不是步骤的第一个零件也要存储状态,
                            //如果有设置信息就不改了，旋转矩阵也要设为本步骤的
                            if (Group.children[i].userData.step_Model_MatrixWorld_set && Group.children[i].userData.step_Model_MatrixWorld_set.thisStepSetMatrixWorld) {

                                //既然父有设置，子传上来的也就没用了
                                console.log(' Group.children[i].userData.step_Model_MatrixWorld_set.matrix333', Group.children[i].userData.step_Model_MatrixWorld_set.matrix)
                                let nmwMatrix = new THREE.Matrix4().fromArray(Group.children[i].userData.step_Model_MatrixWorld_set.matrix.elements)
                                StepRotateMatrix4 = nmwMatrix.clone()

                            } else {


                                StepRotateMatrix4 = newStepRotateMatrix4.clone()

                                Group.children[i].userData.step_Model_MatrixWorld_set = {

                                    thisStepSetMatrixWorld: false,

                                    matrix: StepRotateMatrix4.clone(),

                                }

                            }

                        }
                    }

                } else {
                    //不是步骤的第一个零件也要存储状态,如果有设置信息就不改了
                    if (!(Group.children[i].userData.step_Model_MatrixWorld_set && Group.children[i].userData.step_Model_MatrixWorld_set.thisStepSetMatrixWorld)) {

                        Group.children[i].userData.step_Model_MatrixWorld_set = {

                            thisStepSetMatrixWorld: false,

                            matrix: StepRotateMatrix4.clone(),

                        }

                    }
                }

            }

            //如果层有新的步骤旋转，则返回上级去使用，这样才能保证全局步骤中，前面的的步骤旋转设置能影响到后面（子影响父）
            if (stateMatrixNew) {

                return StepRotateMatrix4

            }



        },

        //找到第一个被设置了模型旋转参数的组
        findFirstSetModelRotateDataPartOrGroup(Group) {

            let FirstSetRotatePartOrGroup

            for (let i = 0; i < Group.children.length; i++) {

                if (!(Group.children[i].userData.TYPE && (Group.children[i].userData.TYPE == 'PART' || Group.children[i].userData.TYPE == 'GROUP'))) { continue }

                //如果不是本步骤的第一个零件，则跳过，同时将旋转状态参数设为null
                if (!(Group.children[i].userData.startingBuildingStep || i == 0)) {

                    if (Group.children[i].userData.step_Model_MatrixWorld_set) {

                        Group.children[i].userData.step_Model_MatrixWorld_set = null

                    }

                }

                //组或者零件都可以
                if (Group.children[i].userData.step_Model_MatrixWorld_set
                    && Group.children[i].userData.step_Model_MatrixWorld_set.thisStepSetMatrixWorld == true) {
                    FirstSetRotatePartOrGroup = Group.children[i]

                    break
                }

                //如果还没找到，并且又是组，则需要找组的内部
                if (FirstSetRotatePartOrGroup == null && Group.children[i].userData.TYPE == "GROUP") {

                    let res = this.findFirstSetModelRotateDataPartOrGroup(Group.children[i])

                    if (res != null) {

                        FirstSetRotatePartOrGroup = res

                        break

                    }

                }

            }

            return FirstSetRotatePartOrGroup

        },

        /**提示用户缺失的材质编号 */
        tipMissingParts() {

            let MissingMatrialArr = lDrawLoader.getMissingColor()

            //给用户提示缺失材质
            if (MissingMatrialArr > 0) {
                let ShowText = ''
                for (let o = 0; o < MissingMatrialArr.length; o++) {
                    o == MissingMatrialArr.length - 1 ? ShowText += MissingMatrialArr[o] : ShowText += MissingMatrialArr[o] + ','
                }

                this.$alert('编号:' + ShowText + "。将用亮粉色代替缺失颜色,您可以联系我们为材质库添加并支持您的材质", '缺失材质', {
                    confirmButtonText: '确定',
                    showCancelButton: false,
                    // callback: action => {
                    //   this.$message({
                    //     type: 'info',
                    //     // message: `action: ${action}`
                    //     message: '期待您联系我们为您添加新的材质'+action

                    //   });
                    // }
                }).then(res => {
                    console.log('结果22222', res)
                }).catch(err => {
                    console.log('错误10000', err)
                });
            }

        },



        onProgress(xhr) {
            if (xhr.lengthComputable) {
                // this.updateProgressBar(xhr.loaded / xhr.total);

                console.log(Math.round((xhr.loaded / xhr.total) * 100, 2) + "% downloaded");
            }
        },

        onError(error) {
            // const message = "Error loading model";
            // progressBarDiv.innerText = message;
            // console.log(message);
            // this.loading = false
            loading.close()
            loading = null

            this.$message.error('解析错误,请确认文件格式正确!如需帮助请联系我们');

            this.$alert('请确认格式正确,如需帮助请联系我们', '解析错误', {
                confirmButtonText: '确定',
                callback: action => {
                    this.$message({
                        type: 'info',
                        message: `action: ${action}`
                    });
                }

            });
            console.error(error);
        },



        showProgressBar() {
            console.log(222)
            // document.body.appendChild(progressBarDiv);
        },

        hideProgressBar() {
            console.log(222222)

            // document.body.removeChild(progressBarDiv);
        },

        updateProgressBar(fraction) {

            console.log(fraction)

            // progressBarDiv.innerText = "Loading... " + Math.round(fraction * 100, 2) + "%";
        },


        //判断key是不是用户自己的？
        // this.$root.$USERINFO.userFolder
        isKeyOfUser(key, userFolder) {
            // UsersFolder / 886f5319281740f88009117c2ddf9b2b

            // if (userFolder == null) return false

            const KeyArr = key.split('/')

            if (KeyArr[1] == userFolder) {

                return true

            } else {

                return false

            }

        },



        async handleParentMessage(e) {

            console.log('eqqqwert', e)

            // THREE.Cache.clear()

            if (!WebGL_available) {

                console.error('不支持webgl')

                this.$message({
                    message: '设备老旧，不支持3d图查看，请更换较新设备查看',
                    type: 'warning'
                });

                return

            }

            // const CreatorID = e.CreatorID || ''

            const schoolName = e.schoolName || ''

            const shareTeacher = e.shareTeacher || ''

            this.FileInfoShow = '            ' + schoolName + '            ' + shareTeacher





            // this.partsListImagesDataOBJ.PartsArr.length = 0
            // this.partsListImagesDataOBJ.GroupsArr.length = 0

            this.partsListImagesData_PartsArr.length = 0
            this.partsListImagesData_GroupsArr.length = 0

            this.modelStepTotal = null

            //屏幕宽高
            this.viewWidth = window.innerWidth
            this.viewHeight = window.innerHeight


            //清除模型占用内存
            //其实在关闭界面的时候就已经清除了
            if (model) {
                this.removeModel(model)


                //其实这个操作费内存，不过我老遇到重新加载多次后画面变成了个破图片，先这样，去掉后理论上省内存些，ipad多次加载不容易崩溃
                // this.init()
            }

            EnableRender = false

            //清除渲染器
            // if (renderer) {
            //     renderer.dispose()
            //     //     renderer = null
            // }



            // container = document.querySelector("#three2");

            // container.appendChild(renderer.domElement);

            // renderer = new THREE.WebGLRenderer({ antialias: true });





            const that = this

            console.log('handleParentMessage_e', e)
            // this.getvideoPlayerView(e.Key)
            // await this.getMediaUrl(e.Key)



            loading = this.$loading({
                lock: true,
                text: '文件加载处理中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            this.mpdUrl = e.Key




            //销毁计时器用
            this.timer = setTimeout(() => {

                if (loading != null) {

                    // if(LoadingNum == nowLoadNum){}


                    loading.close()

                    that.$message({
                        message: '超时！还在处理中,网络环境差或者资源过大',
                        type: 'warning'
                    });
                }


            }, 5000);

            //检测看看文件的key是不是用户自己的，如果是自己的就正常获取用户文件，如果是别人分享的就使用分享者提供的临时密钥来获取文件

            // getObject_fromSharer
            var res
            if (this.$root.$USERINFO && this.$root.$USERINFO.userFolder && this.isKeyOfUser(e.Key, this.$root.$USERINFO.userFolder)) {
                //是该用户的文件夹

                res = await this.$root.$MyCloudFiles.getObject(e.Key, (onprogress) => {

                    console.log('文件下载进度888221', onprogress)
                })

            } else {

                console.log('e333333', e)

                //不是该用户的文件夹，则需要传入临时密钥
                const TemporaryKey = e.temporary_key

                const BucketInfo = {
                    Bucket: TemporaryKey.bucket,
                    Region: TemporaryKey.region
                }


                // res = await this.$root.$MyCloudFiles.getObject_fromSharer(e.Key, (onprogress) => {

                //     console.log('文件下载进度666611', onprogress)

                // }, TemporaryKey, BucketInfo)

                res = await this.getObject_fromSharer(e.Key, (onprogress) => {

                    console.log('文件下载进度666611', onprogress)

                }, TemporaryKey, BucketInfo)

                // getObject_fromSharer(

            }



            console.log('3d模型编辑器内下载下来的文件是', res)

            if (!res) {

                this.$message.error('未加载到模型，请重试');

                return

            }

            // cameraAnimationTweenArr = []

            // thisStepPartAnimation = []

            // modelRotateTweenArr = []

            // window.addEventListener('keyup', this.handleKeyPress);
            // window.addEventListener('resize', this.windowResize)


            // this.reloadObject(true, null, res.Body);

            if (init == false) {
                console.log('初始化000')
                await this.init();
                init = true
                this.animate()
            }

            console.log('renderer444', renderer)

            // renderer.setPixelRatio(window.devicePixelRatio);
            // renderer.setSize(width, height);
            // renderer.useLegacyLights = false;
            // renderer.toneMapping = THREE.ACESFilmicToneMapping;

            // container = document.querySelector("#three2");
            container = document.querySelector("#three2");



            // fileInfoShow = document.getElementById('fileInfoShow');
            // // fileInfoShow = document.querySelector("#fileInfoShow");
            // console.log('fileInfoShow2222', fileInfoShow)


            console.log('container2222', container)

            console.log('renderer.domElement2222', renderer.domElement)

            container.appendChild(renderer.domElement);

            //设置id，方便查找
            renderer.domElement.id = "ThreeCanvas";

            console.log('正式处理文件')

            // saveText = res.Body

            await this.reloadObject(true, null, res.Body);

            console.log('stateDIV22', document.getElementById("statsView"))





            if (!stats.domElement) {

                //抢强制多添加监控器，因为有时候重新打开模型就没有了监控器
                stats = new Stats();
                // stats = new Stats();
                stats.domElement.style.position = "absolute"; //绝对坐标
                stats.domElement.style.left = "0px"; // (0,0)px,左上角
                stats.domElement.style.top = "200px";
                stats.domElement.style.zIndex = "1000"

                stats.domElement.id = "statsView"

                container.appendChild(stats.domElement);
            }

            console.log('renderer', renderer)

            console.log('scene', scene)



            // console.log(init)


            EnableRender = true

            // this.animate();

            // console.log('this.mpdUrl444', this.mpdUrl)




            // this.init();
            // this.animate();

        },



        //传入mpdtxt文件后打开
        async handleParentMessage_temp_view_input_text(e) {

            console.log('e)2222', e)
            e.Key = 'aaaaaaaa'
            let that = this
            this.mpdUrl = e.Key

            // saveText = e.mpdData

            //给100毫秒页面才能响应，才能创建#three的哪个标签元素
            setTimeout(() => {
                that.windowResize()
                that.handleParentMessage_temp_view_input_text_do(e)
            }, 100);

        },


        // getObjByTempKey(SecretKey) {

        //     const cos = new COS({
        //         SecretId: 'your_tmpSecretId', // sts服务下发的临时 secretId
        //         SecretKey: 'your_tmpSecretKey', // sts服务下发的临时 secretKey
        //         SecurityToken: 'your_sessionToken', // sts服务下发的临时 SessionToken
        //         StartTime: 1720770679403, // 建议传入服务端时间，可避免客户端时间不准导致的签名错误
        //         ExpiredTime: 1720771991367, // 临时密钥过期时间
        //     });


        // },
        //不考虑缓存中的文件,获取分享者的文件,需要传入临时密钥相关的数据
        async getObject_fromSharer(Key, onProgressCallback, TemporaryKey, BucketInfo) {
            // var that = this

            // await this.updateNewTempSecretKey()

            var CosShare = new COS({
                SecretId: TemporaryKey.SecretId,
                SecretKey: TemporaryKey.SecretKey,
                SecurityToken: TemporaryKey.SecurityToken,
            });

            // BucketInfo


            return new Promise((resolve, reject) => {
                CosShare.getObject({
                    Bucket: BucketInfo.Bucket,
                    Region: BucketInfo.Region,
                    Key,
                    ResponseCacheControl: 'no-cache',

                    onProgress: function (progressData) {
                        console.log('下载进度332223333', JSON.stringify(progressData));
                        onProgressCallback(Math.floor(progressData.loaded / progressData.total * 100))
                    }

                }, function (err, data) {
                    {
                        console.log(reject, err)
                        console.log('下载对象', data)
                        resolve(data);
                    }
                });
            });

        },

        checkWebGLSupport() {
            console.log('WebGL3333', WebGL)
            if (WebGL.isWebGL2Available()) {
                console.log('WebGL 2 is available!');

                WebGL_available = true
                // 在这里初始化 Three.js 场景、相机、渲染器等
                // 例如：
                // const scene = new THREE.Scene();
                // const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
                // const renderer = new THREE.WebGLRenderer();
                // renderer.setSize(window.innerWidth, window.innerHeight);
                // document.body.appendChild(renderer.domElement);

                // 定义动画函数
                // function animate() {
                //   requestAnimationFrame(animate);
                //   // 在这里更新场景和渲染
                //   // renderer.render(scene, camera);
                // }
                // 开始动画循环
                // animate();
            } else {
                const warning = WebGL.getWebGL2ErrorMessage();
                console.error('WebGL 2 不支222持!', warning);
                WebGL_available = false
                // this.$alert('设备老旧，不支持3D搭建图，但支持在线文档', '不兼容', {
                //   confirmButtonText: '确定',
                //   callback: action => {
                //     this.$message({
                //       type: 'info',
                //       message: `action: ${action}`
                //     });
                //   }
                // })
                // const container = document.getElementById('container');
                // container.appendChild(warning);
            }
        },

        async handleParentMessage_temp_view_input_text_do(e) {

            console.log('222222e3333', e)

            if (!WebGL_available) {

                console.error('不支持webgl')

                return

            }
            //else{

            // }



            // this.mpdUrl = true

            // console.log('this.mpdUrl = true',this.mpdUrl)
            // mpdData
            // this.partsListImagesDataOBJ.PartsArr.length = 0
            // this.partsListImagesDataOBJ.GroupsArr.length = 0

            this.partsListImagesData_PartsArr.length = 0
            this.partsListImagesData_GroupsArr.length = 0

            this.modelStepTotal = null

            //屏幕宽高
            this.viewWidth = window.innerWidth
            this.viewHeight = window.innerHeight


            //清除模型占用内存
            //其实在关闭界面的时候就已经清除了
            if (model) {
                model.removeFromParent()
                this.removeModel(model)
            }



            EnableRender = false

            //清除渲染器
            // if (renderer) {
            //     renderer.dispose()
            //     //     renderer = null
            // }



            // container = document.querySelector("#three2");

            // container.appendChild(renderer.domElement);

            // renderer = new THREE.WebGLRenderer({ antialias: true });



            // const that = this

            console.log('handleParentMessage_e', e)
            // this.getvideoPlayerView(e.Key)
            // await this.getMediaUrl(e.Key)



            loading = this.$loading({
                lock: true,
                text: '文件加载处理中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });

            await this.delay(100);

            // this.mpdUrl = e.Key

            //销毁计时器用
            // this.timer = setTimeout(() => {

            //     if (loading != null) {

            //         // if(LoadingNum == nowLoadNum){}


            //         loading.close()

            //         that.$message({
            //             message: '超时！还在处理中,网络环境差或者资源过大',
            //             type: 'warning'
            //         });
            //     }


            // }, 5000);

            // let res = await this.$root.$MyCloudFiles.getObject(e.Key, (onprogress) => {

            //     console.log('文件下载进度888', onprogress)
            // })

            console.log('3d模型编辑器内下载下来的文件是', e.mpdData)

            if (!e.mpdData) {

                this.$message.error('未加载到模型，请重试');

                return

            }

            // cameraAnimationTweenArr = []

            // thisStepPartAnimation = []

            // modelRotateTweenArr = []

            // window.addEventListener('keyup', this.handleKeyPress);
            // window.addEventListener('resize', this.windowResize)


            // this.reloadObject(true, null, res.Body);

            console.log('this.mpdUrl333', this.mpdUrl)
            console.log('initeeeeeeee', init)
            if (init == false) {
                console.log('初始化00033')
                await this.init();
                init = true
                this.animate()
            }

            console.log('renderer444', renderer)




            // renderer.setPixelRatio(window.devicePixelRatio);
            // renderer.setSize(width, height);
            // renderer.useLegacyLights = false;
            // renderer.toneMapping = THREE.ACESFilmicToneMapping;

            // container = document.querySelector("#three2");
            container = document.querySelector("#three2");

            console.log('containerwrtt', container)

            container.appendChild(renderer.domElement);

            //设置id，方便查找
            renderer.domElement.id = "ThreeCanvas";

            console.log('正式处理文件')

            await this.reloadObject(true, null, e.mpdData);



            console.log('stateDIV22', document.getElementById("statsView"))





            if (!stats.domElement) {

                //抢强制多添加监控器，因为有时候重新打开模型就没有了监控器
                stats = new Stats();
                // stats = new Stats();
                stats.domElement.style.position = "absolute"; //绝对坐标
                stats.domElement.style.left = "0px"; // (0,0)px,左上角
                stats.domElement.style.top = "200px";
                stats.domElement.style.zIndex = "1000"

                stats.domElement.id = "statsView"

                container.appendChild(stats.domElement);
            }

            console.log('renderer', renderer)

            console.log('scene', scene)



            // console.log(init)


            EnableRender = true

            // this.animate();

            // console.log('this.mpdUrl444', this.mpdUrl)




            // this.init();
            // this.animate();

        },

        //移除所有模型数据，避免多次加载模型内存溢出

        removeModel(model) {

            // modelRotateTweenArr = []
            let meshArr = []
            if (model) {

                model.traverse(c => {
                    if (c.isMesh) {
                        meshArr.push(c)
                    }
                    if (c.isLine) {
                        meshArr.push(c)
                    }
                })

            }

            for (let i = 0; i < meshArr.length; i++) {
                this.removeModelAndReleaseMemory(meshArr[i])
            }

            model = null

        },


        removeModelAndReleaseMemory(model) {
            // 1. 首先，从场景中移除模型
            if (model.parent !== null) {
                model.parent.remove(model);
            }

            // 2. 清理模型自身的属性，尤其是Geometry和Material
            if (model.geometry) {
                model.geometry.dispose(); // 清理几何体资源
                model.geometry = null;
            }

            if (model.material) {
                // 如果是单一材质
                if (model.material.dispose) {
                    model.material.dispose(); // 清理材质资源
                    model.material = null;
                }
                // 如果是材质数组（如MeshFaceMaterial）
                else if (Array.isArray(model.material)) {
                    model.material.forEach(material => {
                        if (material.dispose) {
                            material.dispose();
                        }
                    });
                    model.material.length = 0;
                    model.material = null;
                }
            }

            // 3. 对于使用了纹理的模型，还需要释放纹理资源
            if (model.material && model.material.map) {
                model.material.map.dispose(); // 清理纹理资源
                model.material.map = null;
            }

            // 如果模型还有其他特殊资源，也需要相应地清理它们
            // ...

            // 最后，设置模型引用为null，帮助垃圾回收机制工作
            model = null;
        },

        // // 使用示例：
        // let yourModel = ...; // 假设这是你要移除的模型
        // removeModelAndReleaseMemory(yourModel);

        //根据key生成临时下载链接
        // async getMediaUrl(key) {

        //     const that = this

        //     let res = await this.$root.$MyCloudFiles.getMyCloudMideaUrl(key)

        //     if (res == 'err') {

        //         this.$message.error('资源连接错误');

        //         return
        //     }

        //     loading = this.$loading({
        //         lock: true,
        //         text: '文件加载处理中',
        //         spinner: 'el-icon-loading',
        //         background: 'rgba(0, 0, 0, 0.7)'
        //     });

        //     //销毁计时器用
        //     this.timer = setTimeout(() => {

        //         if (loading != null) {

        //             // if(LoadingNum == nowLoadNum){}


        //             loading.close()

        //             that.$message({
        //                 message: '超时！还在处理中,网络环境差或者资源过大',
        //                 type: 'warning'
        //             });
        //         }


        //     }, 5000);
        //     console.log('多媒体的预览地址是', res)
        //     this.mpdUrl = res



        //     //             let Bucket = 'dadastar-1307054034'
        //     // let Region = 'ap-shanghai'


        // },
        disposeScene() {
            // 确保场景中所有对象都被清理
            if (scene && scene.children.length) {
                scene.traverse(function (child) {
                    if (child.geometry) {
                        child.geometry.dispose();
                    }
                    if (child.material) {
                        // 如果材质是材质数组，遍历每个材质并释放
                        if (Array.isArray(child.material)) {
                            for (let i = 0; i < child.material.length; i++) {
                                child.material[i].dispose();
                            }
                        } else {
                            child.material.dispose();
                        }
                    }
                    // 如果是Mesh、Line或者其他可清除的对象，也需要调用dispose
                    // if (child.isMesh || child.isLine) {
                    //     child.dispose(); // 注意：THREE.Mesh 和 THREE.Line 默认没有 dispose 方法，这里假设你扩展了这些类并添加了 dispose 方法来处理特定资源释放
                    // }
                });
            }

            // 清理相机和渲染器
            if (camera) {
                // 相机一般没有需要特别释放的资源
            }

            // if (renderer) {
            //     renderer.dispose(); // 渲染器的dispose方法会清理相关的WebGL资源
            //     renderer.forceContextLoss(); // 在某些情况下，强制丢失WebGL上下文可以确保资源被完全释放
            //     renderer.context = null;
            // }

            // 设置为null帮助垃圾回收
            scene = null;
            camera = null;
            // renderer = null;
        },

        //关闭播放器
        closeMpdPlayer() {

            console.log('关闭播放器222333')

            // this.disposeScene()
            // let that = this
            // if (model) {
            //     model.removeFromParent()
            // }
            if (model) {
                this.removeModel(model)
            }

            // if (Rendering_enabled == true) {

            renderer.render(scene, camera)

            // }


            if (lDrawLoader) {
                lDrawLoader.disposeData()
            }

            if (DoStepAnimationPlayer) {
                DoStepAnimationPlayer.disposeData()
            }
            model = null

            //清空模型
            // setTimeout(() => {
            //     that.clearObject(model)
            //     model = null
            // }, 100);
            // this.clearObject(model)





            this.mpdUrl = null

            EnableRender = false

            //清除渲染器
            // if (renderer) {

            // lDrawLoader.clearOBJ()

            // renderer.dispose()

            // renderer.forceContextLoss()

            // renderer.clear()
            // renderer = 
            // }

            // renderer = new THREE.WebGLRenderer({ antialias: true });



            clearTimeout(this.timer);


            if (loading) {
                // clearTimeout(this.timerId);


                loading.close()
                loading = null
            }


            // window.removeEventListener('keyup', this.handleKeyPress)

            // window.removeEventListener("resize", this.windowResize);

            // OrbitControl.dispose()


            //清空所有变量
            // container = null
            // progressBarDiv = null


            // camera = null
            // scene = null
            // renderer = null
            // OrbitControl = null

            // mpdPartListCreater = null
            // DoStepAnimationPlayer = null

            // // let gui

            // model = null

            // DragControl = null
            // lDrawLoader = null
            // Transformcontrol = null
            // stats = null
            // translatePart = null
            // // let showGroup
            // MyBoxHelperedgesMaterial = null

            // // MyBoxHelperedgesMaterial.userData.type = null

            // //半透明材质
            // material_translucent = null
            // // material_translucent.userData.myType = null



            // //不透明灰色
            // material_opaque = null

            // // material_opaque.userData.myType = null

            // Model_children_visibleInfoArr = null


            // ldrawPath = null

            // //步骤变量
            // stepNumber = null
            // //可见性组
            // showGroup = null

            // //所有的零件
            // partArr = null
            // //所有的组
            // groupArr = null

            // partAndGroupArr = null



            // let loading

            // // let cameraAnimationTweenArr = []

            // // let thisStepPartAnimation = []

            // // let modelRotateTweenArr = []

            // EnableRender = null
            // UserLastOperateTimeStamp = null

            //不查看了，退出了
            ViewModel = false




            // //可见性组
            // showGroup

            // //所有的零件
            // partArr = []
            // //所有的组
            // groupArr = []

            // partAndGroupArr = []

            this.mpdUrl = null
            this.timer = null
            //零件列表数据
            this.partsListImagesData_PartsArr.length = 0
            this.partsListImagesData_GroupsArr.length = 0
            this.stepPath = null
            this.maxStepPath = null







            //步骤变量
            stepNumber = null
            //可见性组
            showGroup.length = 0

            //所有的零件
            partArr.length = 0
            //所有的组
            groupArr.length = 0

            partAndGroupArr.length = 0

            console.log('清空所有变量')

            //通知父级关闭了
            this.$emit('view_closed');


        },

        // clearObject(obj) {
        //     for (let key in obj) {
        //         if (typeof obj[key] === 'object' && obj[key] !== null && !(obj[key] instanceof Array)) {
        //             // 如果属性是对象，则递归调用清空函数
        //             this.clearObject(obj[key]);
        //         } else {
        //             // 否则，直接删除属性
        //             delete obj[key];
        //         }
        //     }
        // },

        handleKeyPress(event) {


            //界面关闭隐藏时事件不起作用
            if (!this.mpdUrl) { return }

            this.updataRenderer()
            console.log('按下按键', event)

            const keyCode = event.keyCode;
            console.log('event.keyCode', keyCode)
            switch (keyCode) {
                case 32:
                    console.log('你按下了空格键')

                    if (this.stepShow == this.modelStepTotal) {

                        this.$message({
                            message: '恭喜搭建完成',
                            type: 'success'
                        });

                        return

                    }
                    this.stepShow++
                    this.stepShow_input = this.stepShow

                    this.setStep(this.stepShow)

                    // this.keyInfo = '你按下了空格键';
                    break;
                case 37:
                    console.log('你按下了左箭头键')

                    if (this.stepShow == 1) {

                        this.$message({
                            message: '第一步',
                            type: 'warning'
                        });

                        return

                    }
                    this.stepShow--

                    this.stepShow_input = this.stepShow

                    this.setStep(this.stepShow)


                    // this.keyInfo = '你按下了左箭头键';
                    break;

                case 38:
                    console.log('你按下了左箭头键')

                    if (this.stepShow == 1) {

                        this.$message({
                            message: '第一步',
                            type: 'warning'
                        });

                        return

                    }
                    this.stepShow--
                    this.stepShow_input = this.stepShow

                    this.setStep(this.stepShow)


                    // this.keyInfo = '你按下了左箭头键';
                    break;
                case 39:
                    console.log('你按下了右箭头键')

                    if (this.stepShow == this.modelStepTotal) {

                        this.$message({
                            message: '恭喜搭建完成',
                            type: 'success'
                        });

                        return

                    }
                    this.stepShow++
                    this.stepShow_input = this.stepShow

                    this.setStep(this.stepShow)


                    // this.keyInfo = '你按下了右箭头键';
                    break;

                case 40:
                    console.log('你按下了右箭头键')

                    if (this.stepShow == this.modelStepTotal) {

                        this.$message({
                            message: '恭喜搭建完成',
                            type: 'success'
                        });

                        return

                    }
                    this.stepShow++

                    this.stepShow_input = this.stepShow

                    this.setStep(this.stepShow)


                    // this.keyInfo = '你按下了右箭头键';
                    break;
                default:
                    console.log('你按下了其他键')

                // this.keyInfo = '你按下了其他键';
            }



        }



        // handleKeyDown(event) {
        //     const keyCode = event.keyCode;
        //     switch (keyCode) {
        //         case 32:
        //             this.keyInfo = '你按下了空格键';
        //             break;
        //         case 37:
        //             this.keyInfo = '你按下了左箭头键';
        //             break;
        //         case 39:
        //             this.keyInfo = '你按下了右箭头键';
        //             break;
        //         default:
        //             this.keyInfo = '你按下了其他键';
        //     }
        // }
    }







}


</script>

<style scoped>
#three2 {
    width: 100%;
    height: 100%;
}

/* .custom-icon:hover {
    
  } */

.buttonBoxsellect {
    background-color: #409eff;
    color: #ffffff;

}

.buttonBox:hover {
    /* background-color: #409eff; */
    color: #409eff;

}

/* .rounded-border {
    width: 50px;
    height: 100px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 60px;
    left: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;

} */

.input-hide-arrows::-webkit-inner-spin-button,
.input-hide-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-hide-arrows {
    -moz-appearance: textfield;
    width: 20px;
    height: 20px;
    text-align: center;
}

/* 横 */
.horizontal {
    max-width: 100%;
    max-height: 150px;
    height: auto;
    width: auto;
    margin-top: 5px;
    border-radius: 0px;
    border: #4095f7 solid 2px;
    background-color: #ecf0f1;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    /* margin-left: 5px; */
}

/* 竖 */
.vertical {
    max-width: 310px;
    min-width: 150px;
    max-height: calc(100vh - 200px);
    height: auto;
    width: max-content;
    margin-top: 5px;
    /* border-radius: 10px; */
    border: #4095f7 solid 2px;
    background-color: #ecf0f1;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 5px;
}

.mask-layer {
    z-index: 99;
    /* 允许鼠标穿透遮罩层 */
    pointer-events: none;
    /* 整体旋转30度 */
    /* transform: rotate(-40deg); */
    transform: translate(-50%, -50%) rotate(-40deg);
}

.planet {

    margin: 50px;

    height: 20px;

    /* 设置透明度 */
    opacity: 0.25;

    color: #ecf0f1;
}



/* 特殊处理第一个和最后一个元素 */
.planet:first-child {
    margin-top: 0;
}

.planet:last-child {
    margin-bottom: 0;
}
</style>