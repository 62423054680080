// import { DoubleSide } from 'three';
// import { startsWith } from 'core-js/core/string';
// import { ConeGeometry } from 'three';
// import { forEach } from 'core-js/core/array';
// import { forEach } from 'core-js/core/array';
import {
	DoubleSide,
	BufferAttribute,
	BufferGeometry,
	Color,
	FileLoader,
	Group,
	LineBasicMaterial,
	LineSegments,
	Line,
	Loader,
	Matrix4,
	BoxGeometry,
	Mesh,
	MeshStandardMaterial,
	// MeshBasicMaterial,
	// MeshToonMaterial,
	MeshBasicMaterial,
	ShaderMaterial,
	SRGBColorSpace,
	UniformsLib,
	UniformsUtils,
	Vector3,
	Ray,
	// Euler
} from 'three';

const missingPart_geometry = new BoxGeometry(40, 40, 40);
const missingPart_material = new MeshBasicMaterial({ color: 0xff0000 });
missingPart_material.userData.colorCode = '4';
missingPart_material.userData.backupColorCode = '4';
//         cube.userData.colorCode = ['4'];

//         cube.userData.backupColorCode = ['4'];
const missingPart_box = new Mesh(missingPart_geometry, missingPart_material);
missingPart_box.userData.colorCode = '4';
missingPart_box.userData.backupColorCode = '4';
const missingPart_Group = new Group().add(missingPart_box)
missingPart_Group.userData.colorCode = '4';
missingPart_Group.userData.backupColorCode = '4';

// //缺失零件样式
// const missingPart_geometry = new BoxGeometry(100, 100, 100);
// const material = new MeshBasicMaterial({ color: 0x00ff00 });
// const missingPart = new Mesh(missingPart_geometry, material);

//警用警告
/* eslint-disable */

// Special surface finish tag types.
// Note: "MATERIAL" tag (e.g. GLITTER, SPECKLE) is not implemented
const FINISH_TYPE_DEFAULT = 0;
const FINISH_TYPE_CHROME = 1;
const FINISH_TYPE_PEARLESCENT = 2;
const FINISH_TYPE_RUBBER = 3;
const FINISH_TYPE_MATTE_METALLIC = 4;
const FINISH_TYPE_METAL = 5;

// State machine to search a subobject path.
// The LDraw standard establishes these various possible subfolders.
const FILE_LOCATION_TRY_PARTS = 0;
const FILE_LOCATION_TRY_P = 1;
const FILE_LOCATION_TRY_MODELS = 2;
const FILE_LOCATION_AS_IS = 3;
const FILE_LOCATION_TRY_RELATIVE = 4;
const FILE_LOCATION_TRY_ABSOLUTE = 5;
const FILE_LOCATION_NOT_FOUND = 6;

const MAIN_COLOUR_CODE = '16';
const MAIN_EDGE_COLOUR_CODE = '24';

const COLOR_SPACE_LDRAW = SRGBColorSpace;

const _tempVec0 = new Vector3();
const _tempVec1 = new Vector3();

var mpdText

//缺失的零件编号
var missingColor = []

// var modelName = null

var txt_partArr

//所有零件（零件库单零件）
var THREE_D_Parts_Arr = []

var material_flotArr = []

//缺失的零件
var missingPartsArr = []




// const Material_TEXT = ''

class LDrawConditionalLineMaterial extends ShaderMaterial {

	constructor(parameters) {

		// mpdText = ''

		super({

			uniforms: UniformsUtils.merge([
				UniformsLib.fog,
				{
					diffuse: {
						value: new Color()
					},
					opacity: {
						value: 1.0
					}
				}
			]),

			vertexShader: /* glsl */`
				attribute vec3 control0;
				attribute vec3 control1;
				attribute vec3 direction;
				varying float discardFlag;

				#include <common>
				#include <color_pars_vertex>
				#include <fog_pars_vertex>
				#include <logdepthbuf_pars_vertex>
				#include <clipping_planes_pars_vertex>
				void main() {
					#include <color_vertex>

					vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
					gl_Position = projectionMatrix * mvPosition;

					// Transform the line segment ends and control points into camera clip space
					vec4 c0 = projectionMatrix * modelViewMatrix * vec4( control0, 1.0 );
					vec4 c1 = projectionMatrix * modelViewMatrix * vec4( control1, 1.0 );
					vec4 p0 = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
					vec4 p1 = projectionMatrix * modelViewMatrix * vec4( position + direction, 1.0 );

					c0.xy /= c0.w;
					c1.xy /= c1.w;
					p0.xy /= p0.w;
					p1.xy /= p1.w;

					// Get the direction of the segment and an orthogonal vector
					vec2 dir = p1.xy - p0.xy;
					vec2 norm = vec2( -dir.y, dir.x );

					// Get control point directions from the line
					vec2 c0dir = c0.xy - p1.xy;
					vec2 c1dir = c1.xy - p1.xy;

					// If the vectors to the controls points are pointed in different directions away
					// from the line segment then the line should not be drawn.
					float d0 = dot( normalize( norm ), normalize( c0dir ) );
					float d1 = dot( normalize( norm ), normalize( c1dir ) );
					discardFlag = float( sign( d0 ) != sign( d1 ) );

					#include <logdepthbuf_vertex>
					#include <clipping_planes_vertex>
					#include <fog_vertex>
				}
			`,

			fragmentShader: /* glsl */`
			uniform vec3 diffuse;
			uniform float opacity;
			varying float discardFlag;

			#include <common>
			#include <color_pars_fragment>
			#include <fog_pars_fragment>
			#include <logdepthbuf_pars_fragment>
			#include <clipping_planes_pars_fragment>
			void main() {

				if ( discardFlag > 0.5 ) discard;

				#include <clipping_planes_fragment>
				vec3 outgoingLight = vec3( 0.0 );
				vec4 diffuseColor = vec4( diffuse, opacity );
				#include <logdepthbuf_fragment>
				#include <color_fragment>
				outgoingLight = diffuseColor.rgb; // simple shader
				gl_FragColor = vec4( outgoingLight, diffuseColor.a );
				#include <tonemapping_fragment>
				#include <colorspace_fragment>
				#include <fog_fragment>
				#include <premultiplied_alpha_fragment>
			}
			`,

		});

		Object.defineProperties(this, {

			opacity: {
				get: function () {

					return this.uniforms.opacity.value;

				},

				set: function (value) {

					this.uniforms.opacity.value = value;

				}
			},

			color: {
				get: function () {

					return this.uniforms.diffuse.value;

				}
			}

		});

		this.setValues(parameters);
		this.isLDrawConditionalLineMaterial = true;

	}

}

class ConditionalLineSegments extends LineSegments {

	constructor(geometry, material) {

		super(geometry, material);
		this.isConditionalLine = true;

	}

}

function generateFaceNormals(faces) {

	for (let i = 0, l = faces.length; i < l; i++) {

		const face = faces[i];
		const vertices = face.vertices;
		const v0 = vertices[0];
		const v1 = vertices[1];
		const v2 = vertices[2];

		_tempVec0.subVectors(v1, v0);
		_tempVec1.subVectors(v2, v1);
		face.faceNormal = new Vector3()
			.crossVectors(_tempVec0, _tempVec1)
			.normalize();

	}

}

const _ray = new Ray();
function smoothNormals(faces, lineSegments, checkSubSegments = false) {

	// NOTE: 1e2 is pretty coarse but was chosen to quantize the resulting value because
	// it allows edges to be smoothed as expected (see minifig arms).
	// --
	// And the vector values are initialize multiplied by 1 + 1e-10 to account for floating
	// point errors on vertices along quantization boundaries. Ie after matrix multiplication
	// vertices that should be merged might be set to "1.7" and "1.6999..." meaning they won't
	// get merged. This added epsilon attempts to push these error values to the same quantized
	// value for the sake of hashing. See "AT-ST mini" dishes. See mrdoob/three#23169.

	const hashMultiplier = (1 + 1e-10) * 1e2;
	function hashVertex(v) {

		const x = ~ ~(v.x * hashMultiplier);
		const y = ~ ~(v.y * hashMultiplier);
		const z = ~ ~(v.z * hashMultiplier);

		return `${x},${y},${z}`;

	}

	function hashEdge(v0, v1) {

		return `${hashVertex(v0)}_${hashVertex(v1)}`;

	}

	// converts the two vertices to a ray with a normalized direction and origin of 0, 0, 0 projected
	// onto the original line.
	function toNormalizedRay(v0, v1, targetRay) {

		targetRay.direction.subVectors(v1, v0).normalize();

		const scalar = v0.dot(targetRay.direction);
		targetRay.origin.copy(v0).addScaledVector(targetRay.direction, - scalar);

		return targetRay;

	}

	function hashRay(ray) {

		return hashEdge(ray.origin, ray.direction);

	}

	const hardEdges = new Set();
	const hardEdgeRays = new Map();
	const halfEdgeList = {};
	const normals = [];

	// Save the list of hard edges by hash
	for (let i = 0, l = lineSegments.length; i < l; i++) {

		const ls = lineSegments[i];
		const vertices = ls.vertices;
		const v0 = vertices[0];
		const v1 = vertices[1];
		hardEdges.add(hashEdge(v0, v1));
		hardEdges.add(hashEdge(v1, v0));

		// only generate the hard edge ray map if we're checking subsegments because it's more expensive to check
		// and requires more memory.
		if (checkSubSegments) {

			// add both ray directions to the map
			const ray = toNormalizedRay(v0, v1, new Ray());
			const rh1 = hashRay(ray);
			if (!hardEdgeRays.has(rh1)) {

				toNormalizedRay(v1, v0, ray);
				const rh2 = hashRay(ray);

				const info = {
					ray,
					distances: [],
				};

				hardEdgeRays.set(rh1, info);
				hardEdgeRays.set(rh2, info);

			}

			// store both segments ends in min, max order in the distances array to check if a face edge is a
			// subsegment later.
			const info = hardEdgeRays.get(rh1);
			let d0 = info.ray.direction.dot(v0);
			let d1 = info.ray.direction.dot(v1);
			if (d0 > d1) {

				[d0, d1] = [d1, d0];

			}

			info.distances.push(d0, d1);

		}

	}

	// track the half edges associated with each triangle
	for (let i = 0, l = faces.length; i < l; i++) {

		const tri = faces[i];
		const vertices = tri.vertices;
		const vertCount = vertices.length;
		for (let i2 = 0; i2 < vertCount; i2++) {

			const index = i2;
			const next = (i2 + 1) % vertCount;
			const v0 = vertices[index];
			const v1 = vertices[next];
			const hash = hashEdge(v0, v1);

			// don't add the triangle if the edge is supposed to be hard
			if (hardEdges.has(hash)) {

				continue;

			}

			// if checking subsegments then check to see if this edge lies on a hard edge ray and whether its within any ray bounds
			if (checkSubSegments) {

				toNormalizedRay(v0, v1, _ray);

				const rayHash = hashRay(_ray);
				if (hardEdgeRays.has(rayHash)) {

					const info = hardEdgeRays.get(rayHash);
					const { ray, distances } = info;
					let d0 = ray.direction.dot(v0);
					let d1 = ray.direction.dot(v1);

					if (d0 > d1) {

						[d0, d1] = [d1, d0];

					}

					// return early if the face edge is found to be a subsegment of a line edge meaning the edge will have "hard" normals
					let found = false;
					for (let i = 0, l = distances.length; i < l; i += 2) {

						if (d0 >= distances[i] && d1 <= distances[i + 1]) {

							found = true;
							break;

						}

					}

					if (found) {

						continue;

					}

				}

			}

			const info = {
				index: index,
				tri: tri
			};
			halfEdgeList[hash] = info;

		}

	}

	// Iterate until we've tried to connect all faces to share normals
	while (true) {

		// Stop if there are no more faces left
		let halfEdge = null;
		for (const key in halfEdgeList) {

			halfEdge = halfEdgeList[key];
			break;

		}

		if (halfEdge === null) {

			break;

		}

		// Exhaustively find all connected faces
		const queue = [halfEdge];
		while (queue.length > 0) {

			// initialize all vertex normals in this triangle
			const tri = queue.pop().tri;
			const vertices = tri.vertices;
			const vertNormals = tri.normals;
			const faceNormal = tri.faceNormal;

			// Check if any edge is connected to another triangle edge
			const vertCount = vertices.length;
			for (let i2 = 0; i2 < vertCount; i2++) {

				const index = i2;
				const next = (i2 + 1) % vertCount;
				const v0 = vertices[index];
				const v1 = vertices[next];

				// delete this triangle from the list so it won't be found again
				const hash = hashEdge(v0, v1);
				delete halfEdgeList[hash];

				const reverseHash = hashEdge(v1, v0);
				const otherInfo = halfEdgeList[reverseHash];
				if (otherInfo) {

					const otherTri = otherInfo.tri;
					const otherIndex = otherInfo.index;
					const otherNormals = otherTri.normals;
					const otherVertCount = otherNormals.length;
					const otherFaceNormal = otherTri.faceNormal;

					// NOTE: If the angle between faces is > 67.5 degrees then assume it's
					// hard edge. There are some cases where the line segments do not line up exactly
					// with or span multiple triangle edges (see Lunar Vehicle wheels).
					//注：如果面之间的角度>67.5度，则假设为
					//硬边。在某些情况下，线段并不完全对齐
					//具有或跨越多个三角形边缘（参见月球车车轮）。
					if (Math.abs(otherTri.faceNormal.dot(tri.faceNormal)) < 0.25) {

						continue;

					}

					// if (Math.abs(otherTri.faceNormal.dot(tri.faceNormal)) < 0.25) {

					// 	continue;

					// }

					// if this triangle has already been traversed then it won't be in
					// the halfEdgeList. If it has not then add it to the queue and delete
					// it so it won't be found again.
					if (reverseHash in halfEdgeList) {

						queue.push(otherInfo);
						delete halfEdgeList[reverseHash];

					}

					// share the first normal
					const otherNext = (otherIndex + 1) % otherVertCount;
					if (
						vertNormals[index] && otherNormals[otherNext] &&
						vertNormals[index] !== otherNormals[otherNext]
					) {

						otherNormals[otherNext].norm.add(vertNormals[index].norm);
						vertNormals[index].norm = otherNormals[otherNext].norm;

					}

					let sharedNormal1 = vertNormals[index] || otherNormals[otherNext];
					if (sharedNormal1 === null) {

						// it's possible to encounter an edge of a triangle that has already been traversed meaning
						// both edges already have different normals defined and shared. To work around this we create
						// a wrapper object so when those edges are merged the normals can be updated everywhere.
						sharedNormal1 = { norm: new Vector3() };
						normals.push(sharedNormal1.norm);

					}

					if (vertNormals[index] === null) {

						vertNormals[index] = sharedNormal1;
						sharedNormal1.norm.add(faceNormal);

					}

					if (otherNormals[otherNext] === null) {

						otherNormals[otherNext] = sharedNormal1;
						sharedNormal1.norm.add(otherFaceNormal);

					}

					// share the second normal
					if (
						vertNormals[next] && otherNormals[otherIndex] &&
						vertNormals[next] !== otherNormals[otherIndex]
					) {

						otherNormals[otherIndex].norm.add(vertNormals[next].norm);
						vertNormals[next].norm = otherNormals[otherIndex].norm;

					}

					let sharedNormal2 = vertNormals[next] || otherNormals[otherIndex];
					if (sharedNormal2 === null) {

						sharedNormal2 = { norm: new Vector3() };
						normals.push(sharedNormal2.norm);

					}

					if (vertNormals[next] === null) {

						vertNormals[next] = sharedNormal2;
						sharedNormal2.norm.add(faceNormal);

					}

					if (otherNormals[otherIndex] === null) {

						otherNormals[otherIndex] = sharedNormal2;
						sharedNormal2.norm.add(otherFaceNormal);

					}

				}

			}

		}

	}

	// The normals of each face have been added up so now we average them by normalizing the vector.
	for (let i = 0, l = normals.length; i < l; i++) {

		normals[i].normalize();

	}

}

function isPartType(type) {

	return type === 'Part' || type === 'Unofficial_Part';

}

function isPrimitiveType(type) {

	return /primitive/i.test(type) || type === 'Subpart';

}

class LineParser {

	constructor(line, lineNumber) {

		this.line = line;
		this.lineLength = line.length;
		this.currentCharIndex = 0;
		this.currentChar = ' ';
		this.lineNumber = lineNumber;

	}

	seekNonSpace() {

		while (this.currentCharIndex < this.lineLength) {

			this.currentChar = this.line.charAt(this.currentCharIndex);

			if (this.currentChar !== ' ' && this.currentChar !== '\t') {

				return;

			}

			this.currentCharIndex++;

		}

	}

	getToken() {

		const pos0 = this.currentCharIndex++;

		// Seek space
		while (this.currentCharIndex < this.lineLength) {

			this.currentChar = this.line.charAt(this.currentCharIndex);

			if (this.currentChar === ' ' || this.currentChar === '\t') {

				break;

			}

			this.currentCharIndex++;

		}

		const pos1 = this.currentCharIndex;

		this.seekNonSpace();

		return this.line.substring(pos0, pos1);

	}

	getVector() {

		return new Vector3(parseFloat(this.getToken()), parseFloat(this.getToken()), parseFloat(this.getToken()));

	}

	getRemainingString() {

		return this.line.substring(this.currentCharIndex, this.lineLength);

	}

	isAtTheEnd() {

		return this.currentCharIndex >= this.lineLength;

	}

	setToEnd() {

		this.currentCharIndex = this.lineLength;

	}

	getLineNumberString() {

		return this.lineNumber >= 0 ? ' at line ' + this.lineNumber : '';

	}

}

// Fetches and parses an intermediate representation of LDraw parts files.
//获取并解析LDraw零件文件的中间表示形式。
class LDrawParsedCache {

	constructor(loader) {

		this.loader = loader;
		this._cache = {};

	}

	cloneResult(original) {

		const result = {};

		// vertices are transformed and normals computed before being converted to geometry
		// so these pieces must be cloned.
		result.faces = original.faces.map(face => {

			return {
				colorCode: face.colorCode,
				material: face.material,
				vertices: face.vertices.map(v => v.clone()),
				normals: face.normals.map(() => null),
				faceNormal: null
			};

		});

		result.conditionalSegments = original.conditionalSegments.map(face => {

			return {
				colorCode: face.colorCode,
				material: face.material,
				vertices: face.vertices.map(v => v.clone()),
				controlPoints: face.controlPoints.map(v => v.clone())
			};

		});

		result.lineSegments = original.lineSegments.map(face => {

			return {
				colorCode: face.colorCode,
				material: face.material,
				vertices: face.vertices.map(v => v.clone())
			};

		});

		// none if this is subsequently modified
		result.type = original.type;
		result.category = original.category;
		result.keywords = original.keywords;
		result.author = original.author;
		result.subobjects = original.subobjects;
		result.fileName = original.fileName;
		result.totalFaces = original.totalFaces;
		result.startingBuildingStep = original.startingBuildingStep;
		result.materials = original.materials;


		//搭搭 测试
		result.stepAnimationInfo = original.stepAnimationInfo;
		result.CameraAnimationArr = original.CameraAnimationArr;
		// if (original.stepAnimationInfo) {

		// 	//console.log('stepAnimationInfo33', result.stepAnimationInfo, original.stepAnimationInfo)

		// }
		result.group = null;
		return result;

	}

	// fetchData 获取数据
	async fetchData(fileName) {

		let triedLowerCase = false;
		let locationState = FILE_LOCATION_TRY_PARTS;
		while (locationState !== FILE_LOCATION_NOT_FOUND) {

			let subobjectURL = fileName;
			switch (locationState) {

				case FILE_LOCATION_AS_IS:
					locationState = locationState + 1;
					break;

				case FILE_LOCATION_TRY_PARTS:
					subobjectURL = 'parts/' + subobjectURL;
					locationState = locationState + 1;
					break;

				case FILE_LOCATION_TRY_P:
					subobjectURL = 'p/' + subobjectURL;
					locationState = locationState + 1;
					break;

				case FILE_LOCATION_TRY_MODELS:
					subobjectURL = 'models/' + subobjectURL;
					locationState = locationState + 1;
					break;

				case FILE_LOCATION_TRY_RELATIVE:
					subobjectURL = fileName.substring(0, fileName.lastIndexOf('/') + 1) + subobjectURL;
					locationState = locationState + 1;
					break;

				case FILE_LOCATION_TRY_ABSOLUTE:

					if (triedLowerCase) {

						// Try absolute path
						locationState = FILE_LOCATION_NOT_FOUND;

					} else {

						// Next attempt is lower case
						fileName = fileName.toLowerCase();
						subobjectURL = fileName;
						triedLowerCase = true;
						locationState = FILE_LOCATION_TRY_PARTS;

					}

					break;

			}

			const loader = this.loader;
			const fileLoader = new FileLoader(loader.manager);
			fileLoader.setPath(loader.partsLibraryPath);
			fileLoader.setRequestHeader(loader.requestHeader);
			fileLoader.setWithCredentials(loader.withCredentials);

			try {

				const text = await fileLoader.loadAsync(subobjectURL);
				return text;

			} catch {

				continue;

			}

		}

		//缺失这个零件，就

		throw new Error('LDrawLoader: Subobject "' + fileName + '" could not be loaded.');

	}

	parse(text, fileName = null) {

		const loader = this.loader;

		// final results
		const faces = [];
		const lineSegments = [];
		const conditionalSegments = [];
		const subobjects = [];
		const materials = {};

		const getLocalMaterial = colorCode => {

			return materials[colorCode] || null;

		};

		let type = 'Model';
		let category = null;
		let keywords = null;
		let author = null;
		let stepAnimationInfo = []
		let CameraAnimationArr = []
		let ModelRotate = null
		let GroupStandInSet = null
		let PartVisible = null
		// let partAnimation = null
		let totalFaces = 0;

		// split into lines
		if (text.indexOf('\r\n') !== - 1) {

			// This is faster than String.split with regex that splits on both
			text = text.replace(/\r\n/g, '\n');

		}

		const lines = text.split('\n');
		const numLines = lines.length;

		let parsingEmbeddedFiles = false;
		let currentEmbeddedFileName = null;
		let currentEmbeddedText = null;

		let bfcCertified = false;
		let bfcCCW = true;
		let bfcInverted = false;
		let bfcCull = true;

		let startingBuildingStep = false;

		// Parse all line commands
		for (let lineIndex = 0; lineIndex < numLines; lineIndex++) {

			const line = lines[lineIndex];

			if (line.length === 0) continue;

			if (parsingEmbeddedFiles) {

				if (line.startsWith('0 FILE ')) {

					// Save previous embedded file in the cache
					// 在缓存中保存以前的嵌入文件
					this.setData(currentEmbeddedFileName, currentEmbeddedText);

					// New embedded text file
					currentEmbeddedFileName = line.substring(7);
					currentEmbeddedText = '';

				} else {

					currentEmbeddedText += line + '\n';

				}

				continue;

			}

			const lp = new LineParser(line, lineIndex + 1);
			lp.seekNonSpace();

			if (lp.isAtTheEnd()) {

				// Empty line
				continue;

			}

			// Parse the line type
			const lineType = lp.getToken();

			let material;
			let colorCode;
			let segment;
			let ccw;
			let doubleSided;
			let v0, v1, v2, v3, c0, c1;

			switch (lineType) {

				// Line type 0: Comment or META
				case '0':

					// Parse meta directive
					const meta = lp.getToken();

					if (meta) {

						switch (meta) {

							case '!LDRAW_ORG':

								type = lp.getToken();
								break;

							case '!COLOUR':

								material = loader.parseColorMetaDirective(lp);
								if (material) {

									materials[material.userData.code] = material;

								} else {

									console.warn('LDrawLoader: Error parsing material' + lp.getLineNumberString());

								}

								break;

							case '!CATEGORY':

								category = lp.getToken();
								break;

							case '!KEYWORDS':

								const newKeywords = lp.getRemainingString().split(',');
								if (newKeywords.length > 0) {

									if (!keywords) {

										keywords = [];

									}

									newKeywords.forEach(function (keyword) {

										keywords.push(keyword.trim());

									});

								}

								break;

							case 'FILE':

								if (lineIndex > 0) {

									// Start embedded text files parsing
									parsingEmbeddedFiles = true;
									currentEmbeddedFileName = lp.getRemainingString();
									currentEmbeddedText = '';

									bfcCertified = false;
									bfcCCW = true;

								}

								break;

							case 'BFC':

								// Changes to the backface culling state
								while (!lp.isAtTheEnd()) {

									const token = lp.getToken();

									switch (token) {

										case 'CERTIFY':
										case 'NOCERTIFY':

											bfcCertified = token === 'CERTIFY';
											bfcCCW = true;

											break;

										case 'CW':
										case 'CCW':

											bfcCCW = token === 'CCW';

											break;

										case 'INVERTNEXT':

											bfcInverted = true;

											break;

										case 'CLIP':
										case 'NOCLIP':

											bfcCull = token === 'CLIP';

											break;

										default:

											console.warn('THREE.LDrawLoader: BFC directive "' + token + '" is unknown.');

											break;

									}

								}

								break;

							case 'STEP':

								startingBuildingStep = true;

								break;

							case 'Author:':

								author = lp.getToken();

								break;


							case 'DADAStepAnimation':


								let stepAnimation_i = lp.getToken();

								stepAnimationInfo.push(stepAnimation_i)
								break;
							// partAnimation


							//相机动画数据
							case 'DADACameraAnimation':


								let cameraAnimation_i = lp.getToken();

								if (cameraAnimation_i.length > 0) {

									CameraAnimationArr.push(cameraAnimation_i)

								}

								break;
							//模型临时旋转参数
							case 'DADAModelStepRotateData':


								ModelRotate = lp.getToken();
								break;

							//零件在步骤中的可见性
							case 'DADAStandInSet':


								GroupStandInSet = lp.getToken();

								break;

							// 0 DADAStandInSet


							// modelRotate = 

							// if(cameraAnimation_i.length>0){

							// 	CameraAnimationArr.push(cameraAnimation_i)

							// }






							default:
								// Other meta directives are not implemented

								break;

						}

					}

					break;

				// Line type 1: Sub-object file
				case '1':

					colorCode = lp.getToken();
					material = getLocalMaterial(colorCode);

					const posX = parseFloat(lp.getToken());
					const posY = parseFloat(lp.getToken());
					const posZ = parseFloat(lp.getToken());
					const m0 = parseFloat(lp.getToken());
					const m1 = parseFloat(lp.getToken());
					const m2 = parseFloat(lp.getToken());
					const m3 = parseFloat(lp.getToken());
					const m4 = parseFloat(lp.getToken());
					const m5 = parseFloat(lp.getToken());
					const m6 = parseFloat(lp.getToken());
					const m7 = parseFloat(lp.getToken());
					const m8 = parseFloat(lp.getToken());

					const matrix = new Matrix4().set(
						m0, m1, m2, posX,
						m3, m4, m5, posY,
						m6, m7, m8, posZ,
						0, 0, 0, 1
					);
					// //console.log('matrix3333',matrix)

					let fileName = lp.getRemainingString().trim().replace(/\\/g, '/');

					if (loader.fileMap[fileName]) {

						// Found the subobject path in the preloaded file path map
						fileName = loader.fileMap[fileName];

					} else {

						// Standardized subfolders
						if (fileName.startsWith('s/')) {

							fileName = 'parts/' + fileName;

						} else if (fileName.startsWith('48/')) {

							fileName = 'p/' + fileName;

						}

					}

					subobjects.push({
						material: material,
						colorCode: colorCode,
						matrix: matrix,
						fileName: fileName,
						inverted: bfcInverted,
						startingBuildingStep: startingBuildingStep,
						stepAnimationInfo,
						CameraAnimationArr,
						ModelRotate,
						GroupStandInSet,
						PartVisible
					});

					stepAnimationInfo = []
					CameraAnimationArr = []
					ModelRotate = null
					GroupStandInSet = null
					PartVisible = null
					// CameraAnimationArr = []
					startingBuildingStep = false;
					bfcInverted = false;
					// stepAnimationInfo = []

					break;

				// Line type 2: Line segment
				case '2':

					colorCode = lp.getToken();
					material = getLocalMaterial(colorCode);
					v0 = lp.getVector();
					v1 = lp.getVector();

					segment = {
						material: material,
						colorCode: colorCode,
						vertices: [v0, v1],
					};

					lineSegments.push(segment);

					break;

				// Line type 5: Conditional Line segment
				case '5':

					colorCode = lp.getToken();
					material = getLocalMaterial(colorCode);
					v0 = lp.getVector();
					v1 = lp.getVector();
					c0 = lp.getVector();
					c1 = lp.getVector();

					segment = {
						material: material,
						colorCode: colorCode,
						vertices: [v0, v1],
						controlPoints: [c0, c1],
					};

					conditionalSegments.push(segment);

					break;

				// Line type 3: Triangle
				case '3':

					colorCode = lp.getToken();
					material = getLocalMaterial(colorCode);
					ccw = bfcCCW;
					doubleSided = !bfcCertified || !bfcCull;

					if (ccw === true) {

						v0 = lp.getVector();
						v1 = lp.getVector();
						v2 = lp.getVector();

					} else {

						v2 = lp.getVector();
						v1 = lp.getVector();
						v0 = lp.getVector();

					}

					faces.push({
						material: material,
						colorCode: colorCode,
						faceNormal: null,
						vertices: [v0, v1, v2],
						normals: [null, null, null],
					});
					totalFaces++;

					if (doubleSided === true) {

						faces.push({
							material: material,
							colorCode: colorCode,
							faceNormal: null,
							vertices: [v2, v1, v0],
							normals: [null, null, null],
						});
						totalFaces++;

					}

					break;

				// Line type 4: Quadrilateral
				case '4':

					colorCode = lp.getToken();
					material = getLocalMaterial(colorCode);
					ccw = bfcCCW;
					doubleSided = !bfcCertified || !bfcCull;

					if (ccw === true) {

						v0 = lp.getVector();
						v1 = lp.getVector();
						v2 = lp.getVector();
						v3 = lp.getVector();

					} else {

						v3 = lp.getVector();
						v2 = lp.getVector();
						v1 = lp.getVector();
						v0 = lp.getVector();

					}

					// specifically place the triangle diagonal in the v0 and v1 slots so we can
					// account for the doubling of vertices later when smoothing normals.
					faces.push({
						material: material,
						colorCode: colorCode,
						faceNormal: null,
						vertices: [v0, v1, v2, v3],
						normals: [null, null, null, null],
					});
					totalFaces += 2;

					if (doubleSided === true) {

						faces.push({
							material: material,
							colorCode: colorCode,
							faceNormal: null,
							vertices: [v3, v2, v1, v0],
							normals: [null, null, null, null],
						});
						totalFaces += 2;

					}

					break;

				default:
					throw new Error('LDrawLoader: Unknown line type "' + lineType + '"' + lp.getLineNumberString() + '.');

			}

		}

		if (parsingEmbeddedFiles) {

			this.setData(currentEmbeddedFileName, currentEmbeddedText);

		}

		// if (stepAnimationInfo) {

		// 	//console.log('stepAnimationInfo', stepAnimationInfo)


		// }

		return {
			faces,
			conditionalSegments,
			lineSegments,
			type,
			// stepAnimationInfo,
			// CameraAnimationArr,
			category,
			keywords,
			author,
			subobjects,
			totalFaces,
			startingBuildingStep,
			materials,
			fileName,
			group: null
		};

	}





	// returns an (optionally cloned) instance of the data
	getData(fileName, clone = true) {

		const key = fileName.toLowerCase();
		const result = this._cache[key];
		if (result === null || result instanceof Promise) {

			return null;

		}

		if (clone) {

			return this.cloneResult(result);

		} else {

			return result;

		}

	}

	// kicks off a fetch and parse of the requested data if it hasn't already been loaded. Returns when
	// the data is ready to use and can be retrieved synchronously with "getData".
	//如果请求的数据尚未加载，则启动对该数据的提取和解析。返回时间
	//数据已准备好使用，并且可以通过“getData”同步检索。
	async ensureDataLoaded(fileName) {

		const key = fileName.toLowerCase();
		if (!(key in this._cache)) {

			// replace the promise with a copy of the parsed data for immediate processing
			// 将promise替换为已解析数据的副本，以便立即处理
			this._cache[key] = this.fetchData(fileName).then(text => {

				const info = this.parse(text, fileName);
				this._cache[key] = info;
				// //console.log('info666', info)
				return info;

			})
				.catch(err => {

					//我觉得在这里可以实现缺失模型的正方体替换逻辑
					//思路是加载器在全部text中找不到这个名字的文件就会catch
					//catch中我使用一个替换零件存储并返回
					//这个替换零件就是我将要以正方形框现实的零件
					//这里是替换的缺失零件

					const MyInfo = {
						author: "MyNoPartBox",
						category: null,
						conditionalSegments: [],
						faces: [{
							colorCode: "0",
							faceNormal: null,
							material: null,
							normals: [null, null, null],
							vertices: [
								new Vector3(0.9239, 0.0761, 0.3827),
								new Vector3(0.9239, 0, 0.3827),
								new Vector3(1, 0, 0),
							]
						}],
						fileName: fileName,

						// colorCode: "0",
						// "1-4cyls.dat",
						group: null,
						keywords: null,
						lineSegments: [],
						materials: {},
						startingBuildingStep: false,
						subobjects: [],
						totalFaces: 1,
						type: "Part"

					}

					this._cache[key] = MyInfo
					console.error('未找到', fileName, err)
					// return MyInfo
				})





		}

		// //console.log('在里面info777', this._cache[key], this._cache)
		// //console.log('在里面info777', this._cache[key].type, this._cache)

		await this._cache[key];

	}

	// sets the data in the cache from parsed data
	// 根据解析的数据设置缓存中的数据
	setData(fileName, text) {

		const key = fileName.toLowerCase();
		this._cache[key] = this.parse(text, fileName);

	}

	clearAllCache() {
		this._cache.clear
		this._cache = null
		this._cache = {}
		// this._cache = {};
	}

}

// returns the material for an associated color code. If the color code is 16 for a face or 24 for
// an edge then the passthroughColorCode is used.
//返回关联颜色代码的材质。如果一个面的颜色代码是16
//则使用passthroughColorCode。
function getMaterialFromCode(colorCode, parentColorCode, materialHierarchy, forEdge) {

	const isPassthrough = !forEdge && colorCode === MAIN_COLOUR_CODE || forEdge && colorCode === MAIN_EDGE_COLOUR_CODE;
	if (isPassthrough) {

		colorCode = parentColorCode;

	}

	return materialHierarchy[colorCode] || null;

}

// Class used to parse and build LDraw parts as three.js objects and cache them if they're a "Part" type.
//类，用于将LDraw部分解析和构建为three.js对象，并在它们是“Part”类型时缓存它们。
class LDrawPartsGeometryCache {

	constructor(loader) {

		this.loader = loader;
		this.parseCache = new LDrawParsedCache(loader);
		this._cache = {};

	}

	clearAllCache() {
		// this._cache = {}
		this._cache.clear
		this._cache = null
		this._cache = {}
		this.parseCache.clearAllCache()
	}

	// Convert the given file information into a mesh by processing subobjects.
	//通过处理子对象将给定的文件信息转换为网格。
	async processIntoMesh(info) {

		const loader = this.loader;
		const parseCache = this.parseCache;
		const faceMaterials = new Set();

		// Processes the part subobject information to load child parts and merge geometry onto part
		// piece object.
		const processInfoSubobjects = async (info, subobject = null) => {

			const subobjects = info.subobjects;
			const promises = [];

			// Trigger load of all subobjects. If a subobject isn't a primitive then load it as a separate
			// group which lets instruction steps apply correctly.
			for (let i = 0, l = subobjects.length; i < l; i++) {

				const subobject = subobjects[i];
				const promise = parseCache.ensureDataLoaded(subobject.fileName).then(() => {

					const subobjectInfo = parseCache.getData(subobject.fileName, false);
					// //console.log('subobjectInfo.type',subobjectInfo)
					if (!isPrimitiveType(subobjectInfo.type)) {

						return this.loadModel(subobject.fileName).catch(error => {

							console.warn(error);
							return null;

						});

					}

					return processInfoSubobjects(parseCache.getData(subobject.fileName), subobject);

				});

				promises.push(promise);

			}

			const group = new Group();
			group.userData.category = info.category;
			group.userData.keywords = info.keywords;
			group.userData.author = info.author;
			group.userData.type = info.type;
			group.userData.fileName = info.fileName;


			//搭搭

			// group.userData.stepAnimationInfo = info.stepAnimationInfo;
			// group.userData.CameraAnimationArr = info.CameraAnimationArr

			// if (info.stepAnimationInfo) {

			// 	//console.log('stepAnimationInfo22', group.userData.stepAnimationInfo, info.stepAnimationInfo)

			// }


			info.group = group;

			const subobjectInfos = await Promise.all(promises);
			for (let i = 0, l = subobjectInfos.length; i < l; i++) {

				const subobject = info.subobjects[i];
				const subobjectInfo = subobjectInfos[i];

				if (subobjectInfo === null) {

					// the subobject failed to load
					continue;

				}

				// if the subobject was loaded as a separate group then apply the parent scopes materials
				if (subobjectInfo.isGroup) {

					const subobjectGroup = subobjectInfo;
					subobject.matrix.decompose(subobjectGroup.position, subobjectGroup.quaternion, subobjectGroup.scale);

					//存储安装位置
					// subobjectGroup.userData.setMatrixLocal = subobject.matrix.clone()

					subobjectGroup.userData.startingBuildingStep = subobject.startingBuildingStep;
					// //console.log('JSON.parse(subobject.startingBuildingStep)',JSON.parse(subobject.startingBuildingStep))

					subobjectGroup.name = subobject.fileName;
					// JSON.parse(jsonString);

					loader.applyMaterialsToMesh(subobjectGroup, subobject.colorCode, info.materials);
					subobjectGroup.userData.colorCode = subobject.colorCode;

					subobjectGroup.userData.backupColorCode = subobject.colorCode;

					//console.log('subobject.CameraAnimationArr:', subobject.CameraAnimationArr)
					//搭搭 测试
					//将数组内的动画数据字符串遍历转换成对象
					var stepAnimationInfo = []
					for (let o = 0; o < subobject.stepAnimationInfo.length; o++) {

						stepAnimationInfo.push(JSON.parse(subobject.stepAnimationInfo[o]))

					}
					subobjectGroup.userData.stepAnimationInfo = stepAnimationInfo;

					//相机动画
					var CameraAnimationArr = []
					for (let p = 0; p < subobject.CameraAnimationArr.length; p++) {

						CameraAnimationArr.push(JSON.parse(subobject.CameraAnimationArr[p]))
					}
					subobjectGroup.userData.CameraAnimationArr = CameraAnimationArr;

					//模型旋转设置参数（步骤中）
					// ModelRotate
					if (subobject.ModelRotate) {
						let matrix4Arr = JSON.parse(subobject.ModelRotate)
						let newRotateMatrix4 = new Matrix4().fromArray(matrix4Arr)
						//console.log('newRotateMatrix4', matrix4Arr, newRotateMatrix4)
						subobjectGroup.userData.step_Model_MatrixWorld_set = {
							matrix: newRotateMatrix4,
							thisStepSetMatrixWorld: true
						}
					}

					//替身组内的可见性设置
					// GroupStandInSet
					if (subobject.GroupStandInSet) {
						subobjectGroup.userData.isStandInGroup = true
						subobjectGroup.userData.StandInSet = JSON.parse(subobject.GroupStandInSet)
					}

					//零件在步骤中的可见性
					// PartVisible
					if (subobject.PartVisible) {
						const PartVisibleInStep = JSON.parse(subobject.PartVisible)
						subobjectGroup.userData.PartVisibleInStep = PartVisibleInStep

						const arr_str = PartVisibleInStep.split(';')

						var arr_num = []
						for (let m = 0; m < arr_str.length; m++) {

							arr_num.push(parseInt(arr_str[m]))
							// 或者使用 parseFloat(arr[i]) 将字符串转换为浮点数
						}

						subobjectGroup.userData.PartVisibleInStepArr = arr_num
					}

					group.add(subobjectGroup);
					continue;

				}

				// add the subobject group if it has children in case it has both children and primitives
				//如果子对象组同时具有子对象和基元，则添加该子对象组（如果它具有子对象）
				if (subobjectInfo.group.children.length) {

					group.add(subobjectInfo.group);

				}

				// transform the primitives into the local space of the parent piece and append them to
				// to the parent primitives list.
				//将基元转换到父片段的局部空间中，并将它们附加到//到父基元列表。
				const parentLineSegments = info.lineSegments;
				const parentConditionalSegments = info.conditionalSegments;
				const parentFaces = info.faces;

				const lineSegments = subobjectInfo.lineSegments;
				const conditionalSegments = subobjectInfo.conditionalSegments;

				const faces = subobjectInfo.faces;
				const matrix = subobject.matrix;
				const inverted = subobject.inverted;
				const matrixScaleInverted = matrix.determinant() < 0;
				const colorCode = subobject.colorCode;

				const lineColorCode = colorCode === MAIN_COLOUR_CODE ? MAIN_EDGE_COLOUR_CODE : colorCode;
				for (let i = 0, l = lineSegments.length; i < l; i++) {

					const ls = lineSegments[i];
					const vertices = ls.vertices;
					vertices[0].applyMatrix4(matrix);
					vertices[1].applyMatrix4(matrix);
					ls.colorCode = ls.colorCode === MAIN_EDGE_COLOUR_CODE ? lineColorCode : ls.colorCode;
					ls.material = ls.material || getMaterialFromCode(ls.colorCode, ls.colorCode, info.materials, true);

					parentLineSegments.push(ls);

				}

				for (let i = 0, l = conditionalSegments.length; i < l; i++) {

					const os = conditionalSegments[i];
					const vertices = os.vertices;
					const controlPoints = os.controlPoints;
					vertices[0].applyMatrix4(matrix);
					vertices[1].applyMatrix4(matrix);
					controlPoints[0].applyMatrix4(matrix);
					controlPoints[1].applyMatrix4(matrix);
					os.colorCode = os.colorCode === MAIN_EDGE_COLOUR_CODE ? lineColorCode : os.colorCode;
					os.material = os.material || getMaterialFromCode(os.colorCode, os.colorCode, info.materials, true);

					parentConditionalSegments.push(os);

				}

				for (let i = 0, l = faces.length; i < l; i++) {

					const tri = faces[i];
					const vertices = tri.vertices;
					for (let i = 0, l = vertices.length; i < l; i++) {

						vertices[i].applyMatrix4(matrix);

					}

					tri.colorCode = tri.colorCode === MAIN_COLOUR_CODE ? colorCode : tri.colorCode;
					tri.material = tri.material || getMaterialFromCode(tri.colorCode, colorCode, info.materials, false);
					faceMaterials.add(tri.colorCode);

					// If the scale of the object is negated then the triangle winding order
					// needs to be flipped.
					if (matrixScaleInverted !== inverted) {

						vertices.reverse();

					}

					parentFaces.push(tri);

				}

				info.totalFaces += subobjectInfo.totalFaces;

			}

			// Apply the parent subobjects pass through material code to this object. This is done several times due
			// to material scoping.
			if (subobject) {

				loader.applyMaterialsToMesh(group, subobject.colorCode, info.materials);
				group.userData.colorCode = subobject.colorCode;

			}

			return info;

		};

		// Track material use to see if we need to use the normal smooth slow path for hard edges.
		for (let i = 0, l = info.faces; i < l; i++) {

			faceMaterials.add(info.faces[i].colorCode);

		}

		await processInfoSubobjects(info);

		if (loader.smoothNormals) {

			const checkSubSegments = faceMaterials.size > 1;
			generateFaceNormals(info.faces);
			smoothNormals(info.faces, info.lineSegments, checkSubSegments);

		}

		// Add the primitive objects and metadata.
		const group = info.group;
		if (info.faces.length > 0) {

			group.add(createObject(info.faces, 3, false, info.totalFaces));

		}

		if (info.lineSegments.length > 0) {

			group.add(createObject(info.lineSegments, 2));

		}

		if (info.conditionalSegments.length > 0) {

			group.add(createObject(info.conditionalSegments, 2, true));

		}

		return group;

	}

	hasCachedModel(fileName) {

		return fileName !== null && fileName.toLowerCase() in this._cache;

	}

	async getCachedModel(fileName) {

		if (fileName !== null && this.hasCachedModel(fileName)) {

			const key = fileName.toLowerCase();
			const group = await this._cache[key];
			return group.clone();

		} else {

			return null;

		}

	}

	// Loads and parses the model with the given file name. Returns a cached copy if available.
	// 加载并解析具有给定文件名的模型。返回缓存副本（如果可用）
	async loadModel(fileName) {

		const parseCache = this.parseCache;
		const key = fileName.toLowerCase();
		if (this.hasCachedModel(fileName)) {

			// Return cached model if available.
			return this.getCachedModel(fileName);

		} else {

			// Otherwise parse a new model.
			// Ensure the file data is loaded and pre parsed.
			await parseCache.ensureDataLoaded(fileName);

			const info = parseCache.getData(fileName);
			// //console.log('info2233444',info)
			//在这里可以从缓存里面拿出零件的数据
			const promise = this.processIntoMesh(info);

			// Now that the file has loaded it's possible that another part parse has been waiting in parallel
			// so check the cache again to see if it's been added since the last async operation so we don't
			// do unnecessary work.
			if (this.hasCachedModel(fileName)) {

				return this.getCachedModel(fileName);

			}

			// Cache object if it's a part so it can be reused later.
			if (isPartType(info.type)) {

				this._cache[key] = promise;

			}

			// return a copy
			const group = await promise;
			return group.clone();

		}

	}

	// parses the given model text into a renderable object. Returns cached copy if available.
	// 将给定的模型文本解析为可渲染对象。返回缓存副本（如果可用）。
	async parseModel(text) {

		const parseCache = this.parseCache;
		const info = parseCache.parse(text);
		if (isPartType(info.type) && this.hasCachedModel(info.fileName)) {

			return this.getCachedModel(info.fileName);

		}

		return this.processIntoMesh(info);

	}

}

function sortByMaterial(a, b) {

	if (a.colorCode === b.colorCode) {

		return 0;

	}

	if (a.colorCode < b.colorCode) {

		return - 1;

	}

	return 1;

}

function createObject(elements, elementSize, isConditionalSegments = false, totalElements = null) {

	// Creates a LineSegments (elementSize = 2) or a Mesh (elementSize = 3 )
	// With per face / segment material, implemented with mesh groups and materials array

	// Sort the faces or line segments by color code to make later the mesh groups
	elements.sort(sortByMaterial);

	if (totalElements === null) {

		totalElements = elements.length;

	}

	const positions = new Float32Array(elementSize * totalElements * 3);
	const normals = elementSize === 3 ? new Float32Array(elementSize * totalElements * 3) : null;
	const materials = [];

	const quadArray = new Array(6);
	const bufferGeometry = new BufferGeometry();
	let prevMaterial = null;
	let index0 = 0;
	let numGroupVerts = 0;
	let offset = 0;

	for (let iElem = 0, nElem = elements.length; iElem < nElem; iElem++) {

		const elem = elements[iElem];
		let vertices = elem.vertices;
		if (vertices.length === 4) {

			quadArray[0] = vertices[0];
			quadArray[1] = vertices[1];
			quadArray[2] = vertices[2];
			quadArray[3] = vertices[0];
			quadArray[4] = vertices[2];
			quadArray[5] = vertices[3];
			vertices = quadArray;

		}

		for (let j = 0, l = vertices.length; j < l; j++) {

			const v = vertices[j];
			const index = offset + j * 3;
			positions[index + 0] = v.x;
			positions[index + 1] = v.y;
			positions[index + 2] = v.z;

		}

		// create the normals array if this is a set of faces
		if (elementSize === 3) {

			if (!elem.faceNormal) {

				const v0 = vertices[0];
				const v1 = vertices[1];
				const v2 = vertices[2];
				_tempVec0.subVectors(v1, v0);
				_tempVec1.subVectors(v2, v1);
				elem.faceNormal = new Vector3()
					.crossVectors(_tempVec0, _tempVec1)
					.normalize();

			}

			let elemNormals = elem.normals;
			if (elemNormals.length === 4) {

				quadArray[0] = elemNormals[0];
				quadArray[1] = elemNormals[1];
				quadArray[2] = elemNormals[2];
				quadArray[3] = elemNormals[0];
				quadArray[4] = elemNormals[2];
				quadArray[5] = elemNormals[3];
				elemNormals = quadArray;

			}

			for (let j = 0, l = elemNormals.length; j < l; j++) {

				// use face normal if a vertex normal is not provided
				let n = elem.faceNormal;
				if (elemNormals[j]) {

					n = elemNormals[j].norm;

				}

				const index = offset + j * 3;
				normals[index + 0] = n.x;
				normals[index + 1] = n.y;
				normals[index + 2] = n.z;

			}

		}

		if (prevMaterial !== elem.colorCode) {

			if (prevMaterial !== null) {

				bufferGeometry.addGroup(index0, numGroupVerts, materials.length - 1);

			}

			const material = elem.material;

			if (material !== null) {

				if (elementSize === 3) {

					materials.push(material);

				} else if (elementSize === 2) {

					if (isConditionalSegments) {

						materials.push(material.userData.edgeMaterial.userData.conditionalEdgeMaterial);

					} else {

						materials.push(material.userData.edgeMaterial);

					}

				}

			} else {

				// If a material has not been made available yet then keep the color code string in the material array
				// to save the spot for the material once a parent scopes materials are being applied to the object.
				materials.push(elem.colorCode);

			}

			prevMaterial = elem.colorCode;
			index0 = offset / 3;
			numGroupVerts = vertices.length;

		} else {

			numGroupVerts += vertices.length;

		}

		offset += 3 * vertices.length;

	}

	if (numGroupVerts > 0) {

		bufferGeometry.addGroup(index0, Infinity, materials.length - 1);

	}

	bufferGeometry.setAttribute('position', new BufferAttribute(positions, 3));

	if (normals !== null) {

		bufferGeometry.setAttribute('normal', new BufferAttribute(normals, 3));

	}

	let object3d = null;

	if (elementSize === 2) {

		if (isConditionalSegments) {

			object3d = new ConditionalLineSegments(bufferGeometry, materials.length === 1 ? materials[0] : materials);

		} else {

			object3d = new LineSegments(bufferGeometry, materials.length === 1 ? materials[0] : materials);

		}

	} else if (elementSize === 3) {

		object3d = new Mesh(bufferGeometry, materials.length === 1 ? materials[0] : materials);

	}

	if (isConditionalSegments) {

		object3d.isConditionalLine = true;

		const controlArray0 = new Float32Array(elements.length * 3 * 2);
		const controlArray1 = new Float32Array(elements.length * 3 * 2);
		const directionArray = new Float32Array(elements.length * 3 * 2);
		for (let i = 0, l = elements.length; i < l; i++) {

			const os = elements[i];
			const vertices = os.vertices;
			const controlPoints = os.controlPoints;
			const c0 = controlPoints[0];
			const c1 = controlPoints[1];
			const v0 = vertices[0];
			const v1 = vertices[1];
			const index = i * 3 * 2;
			controlArray0[index + 0] = c0.x;
			controlArray0[index + 1] = c0.y;
			controlArray0[index + 2] = c0.z;
			controlArray0[index + 3] = c0.x;
			controlArray0[index + 4] = c0.y;
			controlArray0[index + 5] = c0.z;

			controlArray1[index + 0] = c1.x;
			controlArray1[index + 1] = c1.y;
			controlArray1[index + 2] = c1.z;
			controlArray1[index + 3] = c1.x;
			controlArray1[index + 4] = c1.y;
			controlArray1[index + 5] = c1.z;

			directionArray[index + 0] = v1.x - v0.x;
			directionArray[index + 1] = v1.y - v0.y;
			directionArray[index + 2] = v1.z - v0.z;
			directionArray[index + 3] = v1.x - v0.x;
			directionArray[index + 4] = v1.y - v0.y;
			directionArray[index + 5] = v1.z - v0.z;

		}

		bufferGeometry.setAttribute('control0', new BufferAttribute(controlArray0, 3, false));
		bufferGeometry.setAttribute('control1', new BufferAttribute(controlArray1, 3, false));
		bufferGeometry.setAttribute('direction', new BufferAttribute(directionArray, 3, false));

	}

	return object3d;

}

//

class LDrawLoader extends Loader {

	constructor(manager) {

		super(manager);

		// Array of THREE.Material
		this.materials = [];
		this.materialLibrary = {};

		// This also allows to handle the embedded text files ("0 FILE" lines)
		//将材质应用于网格这也允许处理嵌入的文本文件（“0 FILE”行）
		this.partsCache = new LDrawPartsGeometryCache(this);

		// This object is a map from file names to paths. It agilizes the paths search. If it is not set then files will be searched by trial and error.
		//此对象是从文件名到路径的映射。它使路径搜索变得灵活。如果未设置，则将通过反复尝试来搜索文件。
		this.fileMap = {};

		// Initializes the materials library with default materials
		//使用默认材质初始化材质库
		this.setMaterials([]);

		// If this flag is set to true the vertex normals will be smoothed.
		//如果此标志设置为true，则顶点法线将被平滑
		this.smoothNormals = true;

		// The path to load parts from the LDraw parts library from.
		//从LDraw零件库加载零件的路径。
		this.partsLibraryPath = '';

		// Material assigned to not available colors for meshes and edges
		//指定给网格和边的不可用颜色的材质

		// this.missingColorMaterial = new MeshBasicMaterial({ name: Loader.DEFAULT_MATERIAL_NAME, color: 0xFF00FF, roughness: 0.3, metalness: 0 });

		this.missingColorMaterial = new MeshStandardMaterial({ name: Loader.DEFAULT_MATERIAL_NAME, color: 0xFF00FF, roughness: 0.3, metalness: 0 });
		this.missingEdgeColorMaterial = new LineBasicMaterial({ name: Loader.DEFAULT_MATERIAL_NAME, color: 0xFF00FF });
		this.missingConditionalEdgeColorMaterial = new LDrawConditionalLineMaterial({ name: Loader.DEFAULT_MATERIAL_NAME, fog: true, color: 0xFF00FF });
		this.missingColorMaterial.userData.edgeMaterial = this.missingEdgeColorMaterial;
		//替换材质设为亮粉的编号
		this.missingColorMaterial.userData.code = '77'

		this.missingColorMaterial.userData.isMissingMatrial = true

		this.missingEdgeColorMaterial.userData.conditionalEdgeMaterial = this.missingConditionalEdgeColorMaterial;

	}

	clearAllCache() {
		console.log('清空所有缓存333')
		this.partsCache.clearAllCache()
	}

	setPartsLibraryPath(path) {

		this.partsLibraryPath = path;
		return this;

	}

	//主程序中获取加载的mpd文本
	getmpdText() {

		return mpdText

	}

	//主程序获取缺失的零件列表
	getMissingColor() {

		return missingColor

	}


	//主程序获取缺失的零件列表
	getMissingPartsArr() {

		return missingPartsArr

	}


	// 

	//预加载材料
	async preloadMaterials(text) {


		// const fileLoader = new FileLoader(this.manager);
		// fileLoader.setPath(this.path);
		// fileLoader.setRequestHeader(this.requestHeader);
		// fileLoader.setWithCredentials(this.withCredentials);


		//代码中，`process.env.BASE_URL`是Vue项目的基本URL，它会自动指向你的项目根目录。你需要将`your-texture-file.txt`替换为你实际的txt文件路径。
		// const Myurl = process.env.BASE_URL + 'public/colorInfo/colors.txt'; // 替换为你的txt文件路径

		// //console.log('Myurl',Myurl)
		// const text = await fileLoader.loadAsync(Myurl);

		// const text = await fileLoader.loadAsync(url);
		// const text = await fileLoader.loadAsync(url);

		// //console.log('text222',text)
		// Material_TEXT
		const colorLineRegex = /^0 !COLOUR/;
		const lines = text.split(/[\n\r]/g);
		const materials = [];
		for (let i = 0, l = lines.length; i < l; i++) {

			const line = lines[i];
			if (colorLineRegex.test(line)) {

				const directive = line.replace(colorLineRegex, '');
				const material = this.parseColorMetaDirective(new LineParser(directive));
				materials.push(material);

			}

		}

		this.setMaterials(materials);


		//将文件返回回去
		return text

	}

	//主函数
	load(url, onLoad, onProgress, onError) {

		const fileLoader = new FileLoader(this.manager);
		fileLoader.setPath(this.path);
		fileLoader.setRequestHeader(this.requestHeader);
		fileLoader.setWithCredentials(this.withCredentials);
		fileLoader.load(url, text => {

			//console.log('text2222222222 =', text)

			//保存起来，主程序上传的时候可以用
			mpdText = text
			//

			//先将txt分割转换成
			//零件名称：txt的对象

			//零件名称a：txta
			//零件名称b：txtb
			//零件名称c：txtc的格式

			// txt_partArr = splitTxtToPartArr(mpdText)

			// //console.log('分割后的222', txt_partArr)

			// //第二步，找出本模型步骤中的所有零件，不重复
			// const stepAllParts = txt_partArr[0].needSubPartsArr

			// //console.log('stepAllParts888', stepAllParts)


			// var AllPartsTxtArr = txt_partArr.partTxt



			//将每个step零件
			//a：{b:txtb.name,c:txc.name}的格式
			//现将不重复的所有零件txt在loader中生成three.js 3d对象
			//然后将这些3d对象拿来克隆，放到model模型中，这样做的好处是减少重复模型造成的硬件开销


			// for (let i = 1; i < stepAllParts.length; i++) {
			// 	// i_partTxt += stepAllParts[i].partTxt + '\n'

			// 	const i_partTxt = this.GreateAllTextByTxtInfo(stepAllParts[i])

			// 	//console.log('i_partTxt4433', i_partTxt)


			// }



			this.partsCache
				.parseModel(text, this.materialLibrary)
				.then(group => {

					// //console.log('赋予材质')
					this.applyMaterialsToMesh(group, MAIN_COLOUR_CODE, this.materialLibrary, true);
					this.computeBuildingSteps(group);
					// group.userData.fileName = url;
					// onLoad(group);

				})
				.catch(onError);







			// //console.log('mpdText', mpdText)

			//转换成缓存

			// this.partsCache
			// 	.parseModel(text, this.materialLibrary)
			// 	.then(group => {

			// 		// //console.log('赋予材质')
			// 		this.applyMaterialsToMesh(group, MAIN_COLOUR_CODE, this.materialLibrary, true);
			// 		this.computeBuildingSteps(group);
			// 		// group.userData.fileName = url;
			// 		onLoad(group);

			// 	})
			// 	.catch(onError);

		}, onProgress, onError);

		mpdText = ''
	}



	splitTxtToPartArr(mpdText) {

		var mpdPartsArr = []

		var txtArr = mpdText.split('\n')

		// if(){}
		// 0 FILE 4-4cyli.dat

		var name = ''
		var partTxt = []
		//需要的子零件
		var needSubPartsArr = []
		var startAddFileTxt = true

		//收集step里面的零件

		// var MainParts = []


		for (let i = 0; i < txtArr.length; i++) {

			// && (txtArr[i].endsWith('.dat') || txtArr[i].endsWith('.ldr'))
			if (txtArr[i].startsWith('1 ')) {

				const needSubPartsArrLineArr = txtArr[i].split(' ')

				//零件的子零件
				const needSubPartName = needSubPartsArrLineArr.slice(14).join(" ");

				//console.log('txtArr[i]', txtArr[i])

				if (needSubPartsArr.indexOf(needSubPartName) == -1) {

					needSubPartsArr.push(needSubPartName)
				}


			}

			if (startAddFileTxt) {

				partTxt += txtArr[i] + '\n'

			}

			if (txtArr[i].startsWith('0 FILE')) {

				startAddFileTxt = true

				// const nameArr = txtArr[i].split('0 FILE ')
				var Arr = txtArr[i].split(' ')
				// const nameArr = Arr[i].slice(2).join(" ")
				name = Arr.slice(2).join(" ")

				// name = nameArr[1]

			}
			// if (txtArr[i].startsWith('0 NOFILE') || txtArr[i].startsWith('0 ENDLDRDADA')) {

			if (txtArr[i].startsWith('0 NOFILE') || txtArr[i].startsWith('0 ENDLDRDADA')) {

				mpdPartsArr.push({
					name: name,
					partTxt,
					needSubPartsArr
				})
				name = ''
				partTxt = ''
				needSubPartsArr = []
			}
		}

		// partTxt = ''

		// mpdPartsArr = null

		// txtArr = null

		// if(){}
		// 0 FILE 4-4cyli.dat

		// name = ''
		// partTxt = null
		// //需要的子零件
		// needSubPartsArr = null
		// startAddFileTxt = null

		return mpdPartsArr

	}

	async loadByText(text, onLoad, onError) {

		var that = this

		// THREE_D_Parts_Arr = []

		// if (THREE_D_Parts_Arr.length > 0) {

		// 	for (let i = 0; i < THREE_D_Parts_Arr.length; i++) {

		// 		console.log('清空单一模型', THREE_D_Parts_Arr[i])
		// 		//清空之前模型占用的资源
		// 		this.disposeGroup(THREE_D_Parts_Arr[i]);

		// 	}

		// }

		// console.log('ldraw加载器清空旧的3d资源数组')

		//如果选择清除旧的模型数据，则清除
		//其实应该默认清除的，但是由于搭搭星球的编辑器不能每次加载都清除，会出现加载wedo马达或者智能集线器的第二次加载时都失掉一些mesh
		// if (disposePartData) {
		// 	if (THREE_D_Parts_Arr.length > 0) {

		// 		for (let i = 0; i < THREE_D_Parts_Arr.length; i++) {

		// 			console.log('清空单一模型222', THREE_D_Parts_Arr[i])
		// 			//清空之前模型占用的资源
		// 			this.disposeGroup(THREE_D_Parts_Arr[i]);

		// 		}

		// 	}

		// 	this.clearOBJ()

		// }



		THREE_D_Parts_Arr.length = 0

		// THREE_D_Parts_Arr = []

		//console.log('通过模型文本生成，而不是链接')

		// //console.log('text =', text)

		// mpdText =text
		//保存起来，主程序上传的时候可以用
		// 将连续的两个空格转换 ' '
		// mpdText = text.replace(/\s{2,}/g, ' ');
		mpdText = text.replace(/[ \t]+/g, " "); // 替换任何连续的空格或制表符为单个空格



		// // 将换行开头的空格去掉
		mpdText = mpdText.replace(/\n\s+/g, '\n');
		// // str = str.replace(/\n\s+/g, '\n');

		// // 去掉每行尾部的一个或者多个空格
		mpdText = mpdText.replace(/ +$/gm, '');

		// mpdText =text
		//console.log('mpdText', mpdText)




		txt_partArr = this.splitTxtToPartArr(mpdText)

		console.log('txt_partArr3333', txt_partArr)

		// //console.log('分割后的', txt_partArr)

		//console.log('分割后的222', txt_partArr)

		//第二步，找出本模型步骤中的所有主零件，不重复
		//  = []

		//从模型中找出所有需要的零件
		var stepAllParts = this.findAllPartsInStep(txt_partArr)


		// for(let i = 0;i<txt_partArr.length;i++){

		// 	if(txt_partArr[i].partTxt.startsWith('0 ENDLDRDADA')){
		// 		break
		// 	}

		// 	stepAllParts = stepAllParts.concat(txt_partArr[i].needSubPartsArr)  

		// }

		//console.log('stepAllParts888', stepAllParts)

		//将每个step零件
		//a：{b:txtb.name,c:txc.name}的格式
		//现将不重复的所有零件txt在loader中生成three.js 3d对象
		//然后将这些3d对象拿来克隆，放到model模型中，这样做的好处是减少重复模型造成的硬件开销

		// missingColor.length = 0

		//缺失的材质编号
		missingColor = []

		// const missingPart = 

		//缺失零件样式
		// const missingPart_geometry = new BoxGeometry(1000, 1000, 1000);
		// const material = new MeshBasicMaterial({ color: 0x00ff00 });
		// const missingPart = new Mesh(missingPart_geometry, material);
		missingPartsArr.length = 0

		// c.material = this.materialLibrary['13']

		for (let i = 0; i < stepAllParts.length; i++) {

			// var Partname1 = that.FindPartnameInTxt(i_partTxt)

			// console.log('THREE_D_Parts_Arreeee', THREE_D_Parts_Arr, stepAllParts[i])

			// if (that.partInHasBeenLoaded(THREE_D_Parts_Arr, stepAllParts[i])) {

			// 	console.log('零件模型已经存在，不用加载新的了')

			// 	//这个3d文件有，找下一个
			// 	continue

			// }


			// i_partTxt += stepAllParts[i].partTxt + '\n'
			var res_i = this.GreateAllTextByTxtInfo(stepAllParts[i])

			if (res_i.res == 'success') {

				const i_partTxt = res_i.data




				await this.partsCache
					.parseModel(i_partTxt, this.materialLibrary)
					.then(group => {

						THREE_D_Parts_Arr.push(group)

						group.name = stepAllParts[i]

						// // //console.log('赋予材质')
						// this.applyMaterialsToMesh(group, MAIN_COLOUR_CODE, this.materialLibrary, true);
						// this.computeBuildingSteps(group);
						// // group.userData.fileName = url;
						//console.log('零件3d对象', group)
						//console.log('index', i)


						// onLoad(group);

					})
					.catch(onError);

			}

			// else {
			// 	// res: 'missing_part',
			// 	// data: missingPartName
			// 	if (res_i.res == 'missing_part') {

			// 		console.log('缺失零件eeeee',res_i)

			// 		var missingPART = new Group().add(missingPart.clone()) 

			// 		missingPART.name = res_i.data

			// 		THREE_D_Parts_Arr.push(missingPART)

			// 	}


			// }

			// const i_partTxt



			//console.log('i_partTxt4433', i_partTxt)

			//接下来要解析步骤文件，将通过克隆将步骤文件转换成我需要的步骤层级





		}


		console.log('单一的3d模型是', THREE_D_Parts_Arr)



		// parseMainTxt()

		// var ModelGroup
		//模型的组数据，但是没有真正的零件
		// var Model_No3DpartGroup

		//模型的组数据，但是没有真正的零件
		// const Model_No3DpartGroup = this.GreatModelData(ModelGroup, text, 'mainModel')

		//将mpd文件简化，只要是dat的零件我们都是用个简单的几何体数据来代替
		//主要保留组和零件，至于零件内的是啥，随便，越简单越好
		const SimplifiedMpd = this.CreateSimplifiedMPD(mpdText)

		console.log('简化后的结果是eeeeee:', SimplifiedMpd)

		await this.partsCache
			.parseModel(SimplifiedMpd, this.materialLibrary)
			.then(group => {

				const Model_No3DpartGroup = group

				//console.log('模型结构shu是', group)

				const MODEL_CLONE = this.cloneAllPartToGroup(Model_No3DpartGroup)


				console.log('MODEL_CLONE555', MODEL_CLONE)

				//console.log('MODEL_CLONEtttttt', MODEL_CLONE)

				//console.log('Model_No3DpartGrouprrrr', Model_No3DpartGroup)

				//检测所有的材质，看看谁的metreal可见性是undefind的
				// material


				//缺失的材质
				// var errorMaterialArr = []

				MODEL_CLONE.traverse(c => {

					if (c.isMesh) {

						if (!c.material) {

							c.material = this.materialLibrary['13']

							// missingColor.push(c)
							// missingColor.push(c.userData.colorCode)

							// console.log('缺失材质',missingColor)

						}

					}

				})

				// //console.log('errorMaterialArr', errorMaterialArr)

				// MODEL_CLONE.update



				that.computeBuildingSteps(MODEL_CLONE);

				console.log('缺失材质', missingColor)

				onLoad(MODEL_CLONE);

				// THREE_D_Parts_Arr.push(group)

				// group.name = stepAllParts[i]

				// // //console.log('赋予材质')
				// this.applyMaterialsToMesh(group, MAIN_COLOUR_CODE, this.materialLibrary, true);
				// this.computeBuildingSteps(group);
				// // group.userData.fileName = url;
				//console.log('零件3d对象33333树木', Model_No3DpartGroup)
				// //console.log('index', i)


				// onLoad(group);

			})
			.catch(onError);

		txt_partArr.lemgth = 0
		text = ''
		// THREE_D_Parts_Arr.length = 0
		// MODEL_CLONE = null
		// SimplifiedMpd = null
		// if (THREE_D_Parts_Arr.length > 0) {

		// 	for (let i = 0; i < THREE_D_Parts_Arr.length; i++) {

		// 		console.log('清空单一模型222', THREE_D_Parts_Arr[i])
		// 		//清空之前模型占用的资源
		// 		this.disposeGroup(THREE_D_Parts_Arr[i]);

		// 	}

		// }
		// this.clearOBJ()

		// //console.log('没有3d数据的模型结构文件是', Model_No3DpartGroup)


		//接下来将没有零件的组通过克隆的方式生成出来，并修改材质
		// const 

		// MODEL_CLONE.updateMatrixWorld(true,true)

		// //console.log('克隆出的新模型是', MODEL_CLONE)

		// //console.log('ModelGroup3333', ModelGroup, Model_No3DpartGroup)

		//材质
		// this.applyMaterialsToMesh(MODEL_CLONE, MAIN_COLOUR_CODE, this.materialLibrary, true);

		// this.applyMaterialsToMesh(MODEL_CLONE, MAIN_COLOUR_CODE, this.materialLibrary, true);

		// this.setModelMaterialsToMesh(MODEL_CLONE)





		// this.partsCache
		// 	.parseModel(text, this.materialLibrary)
		// 	.then(group => {



		// 		// // //console.log('赋予材质')
		// 		// this.applyMaterialsToMesh(group, MAIN_COLOUR_CODE, this.materialLibrary, true);
		// 		// this.computeBuildingSteps(group);
		// 		// // group.userData.fileName = url;
		// 		// onLoad(group);

		// 	})

	}

	// partInHasBeenLoaded(LoadedPartsArr, partname) {

	// 	console.log('LoadeaPartsArr，partname', LoadedPartsArr, partname)

	// 	console.log('LoadeaPartsArrlength', LoadedPartsArr.length)

	// 	var findIn = false

	// 	for (let i = 0; i < LoadedPartsArr.length; i++) {

	// 		console.log('LoadedPartsArr[i].name == partname)', LoadedPartsArr[i].name, partname)

	// 		if (LoadedPartsArr[i].name == partname) {


	// 			findIn = true

	// 			break

	// 		}

	// 	}

	// 	return findIn



	// }

	//在模型文件中找到模型名称
	// FindPartnameInTxt(partTxt) {

	// 	const lineArr = partTxt.split('\n')

	// 	var nameLine

	// 	for (let i = 0; i < lineArr.length; i++) {

	// 		if (lineArr[i].startsWith('0 FILE')) {

	// 			nameLine = lineArr[i]

	// 			break

	// 		}

	// 	}

	// 	console.log('找到名称行1111', nameLine)

	// 	//在名称行中找到模型名字
	// 	// const str = "0 FILE 21980.dat";
	// 	const fileName1 = nameLine.match(/FILE\s+([\w-]+\.\w+)/);

	// 	console.log('fileNameeee', fileName1)

	// 	return fileName1[1]





	// }

	disposeGroup(group) {
		// 遍历当前组中的所有子对象
		group.children.forEach(child => {
			if (child instanceof Group) {
				// 如果子对象也是一个组，递归清理
				this.disposeGroup(child);
			} else if (child instanceof Mesh ||
				child instanceof Line ||
				child instanceof Points ||
				child instanceof Sprite) {
				// 如果是3D对象，清理它的材质和几何体
				disposeMesh(child);
			} else if (child instanceof Light) {
				// 如果是光照，不需要额外的处置，除非有特殊的光照纹理需要处理
				// 一般情况下，光照对象自身不需要dispose操作
			}
		});

		// 如果当前组有父级，并且需要从父级中移除
		if (group.parent !== null) {
			group.parent.remove(group);
		}

		// 清理3D对象的方法
		function disposeMesh(object) {
			console.log('释放mesh', object.geometry)
			if (object.geometry) {

				const newGeometry = new BufferGeometry();

				object.geometry.copy(newGeometry);

				// 清理旧的 BufferGeometry
				object.geometry.dispose();

				// 替换新的 BufferGeometry
				object.geometry = newGeometry;
			}
			// if (object.geometry) object.geometry.dispose();
			//单一零件的材质应该是数字或者数组
			// if (object.material) {
			// 	if (Array.isArray(object.material)) {
			// 		object.material.forEach(mat => mat.dispose());
			// 	} else {
			// 		console.log('object.materialrrrr',object.material)
			// 		object.material.dispose();
			// 	}
			// }
		}
	}





	//保存mpd文件的时候在这里生成保存文件
	// txt_partArr
	//将模型转换成mpd文件返回出去
	MakeModelToMpd() {

		var modelname = model.name && model.name != null ? model.name : 'Untitled Model'

		ExportFileText_start += '0 FILE ' + modelname + '\n' + '0 Name: ' + modelname + '\n' + '0 Author: ' + this.$root.$USERINFO.userRealName + '\n'
		ExportFileText_start += '0 DADAVersion 1.0'

		//将模型处理成ldr文件
		const dadaAnimationLdr = this.ModelParsingAsText_ldr(model)

		console.log('dadaAnimationLdr777 ', dadaAnimationLdr)
		// console.log('text文件999', ExportFileText)
		//然后将模型转换成mpd文件
		//计划在本地处理转换逻辑，在云端处理太浪费云端资源了
		//将本地加载时的mpd 的txt文件内的所有已经有的文件拿拿出来继续用

		//找出mpd文件中的 零件文件
		const sectionRES = this.LdrParsingToMpdByText(lDrawLoader.getmpdText())
		console.log('sectionRES', sectionRES)
		//mpd零件信息部分
		const MPDsection = sectionRES.mpdSection
		const SoftDsection = sectionRES.softPartMpd
		//ldr步骤部分
		// const LDRsection = sectionRES.ldrSection

		console.log('MPDsection', MPDsection)

		var resMpd = ExportFileText_start + '\n' + dadaAnimationLdr + '\n' + SoftDsection + '\n' + '0 ENDLDRDADA' + '\n' + MPDsection + '\n'

		console.log('resMpd', resMpd)


	}

	//将模型解析为文本
	ModelParsingAsText_ldr(model_) {

		//先移除模型中的所有startingBuildingStep 为false的 空白组

		var empty_NoUsablePartArr = []

		model_.traverse(c => {
			if (c.isGroup) {
				if (c.name == 'MY_EmPtY_SteP.dat') {
					if (c.userData.startingBuildingStep == false) {
						empty_NoUsablePartArr.push(c)
					}
				}
			}
		})
		//移除没用的空零件
		for (let i = 0; i < empty_NoUsablePartArr.length; i++) {

			empty_NoUsablePartArr[i].removeFromParent()

		}





		var ExportFileText_ldr = ''

		//已经存到ExportFileText_PartsMpd 内的零件名称
		//如果已经存在mpd文件中保存过的零件则不用继续保存了
		var mpdFileSaveArrNameList = []

		var missing_part_ = []



		var ExportFileText_PartsMpd = ''


		const ModelParsingAsText_ldr_Do = function (Group) {

			// ExportFileText = ''

			console.log('222Group222', Group)

			//保存本级的所有的组对象，本级处理完成后处理本级的所有子对象
			var subGroupsArr = []

			// var text = ''

			// 1 <colour> x y z a b c d e f g h i <file>

			for (let j = 0; j < Group.children.length; j++) {

				console.log('子代1111', Group.children[j])
				// Group.children[j].updateMatrixWorld()
				Group.children[j].updateMatrix()


				if (Group.children[j].userData.TYPE && (Group.children[j].userData.TYPE == 'PART' || Group.children[j].userData.TYPE == 'GROUP')) {
					//零件

					console.log('filename：11111', Group.children[j].userData.fileName)
					if (Group.children[j].userData.startingBuildingStep) {
						ExportFileText_ldr += "\n" + '0 STEP'
					}

					// if (Group.children[0].userData.startingBuildingStep) {
					//   ExportFileText_ldr += '0 STEP' + "\n"
					// }

					//如果有零件安装动画，则将零件的安装信息保存到文件中
					if (Group.children[j].userData.stepAnimationInfo && Group.children[j].userData.stepAnimationInfo.length > 0) {

						console.log('有文件动画信息', Group.children[j].userData.stepAnimationInfo)
						const partAnimationArr = Group.children[j].userData.stepAnimationInfo
						for (let o = 0; o < partAnimationArr.length; o++) {

							const jsonString = JSON.stringify(partAnimationArr[o]);


							ExportFileText_ldr += '\n' + '0 DADAStepAnimation' + ' ' + jsonString

							console.log('写入动画信息', jsonString)

						}
					}

					//如果有相机动画，则将相机动画存储进去
					if (Group.children[j].userData.CameraAnimationArr && Group.children[j].userData.CameraAnimationArr.length > 0) {

						const CameraAnimationArr = Group.children[j].userData.CameraAnimationArr
						for (let o = 0; o < CameraAnimationArr.length; o++) {

							const jsonString = JSON.stringify(CameraAnimationArr[o]);

							ExportFileText_ldr += '\n' + '0 DADACameraAnimation' + ' ' + jsonString

						}

					}


					//零件可见性数据
					// PartVisibleInStep
					if (Group.children[j].userData.PartVisibleInStep && Group.children[j].userData.PartVisibleInStep.length > 0) {

						// const CameraAnimationArr = Group.children[j].userData.CameraAnimationArr
						// for (let o = 0; o < CameraAnimationArr.length; o++) {

						const jsonString = JSON.stringify(Group.children[j].userData.PartVisibleInStep);

						ExportFileText_ldr += '\n' + '0 DADAPartVisibleData' + ' ' + jsonString

						// }

					}

					//主模型的步骤旋转数据
					if (Group.children[j].userData.step_Model_MatrixWorld_set && Group.children[j].userData.step_Model_MatrixWorld_set.thisStepSetMatrixWorld) {

						const jsonString = JSON.stringify(Group.children[j].userData.step_Model_MatrixWorld_set.matrix.elements);

						ExportFileText_ldr += '\n' + '0 DADAModelStepRotateData' + ' ' + jsonString

					}


					//如果是替身零件组
					//
					if (Group.children[j].userData.TYPE == 'GROUP' && Group.children[j].userData.isStandInGroup) {

						const jsonString = JSON.stringify(Group.children[j].userData.StandInSet);

						ExportFileText_ldr += '\n' + '0 DADAStandInSet' + ' ' + jsonString

					}



					console.log('Group.children[i]111222', Group.children[j])
					const type = '1'
					const colorCode = Group.children[j].userData.colorCode
					const Matrix_i = Group.children[j].matrix.elements
					const x = Matrix_i[12].toFixed(6)
					const y = Matrix_i[13].toFixed(6)
					const z = Matrix_i[14].toFixed(6)

					const a = Matrix_i[0].toFixed(6)
					const b = Matrix_i[4].toFixed(6)
					const c = Matrix_i[8].toFixed(6)
					const d = Matrix_i[1].toFixed(6)
					const e = Matrix_i[5].toFixed(6)
					const f = Matrix_i[9].toFixed(6)
					const g = Matrix_i[2].toFixed(6)
					const h = Matrix_i[6].toFixed(6)
					const i = Matrix_i[10].toFixed(6)

					const name = Group.children[j].userData.fileName

					//看是不是空零件，不是空零件才生成零件文本
					if (Group.children[j].userData.TYPE == 'PART' && Group.children[j].name != 'MY_EmPtY_SteP.dat') {

						if (!name) {

							console.error('名称错误11', Group)
						}


						CreatePartOrGroupMpdData(name)

					}
					// else if(Group.children[j].userData.TYPE == 'GROUP'){

					// 	CreatePartOrGroupMpdData(name)

					// }else{
					// 	console.error('类型错误')
					// }



					ExportFileText_ldr += '\n' + type + " " + colorCode + " " + x + " " + y + " " + z + " " + a + " " + b + " " + c + " " + d + " " + e + " " + f + " " + g + " " + h + " " + i + " " + name

				}

				//如果是组，则将组存起来，等会儿直接生成子步骤零件
				if (Group.children[j].userData.TYPE && Group.children[j].userData.TYPE == 'GROUP') {
					//组
					subGroupsArr.push(Group.children[j])

				}
				// if (Group.children[j].userData.TYPE && Group.children[j].userData.TYPE == 'PART' && (Group.children[j].userData.fileName.indexOf('.ldr') != -1))
				if (Group.children[j].userData.TYPE && Group.children[j].userData.TYPE == 'PART') {
					//ldr 文件
					// subGroupsArr.push(Group.children[j])

					console.log('ldr文件1111111', Group.children[j])

				}

			}

			//结束的 0 STEP
			ExportFileText_ldr += '\n' + '0 STEP'

			ExportFileText_ldr += '\n' + '0 NOFILE'

			for (let k = 0; k < subGroupsArr.length; k++) {
				// fileName

				console.log('subGroupsArr[k]', subGroupsArr[k])

				ExportFileText_ldr += '\n' + '0 FILE ' + subGroupsArr[k].userData.fileName
				// ExportFileText += "0 " + subGroupsArr[k].userData.fileName + '\n'

				ExportFileText_ldr += '\n' + '0 Name:  ' + subGroupsArr[k].userData.fileName


				//如果有零件安装动画，则将零件的安装信息保存到文件中
				if (subGroupsArr[k].userData.stepAnimationInfo && subGroupsArr[k].userData.stepAnimationInfo.length > 0) {

					const partAnimationArr = subGroupsArr[k].userData.stepAnimationInfo
					for (let p = 0; p < partAnimationArr.length; p++) {

						const jsonStringGROUP = JSON.stringify(partAnimationArr[p]);


						ExportFileText_ldr += '\n' + '0 DADAanimation' + ' ' + jsonStringGROUP

					}
				}

				// subGroupsArr[k]
				ModelParsingAsText_ldr_Do(subGroupsArr[k])

			}



			// var ExportFileText_ldr = ''


		}


		const CreatePartOrGroupMpdData = function (PartOrGroupName) {

			// ExportFileText_ldr += '\n'

			//检查这个零件是否以及存储到mpd文件中ExportFileText_PartsMpd

			//如果不存在则写入
			if (mpdFileSaveArrNameList.indexOf(PartOrGroupName) == -1) {

				// mpdPartsArr.push({
				// 	name: name,
				// 	partTxt,
				// 	needSubPartsArr
				// })

				var partData
				var needSubPartsArr = []

				//查找这个名字对应的pmd文件
				for (let i = 0; i < txt_partArr.length; i++) {

					if (txt_partArr[i].name == PartOrGroupName) {

						partData = txt_partArr[i].partTxt

						needSubPartsArr = txt_partArr[i].needSubPartsArr

						console.log('needSubPartsArr4444', needSubPartsArr)
						console.log(txt_partArr[i])

						break
					}

				}

				if (partData) {
					// ExportFileText_PartsMpd
					ExportFileText_PartsMpd += ('\n' + partData)

					mpdFileSaveArrNameList.push(PartOrGroupName)

				} else {
					console.error('保存时候缺失这个零件', PartOrGroupName)
					missing_part_.push(PartOrGroupName)
				}

				//找这个文件的子文件
				for (let i = 0; i < needSubPartsArr.length; i++) {


					if (!needSubPartsArr[i]) {

						console.error('名称错误2222', i, needSubPartsArr)
					}



					CreatePartOrGroupMpdData(needSubPartsArr[i])


				}


			}

		}


		ModelParsingAsText_ldr_Do(model_)

		console.log('缺失的零件333', missing_part_)

		console.log('mpdFileSaveArrNameList33', mpdFileSaveArrNameList)

		console.log('文件保存结果是', ExportFileText_ldr)

		console.log('ExportFileText_PartsMpd', ExportFileText_PartsMpd)

		return ExportFileText_ldr + '\n' + ExportFileText_PartsMpd

	}

	//生成简化的mpd文件，用来生成零件树

	// CreateSimplifiedMPD(mpdText) {
	// 	const arr = mpdText.split('\n')

	// 	//console.log('分解后的数组对象是:,', arr)

	// 	var newSimplifiedMPDtxt = ''

	// 	var i_txtAdd = ''

	// 	var startAdd = false

	// 	for (let i = 0; i < arr.length; i++) {

	// 		const i_txt = arr[i]

	// 		// newSimplifiedMPDtxt += i_txt + '\n'

	// 		// if (i > 0) {

	// 		if (i_txt.startsWith('0 FILE') && (i_txt.endsWith('.dat') || i_txt.endsWith('.ldr'))) {

	// 			newSimplifiedMPDtxt += i_txtAdd + '\n'

	// 			i_txtAdd = ''

	// 			startAdd = true

	// 			// startAdd = startAdd

	// 			i_txtAdd += i_txt + '\n'

	// 			continue

	// 		}


	// 		//照抄
	// 		// i_txtAdd += i_txt + '\n'

	// 		// i_txt


	// 		// if (startAdd == false) {
	// 		// 	//照抄

	// 		// 	newSimplifiedMPDtxt += i_txt + '\n'


	// 		// }


	// 		if (startAdd == true) {

	// 			// startAdd = false

	// 			i_txtAdd += '4 16 -20 8 -30 -20 8 30 -16 8 26 -16 8 -26' + '\n'

	// 			i_txtAdd += '0 NOFILE' + '\n'

	// 			startAdd = false

	// 		}


	// 		// var lineType = i_txt.split(' ')[0]


	// 		// switch (lineType) {

	// 		// 	case '1':

	// 		// 	   break



	// 		// 	case '2':

	// 		// 		break

	// 		// 	case '5':
	// 		// 		break

	// 		// 	case '3':
	// 		// 		break

	// 		// 	case '4':

	// 		// 		break


	// 		// }






	// 	}

	// 	//console.log('简化后的结果是:', newSimplifiedMPDtxt)

	// 	return newSimplifiedMPDtxt

	// }


	CreateSimplifiedMPD(mpdText) {
		const arr = mpdText.split('\n')

		//console.log('分解后的数组对象是:,', arr)

		var newSimplifiedMPDtxt = ''

		var startChange = false

		for (let i = 0; i < arr.length; i++) {

			const i_txt = arr[i]

			// newSimplifiedMPDtxt += i_txt + '\n'

			// if (i > 0) {

			if (i_txt.startsWith('0 FILE') && (i_txt.endsWith('.dat') || i_txt.endsWith('.ldr'))) {

				startChange = true
				newSimplifiedMPDtxt += i_txt + '\n'

			}

			if (startChange == false) {
				//照抄

				newSimplifiedMPDtxt += i_txt + '\n'


			}

			if (i_txt.startsWith('0 NOFILE') && (startChange == true)) {

				// startChange = false

				newSimplifiedMPDtxt += '4 16 -20 8 -30 -20 8 30 -16 8 26 -16 8 -26' + '\n'

				newSimplifiedMPDtxt += '0 NOFILE' + '\n'

				startChange = false

			}


		}

		//console.log('简化后的结果是:', newSimplifiedMPDtxt)
		// arr = ''
		return newSimplifiedMPDtxt

	}

	findAllPartsInStep(txt_partArr) {

		var AllPartsInModel = []

		var mainStepPartsAndGroup = txt_partArr[0].needSubPartsArr

		const findChildrenPart = function (FileNameArr) {

			for (let i = 0; i < FileNameArr.length; i++) {

				console.log('FileNameArr[i]', FileNameArr[i])

				if (FileNameArr[i].endsWith('.ldr') || FileNameArr[i].endsWith('.dat')) {
					//零件
					if (AllPartsInModel.indexOf(FileNameArr[i]) == -1) {

						AllPartsInModel.push(FileNameArr[i])

					}

				} else {
					//组

					var GroupName = FileNameArr[i]

					//console.log('GroupName44', GroupName)

					var GroupInfo = null

					//在总文件内找到这个组相关的依赖零件
					//跳过步骤文件
					for (let i = 1; i < txt_partArr.length; i++) {

						if (txt_partArr[i].name == GroupName) {

							//console.log('txt_partArr[i].name33', txt_partArr[i].name, GroupName, txt_partArr[i].name == GroupName)

							GroupInfo = txt_partArr[i]

							break

						}

					}
					//console.log('GroupName44 submodel group 2', 'submodel group 2' == 'SubModel Group 1')
					//如果没找到，则忽略大小写再找一遍
					if (!GroupInfo) {

						for (let i = 1; i < txt_partArr.length; i++) {

							if (txt_partArr[i].name.toLowerCase() == GroupName.toLowerCase()) {

								//console.log('txt_partArr[i].name.toLowerCase(),GroupName.toLowerCase()', txt_partArr[i].name, GroupName)
								//console.log('txt_partArr[i].name.toLowerCase() == GroupName.toLowerCase()', txt_partArr[i].name.toLowerCase(), GroupName.toLowerCase())

								GroupInfo = txt_partArr[i]

								break

							}

						}

					}

					if (GroupInfo) {
						//console.log('txt_partArr[i].name.toLowerCase(),GroupName.toLowerCase()44555', GroupName)
						//console.log('找到组555', GroupInfo)
						findChildrenPart(GroupInfo.needSubPartsArr)
					} else {
						//console.log('未找到合适的零件信息', txt_partArr[i].name, txt_partArr[i].name.toLowerCase())
						//console.log(GroupName, GroupName.toLowerCase())
					}

					// GroupInfo = null



				}

			}

		}


		findChildrenPart(mainStepPartsAndGroup)

		return AllPartsInModel


	}


	//为模型的所有后代设置材质
	// setModelMaterialsToMesh(model) {

	// 	//递归修改
	// 	const changChildrenColor = function (Group) {

	// 		for (let i = 0; i < Group.children.length; i++) {

	// 			var i_part = Group.children[i]

	// 			if(i_part.name.endsWith('.ldr')||i_part.name.endsWith('.dat')){
	// 				//零件

	// 				i_part.


	// 			}else{
	// 				//组


	// 			}

	// 		}

	// 	}
	// 	// for(){

	// 	// }

	// 	// for(let i = ){

	// 	// }

	// }




	//将text传入生成主搭建图的所有零件信息和层级关系
	// GreatModelData(GrouP, textmain, MpdfileName) {

	// 	// this.parseCache = new LDrawParsedCache(loader);
	// 	// const parseCache = this.parseCache;
	// 	// info = parseCache.parse(text, fileName);

	// 	// var ModelGroup = new Group()

	// 	//console.log('开始创建组树', GrouP, textmain, MpdfileName)

	// 	var NowGroup = GrouP

	// 	//ldr文件的结束点找打后就不用继续往下找组了，只需要找零件
	// 	var FindLdrEndPlace = false

	// 	// 0 ENDLDRDADA


	// 	// //第一次保存时保存的是mainmodel
	// 	var workNUm = 0

	// 	const parseMainTxt = function (text, nowGroup) {

	// 		//console.log('函数处理开始工作，传入的参数是:', text, nowGroup)

	// 		// const loader = this.loader;

	// 		// final results
	// 		// const faces = [];
	// 		// const lineSegments = [];
	// 		// const conditionalSegments = [];
	// 		const subobjects = [];
	// 		// const materials = {};

	// 		// const getLocalMaterial = colorCode => {

	// 		// 	return materials[colorCode] || null;

	// 		// };

	// 		let type = 'Model';
	// 		let category = null;
	// 		let keywords = null;
	// 		// let author = null;
	// 		let stepAnimationInfo = []
	// 		let CameraAnimationArr = []
	// 		let ModelRotate = null
	// 		let PartVisible = null
	// 		// let partAnimation = null
	// 		let totalFaces = 0;

	// 		let stopFind = false

	// 		// split into lines
	// 		if (text.indexOf('\r\n') !== - 1) {

	// 			// This is faster than String.split with regex that splits on both
	// 			text = text.replace(/\r\n/g, '\n');

	// 		}

	// 		const lines = text.split('\n');
	// 		const numLines = lines.length;

	// 		let parsingEmbeddedFiles = false;
	// 		let currentEmbeddedFileName = '';
	// 		let currentEmbeddedText = '';

	// 		let startFindGroupLine = false

	// 		// let bfcCertified = false;
	// 		// let bfcCCW = true;
	// 		// let bfcInverted = false;
	// 		// let bfcCull = true;

	// 		let startingBuildingStep = false;

	// 		//检查出了组，则将组的group存储起来，方便向下级检索的时候用
	// 		// let childrenGROUP

	// 		// Parse all line commands
	// 		for (let lineIndex = 0; lineIndex < numLines; lineIndex++) {

	// 			const line = lines[lineIndex];

	// 			if (line.length === 0) continue;

	// 			if (stopFind == true) {
	// 				break
	// 			}

	// 			// if (line.startsWith('0 ENDLDRDADA')) {

	// 			// 	//console.log('找到ldr结束点，结束')
	// 			// 	break
	// 			// 	// FindLdrEndPlace = true
	// 			// }

	// 			//分析嵌入的文件（组内的零件）
	// 			// if (parsingEmbeddedFiles) {

	// 			// 	// if(FindLdrEndPlace = true){

	// 			// 	// 	continue

	// 			// 	// }

	// 			// 	// if(){

	// 			// 	// }


	// 			// 	// currentEmbeddedFileName = 

	// 			// 	// if(){

	// 			// 	// }

	// 			// 	//file开头，且结尾不是ldr或者dat ，我们只找组，不找零件
	// 			// 	// if (line.startsWith('0 FILE ')) {
	// 			// 	//子零件或
	// 			// 	// if (line.startsWith('1 ')||line.startsWith('0 FILE ')) {


	// 			// 	// ModelGroup

	// 			// 	// Save previous embedded file in the cache
	// 			// 	// 在缓存中保存以前的嵌入文件
	// 			// 	// this.setData(currentEmbeddedFileName, currentEmbeddedText);
	// 			// 	// if (!(line.endsWith('.ldr') || line.endsWith('.dat'))) {

	// 			// 	//console.log('currentEmbeddedFileName333', currentEmbeddedFileName)
	// 			// 	//console.log('currentEmbeddedText333', currentEmbeddedText)

	// 			// 	// if (FindLdrEndPlace == false) {



	// 			// 	if (!(currentEmbeddedFileName.endsWith('.ldr') || currentEmbeddedFileName.endsWith('.dat'))) {

	// 			// 		// const childrenGroup = new Group()
	// 			// 		var childrenGROUP
	// 			// 		// nowGroup.add(childrenGroup)

	// 			// 		//console.log('parseMainTxt传入参数11', currentEmbeddedText, currentEmbeddedFileName, childrenGROUP)
	// 			// 		//加个0 file 方便子级解析

	// 			// 		if (!currentEmbeddedText.startsWith('0 FILE')) {

	// 			// 			currentEmbeddedText = '0 FILE ' + currentEmbeddedFileName + '\n' + currentEmbeddedText

	// 			// 		}



	// 			// 		//console.log('currentEmbeddedText22', currentEmbeddedText)




	// 			// 		//根据文件名称在本级的下级找到这个3D对象NowGroup
	// 			// 		for (let o = 0; o < NowGroup.children.length; o++) {

	// 			// 			if (NowGroup.children[o].name == currentEmbeddedFileName) {

	// 			// 				childrenGROUP = NowGroup.children[o]

	// 			// 				break

	// 			// 			}

	// 			// 		}



	// 			// 		//如果没找到，则不管大小写再找一遍（有的情况下作者会将组不分大小写）
	// 			// 		if (!childrenGROUP) {

	// 			// 			for (let o = 0; o < NowGroup.children.length; o++) {

	// 			// 				if (NowGroup.children[o].name.toLowerCase() == currentEmbeddedFileName.toLowerCase()) {

	// 			// 					childrenGROUP = NowGroup.children[o]

	// 			// 					break

	// 			// 				}

	// 			// 			}



	// 			// 		}

	// 			// 		//console.log('NowGroup4444', NowGroup)
	// 			// 		//console.log('2222childrenGROUP', childrenGROUP, currentEmbeddedFileName)

	// 			// 		if (!childrenGROUP) {

	// 			// 			//console.log('缺失零件11113333', currentEmbeddedFileName)

	// 			// 		}

	// 			// 		//console.log('currentEmbeddedText555', currentEmbeddedText)



	// 			// 		//console.log('workNUm++', workNUm++)

	// 			// 		//第一次解析的是主模型内的子级零件数据
	// 			// 		// ||workNUm == 2
	// 			// 		// if(workNUm == 1){

	// 			// 		// 	parseMainTxt(currentEmbeddedText, nowGroup)

	// 			// 		// }else{
	// 			// 		if (workNUm > 1) {
	// 			// 			//console.log('workNUm444', workNUm)
	// 			// 			//console.log('currentEmbeddedText, childrenGROUP', currentEmbeddedText, childrenGROUP)
	// 			// 			parseMainTxt(currentEmbeddedText, childrenGROUP)
	// 			// 		}

	// 			// 		workNUm++


	// 			// 		// }

	// 			// 		// }





	// 			// 		// New embedded text file
	// 			// 		// currentEmbeddedFileName = line.substring(7);

	// 			// 	}



	// 			// 	// }

	// 			// 	parsingEmbeddedFiles = false

	// 			// 	currentEmbeddedText = '';

	// 			// 	continue;

	// 			// } else {

	// 			// 	currentEmbeddedText += line + '\n';

	// 			// }


	// 			currentEmbeddedText += line + '\n';

	// 			const lp = new LineParser(line, lineIndex + 1);

	// 			lp.seekNonSpace();

	// 			if (lp.isAtTheEnd()) {

	// 				// Empty line
	// 				continue;

	// 			}

	// 			// Parse the line type
	// 			const lineType = lp.getToken();

	// 			// let material;
	// 			let colorCode;
	// 			// let segment;
	// 			// let ccw;
	// 			// let doubleSided;
	// 			// let v0, v1, v2, v3, c0, c1;

	// 			switch (lineType) {

	// 				// Line type 0: Comment or META
	// 				case '0':

	// 					// Parse meta directive
	// 					const meta = lp.getToken();

	// 					if (meta) {

	// 						switch (meta) {

	// 							case '!LDRAW_ORG':

	// 								type = lp.getToken();
	// 								break;

	// 							case '!COLOUR':

	// 								// material =  loader.parseColorMetaDirective(lp);

	// 								material = lp.getToken();

	// 								// const name_material = lp.getToken();

	// 								// if (material) {

	// 								// 	materials[material.userData.code] = material;

	// 								// } else {

	// 								// 	console.warn('LDrawLoader: Error parsing material' + lp.getLineNumberString());

	// 								// }

	// 								break;

	// 							case '!CATEGORY':

	// 								category = lp.getToken();
	// 								break;

	// 							case '!KEYWORDS':

	// 								const newKeywords = lp.getRemainingString().split(',');
	// 								if (newKeywords.length > 0) {

	// 									if (!keywords) {

	// 										keywords = [];

	// 									}

	// 									newKeywords.forEach(function (keyword) {

	// 										keywords.push(keyword.trim());

	// 									});

	// 								}

	// 								break;

	// 							case 'FILE':



	// 								if (lineIndex > 0) {

	// 									//不找零件了，这个函数的功能只找组


	// 									// currentEmbeddedFileName = lp.getRemainingString();
	// 									// Start embedded text files parsing

	// 									startFindGroupLine = true

	// 									if (currentEmbeddedFileName == '') {
	// 										currentEmbeddedFileName = lp.getRemainingString();
	// 									}

	// 									//console.log('名称txt', currentEmbeddedFileName)

	// 									currentEmbeddedText = '';

	// 									// bfcCertified = false;
	// 									// bfcCCW = true;


	// 									// }


	// 								}

	// 								break;

	// 							case 'NOFILE':

	// 								// if (lineIndex > 0) {

	// 								//不找零件了，这个函数的功能只找组

	// 								// if(){}
	// 								//console.log('line_i-1', lines[lineIndex - 1])
	// 								//console.log('line_i', lines[lineIndex])
	// 								//console.log('line_i+1', lines[lineIndex + 1])

	// 								//console.log('currentEmbeddedFileName888', currentEmbeddedFileName)

	// 								if (startFindGroupLine == false) {
	// 									currentEmbeddedFileName = ''
	// 									break

	// 								}


	// 								// lines[lineIndex]



	// 								// Start embedded text files parsing
	// 								// parsingEmbeddedFiles = true;
	// 								// currentEmbeddedFileName = ''
	// 								// currentEmbeddedFileName = lp.getRemainingString();
	// 								// currentEmbeddedText = '';

	// 								// bfcCertified = false;
	// 								// bfcCCW = true;


	// 								// }


	// 								// }


	// 								//分析嵌入的文件（组内的零件）
	// 								// if (parsingEmbeddedFiles) {


	// 								//console.log('currentEmbeddedFileName333', currentEmbeddedFileName)
	// 								//console.log('currentEmbeddedText333', currentEmbeddedText)

	// 								if (!(currentEmbeddedFileName.endsWith('.ldr') || currentEmbeddedFileName.endsWith('.dat'))) {

	// 									// const childrenGroup = new Group()
	// 									var childrenGROUP = null
	// 									// nowGroup.add(childrenGroup)

	// 									//console.log('parseMainTxt传入参数11', currentEmbeddedText, currentEmbeddedFileName, childrenGROUP)
	// 									//加个0 file 方便子级解析

	// 									if (!currentEmbeddedText.startsWith('0 FILE')) {

	// 										currentEmbeddedText = '0 FILE ' + currentEmbeddedFileName + '\n' + currentEmbeddedText

	// 									}



	// 									//console.log('currentEmbeddedText22', currentEmbeddedText)




	// 									//根据文件名称在本级的下级找到这个3D对象NowGroup
	// 									for (let o = 0; o < NowGroup.children.length; o++) {

	// 										if (NowGroup.children[o].name == currentEmbeddedFileName) {

	// 											childrenGROUP = NowGroup.children[o]

	// 											break

	// 										}

	// 									}



	// 									//如果没找到，则不管大小写再找一遍（有的情况下作者会将组不分大小写）
	// 									if (childrenGROUP == null) {

	// 										for (let o = 0; o < NowGroup.children.length; o++) {

	// 											if (NowGroup.children[o].name.toLowerCase() == currentEmbeddedFileName.toLowerCase()) {

	// 												childrenGROUP = NowGroup.children[o]

	// 												break

	// 											}

	// 										}



	// 									}

	// 									//console.log('NowGroup4444', NowGroup)
	// 									//console.log('2222childrenGROUP', childrenGROUP, currentEmbeddedFileName)

	// 									if (!childrenGROUP) {

	// 										//console.log('缺失零件11113333', currentEmbeddedFileName)

	// 										//这样肯定不对，先调试
	// 										break
	// 									}

	// 									//console.log('currentEmbeddedText555', currentEmbeddedText)



	// 									//console.log('workNUm++', workNUm++)

	// 									if (childrenGROUP.children.length > 0) {

	// 										//说明这个组已经添加过零件了
	// 										currentEmbeddedFileName = ''
	// 										break
	// 										// axlef copy
	// 									}


	// 									// if (workNUm > 0) {
	// 									//console.log('workNUm444', workNUm)
	// 									//console.log('currentEmbeddedFileName3344', currentEmbeddedFileName)
	// 									//console.log('currentEmbeddedText, childrenGROUP', currentEmbeddedText, childrenGROUP)
	// 									parseMainTxt(currentEmbeddedText, childrenGROUP)
	// 									// }

	// 									// workNUm++

	// 									// }

	// 									// }

	// 									parsingEmbeddedFiles = false

	// 									currentEmbeddedFileName = ''

	// 									currentEmbeddedText = '';

	// 									// stopFind = true

	// 									// continue;

	// 								}

	// 								currentEmbeddedFileName = ''

	// 								break;

	// 							// case 'NOFILE':

	// 							// 	parsingEmbeddedFiles = false

	// 							// 	break

	// 							// case 'BFC':

	// 							// 	// Changes to the backface culling state
	// 							// 	while (!lp.isAtTheEnd()) {

	// 							// 		const token = lp.getToken();

	// 							// 		switch (token) {

	// 							// 			case 'CERTIFY':
	// 							// 			case 'NOCERTIFY':

	// 							// 				bfcCertified = token === 'CERTIFY';
	// 							// 				bfcCCW = true;

	// 							// 				break;

	// 							// 			case 'CW':
	// 							// 			case 'CCW':

	// 							// 				bfcCCW = token === 'CCW';

	// 							// 				break;

	// 							// 			case 'INVERTNEXT':

	// 							// 				bfcInverted = true;

	// 							// 				break;

	// 							// 			case 'CLIP':
	// 							// 			case 'NOCLIP':

	// 							// 				bfcCull = token === 'CLIP';

	// 							// 				break;

	// 							// 			default:

	// 							// 				console.warn('THREE.LDrawLoader: BFC directive "' + token + '" is unknown.');

	// 							// 				break;

	// 							// 		}

	// 							// 	}

	// 							// 	break;

	// 							case 'STEP':

	// 								startingBuildingStep = true;

	// 								break;

	// 							// case 'Author:':

	// 							case 'ENDLDRDADA':

	// 								//结束查找
	// 								stopFind = true

	// 								// if (line.startsWith('0 ENDLDRDADA')) {

	// 								// 	//console.log('找到ldr结束点，结束')
	// 								// 	break
	// 								// 	// FindLdrEndPlace = true
	// 								// }



	// 								// 	author = lp.getToken();

	// 								break;


	// 							case 'DADAStepAnimation':


	// 								let stepAnimation_i = lp.getToken();

	// 								stepAnimationInfo.push(stepAnimation_i)

	// 							// partAnimation


	// 							//相机动画数据
	// 							case 'DADACameraAnimation':


	// 								let cameraAnimation_i = lp.getToken();

	// 								if (cameraAnimation_i.length > 0) {

	// 									CameraAnimationArr.push(cameraAnimation_i)

	// 								}


	// 							//模型临时旋转参数
	// 							case 'DADAModelStepRotateData':


	// 								ModelRotate = lp.getToken();


	// 							//零件在步骤中的可见性
	// 							case 'DADAPartVisibleData':


	// 								PartVisible = lp.getToken();


	// 							// modelRotate = 

	// 							// if(cameraAnimation_i.length>0){

	// 							// 	CameraAnimationArr.push(cameraAnimation_i)

	// 							// }

	// 							default:
	// 								// Other meta directives are not implemented

	// 								break;

	// 						}

	// 					}

	// 					break;

	// 				// Line type 1: Sub-object file
	// 				case '1':


	// 					//查找组的时候不需要找零件（添加零件）
	// 					if (startFindGroupLine == true) {
	// 						// currentEmbeddedFileName = ''
	// 						break

	// 					}

	// 					colorCode = lp.getToken();
	// 					// material = getLocalMaterial(colorCode);

	// 					const posX = parseFloat(lp.getToken());
	// 					const posY = parseFloat(lp.getToken());
	// 					const posZ = parseFloat(lp.getToken());
	// 					const m0 = parseFloat(lp.getToken());
	// 					const m1 = parseFloat(lp.getToken());
	// 					const m2 = parseFloat(lp.getToken());
	// 					const m3 = parseFloat(lp.getToken());
	// 					const m4 = parseFloat(lp.getToken());
	// 					const m5 = parseFloat(lp.getToken());
	// 					const m6 = parseFloat(lp.getToken());
	// 					const m7 = parseFloat(lp.getToken());
	// 					const m8 = parseFloat(lp.getToken());

	// 					const matrix = new Matrix4().set(
	// 						m0, m1, m2, posX,
	// 						m3, m4, m5, posY,
	// 						m6, m7, m8, posZ,
	// 						0, 0, 0, 1
	// 					);
	// 					// //console.log('matrix3333',matrix)

	// 					let fileName = lp.getRemainingString().trim().replace(/\\/g, '/');

	// 					// if (loader.fileMap[fileName]) {

	// 					// 	// Found the subobject path in the preloaded file path map
	// 					// 	fileName = loader.fileMap[fileName];

	// 					// } else {

	// 					// Standardized subfolders
	// 					if (fileName.startsWith('s/')) {

	// 						fileName = 'parts/' + fileName;

	// 					} else if (fileName.startsWith('48/')) {

	// 						fileName = 'p/' + fileName;

	// 					}

	// 					// }

	// 					const newGroup_ = new Group()

	// 					nowGroup.add(newGroup_)

	// 					//console.log('新建结构零件', newGroup_)

	// 					newGroup_.name = fileName

	// 					newGroup_.applyMatrix4(matrix.clone())

	// 					if (!(fileName.endsWith('.ldr') || fileName.endsWith('.dat'))) {

	// 						//console.log('保存一个组', fileName, matrix)

	// 					}

	// 					// newGroup_.material = colorCode,

	// 					newGroup_.userData = {
	// 						type,
	// 						colorCode: colorCode,
	// 						matrix: matrix.clone(),
	// 						fileName: fileName,
	// 						// inverted: bfcInverted,
	// 						startingBuildingStep: startingBuildingStep,
	// 						stepAnimationInfo,
	// 						CameraAnimationArr,
	// 						ModelRotate,
	// 						PartVisible
	// 					}

	// 					//console.log('newGroup_65554455', newGroup_, nowGroup)



	// 					// if(!(fileName.endsWith('.dat')||fileName.endsWith('.ldr'))){

	// 					// childrenGROUP = newGroup_
	// 					// //console.log('零件数据子级更细', childrenGROUP.name)
	// 					// }

	// 					// nowGroup = newGroup


	// 					// subobjects.push({
	// 					// 	// material: material,
	// 					// 	// colorCode: name_material,
	// 					// 	colorCode:colorCode,
	// 					// 	matrix: matrix,
	// 					// 	fileName: fileName,
	// 					// 	// inverted: bfcInverted,
	// 					// 	startingBuildingStep: startingBuildingStep,
	// 					// 	stepAnimationInfo,
	// 					// 	CameraAnimationArr,
	// 					// 	ModelRotate,
	// 					// 	PartVisible
	// 					// });

	// 					stepAnimationInfo = []
	// 					CameraAnimationArr = []
	// 					ModelRotate = null
	// 					PartVisible = null
	// 					// CameraAnimationArr = []
	// 					startingBuildingStep = false;
	// 					// bfcInverted = false;
	// 					// stepAnimationInfo = []

	// 					break;

	// 				// Line type 2: Line segment
	// 				case '2':

	// 					// 	colorCode = lp.getToken();
	// 					// 	material = getLocalMaterial(colorCode);
	// 					// 	v0 = lp.getVector();
	// 					// 	v1 = lp.getVector();

	// 					// 	segment = {
	// 					// 		material: material,
	// 					// 		colorCode: colorCode,
	// 					// 		vertices: [v0, v1],
	// 					// 	};

	// 					// 	lineSegments.push(segment);

	// 					break;

	// 				// Line type 5: Conditional Line segment
	// 				case '5':

	// 					// 	colorCode = lp.getToken();
	// 					// 	material = getLocalMaterial(colorCode);
	// 					// 	v0 = lp.getVector();
	// 					// 	v1 = lp.getVector();
	// 					// 	c0 = lp.getVector();
	// 					// 	c1 = lp.getVector();

	// 					// 	segment = {
	// 					// 		material: material,
	// 					// 		colorCode: colorCode,
	// 					// 		vertices: [v0, v1],
	// 					// 		controlPoints: [c0, c1],
	// 					// 	};

	// 					// 	conditionalSegments.push(segment);

	// 					break;

	// 				// Line type 3: Triangle
	// 				case '3':

	// 					// 	colorCode = lp.getToken();
	// 					// 	material = getLocalMaterial(colorCode);
	// 					// 	ccw = bfcCCW;
	// 					// 	doubleSided = !bfcCertified || !bfcCull;

	// 					// 	if (ccw === true) {

	// 					// 		v0 = lp.getVector();
	// 					// 		v1 = lp.getVector();
	// 					// 		v2 = lp.getVector();

	// 					// 	} else {

	// 					// 		v2 = lp.getVector();
	// 					// 		v1 = lp.getVector();
	// 					// 		v0 = lp.getVector();

	// 					// 	}

	// 					// 	faces.push({
	// 					// 		material: material,
	// 					// 		colorCode: colorCode,
	// 					// 		faceNormal: null,
	// 					// 		vertices: [v0, v1, v2],
	// 					// 		normals: [null, null, null],
	// 					// 	});
	// 					// 	totalFaces++;

	// 					// 	if (doubleSided === true) {

	// 					// 		faces.push({
	// 					// 			material: material,
	// 					// 			colorCode: colorCode,
	// 					// 			faceNormal: null,
	// 					// 			vertices: [v2, v1, v0],
	// 					// 			normals: [null, null, null],
	// 					// 		});
	// 					// 		totalFaces++;

	// 					// 	}

	// 					break;

	// 				// Line type 4: Quadrilateral
	// 				case '4':

	// 					// 	colorCode = lp.getToken();
	// 					// 	material = getLocalMaterial(colorCode);
	// 					// 	ccw = bfcCCW;
	// 					// 	doubleSided = !bfcCertified || !bfcCull;

	// 					// 	if (ccw === true) {

	// 					// 		v0 = lp.getVector();
	// 					// 		v1 = lp.getVector();
	// 					// 		v2 = lp.getVector();
	// 					// 		v3 = lp.getVector();

	// 					// 	} else {

	// 					// 		v3 = lp.getVector();
	// 					// 		v2 = lp.getVector();
	// 					// 		v1 = lp.getVector();
	// 					// 		v0 = lp.getVector();

	// 					// 	}

	// 					// 	// specifically place the triangle diagonal in the v0 and v1 slots so we can
	// 					// 	// account for the doubling of vertices later when smoothing normals.
	// 					// 	faces.push({
	// 					// 		material: material,
	// 					// 		colorCode: colorCode,
	// 					// 		faceNormal: null,
	// 					// 		vertices: [v0, v1, v2, v3],
	// 					// 		normals: [null, null, null, null],
	// 					// 	});
	// 					// 	totalFaces += 2;

	// 					// 	if (doubleSided === true) {

	// 					// 		faces.push({
	// 					// 			material: material,
	// 					// 			colorCode: colorCode,
	// 					// 			faceNormal: null,
	// 					// 			vertices: [v3, v2, v1, v0],
	// 					// 			normals: [null, null, null, null],
	// 					// 		});
	// 					// 		totalFaces += 2;

	// 					// 	}

	// 					break;

	// 				default:
	// 					throw new Error('LDrawLoader: Unknown line type "' + lineType + '"' + lp.getLineNumberString() + '.');

	// 			}

	// 		}

	// 		// if (parsingEmbeddedFiles) {

	// 		// 	if (!(currentEmbeddedFileName.endsWith('.ldr') || currentEmbeddedFileName.endsWith('.dat'))) {

	// 		// 		const childrenGroup = new Group()

	// 		// 		nowGroup.add(childrenGroup)

	// 		// 		//console.log('parseMainTxt传入参数33', textmain, currentEmbeddedFileName, childrenGroup)

	// 		// 		parseMainTxt(textmain, currentEmbeddedFileName, childrenGroup)

	// 		// 		// this.setData(currentEmbeddedFileName, currentEmbeddedText);
	// 		// 		// if (line.startsWith('0 FILE ') && !(line.endsWith('.ldr') || line.endsWith('.dat'))) {

	// 		// 		// 	// ModelGroup

	// 		// 		// 	// Save previous embedded file in the cache
	// 		// 		// 	// 在缓存中保存以前的嵌入文件
	// 		// 		// 	// this.setData(currentEmbeddedFileName, currentEmbeddedText);

	// 		// 		// 	const childrenGroup = new Group()

	// 		// 		// 	nowGroup.add(childrenGroup)

	// 		// 		// 	parseMainTxt(textmain, currentEmbeddedFileName, childrenGroup)


	// 		// 		// 	// New embedded text file
	// 		// 		// 	currentEmbeddedFileName = line.substring(7);
	// 		// 		// 	currentEmbeddedText = '';

	// 		// 		// }

	// 		// 	}

	// 		// }

	// 		// if (!(currentEmbeddedFileName.endsWith('.ldr') || currentEmbeddedFileName.endsWith('.dat'))) {

	// 		// 	const childrenGroup = new Group()

	// 		// 	nowGroup.add(childrenGroup)

	// 		// 	parseMainTxt(textmain, currentEmbeddedFileName, childrenGroup)

	// 		// }

	// 		// if (stepAnimationInfo) {

	// 		// 	//console.log('stepAnimationInfo', stepAnimationInfo)


	// 		// }

	// 		// return {
	// 		// 	faces,
	// 		// 	conditionalSegments,
	// 		// 	lineSegments,
	// 		// 	type,
	// 		// 	// stepAnimationInfo,
	// 		// 	// CameraAnimationArr,
	// 		// 	category,
	// 		// 	keywords,
	// 		// 	author,
	// 		// 	subobjects,
	// 		// 	totalFaces,
	// 		// 	startingBuildingStep,
	// 		// 	// materials,
	// 		// 	fileName,
	// 		// 	group: null
	// 		// };

	// 	}


	// 	parseMainTxt(textmain, NowGroup)


	// 	//console.log('workNUm333', workNUm)



	// 	//console.log('GrouP5555', GrouP)

	// 	return GrouP


	// }


	//接下来将没有零件的组通过克隆的方式生成出来
	cloneAllPartToGroup(Model_No3DpartGroup) {



		//console.log('Model_No3DpartGroup', Model_No3DpartGroup)
		const that = this

		var newThreeGroup_MODEL = new Group()

		const FindChildrenAndCloneDo = function (GROUP, currentNewGroup_) {

			//console.log('调用函数的参数是', GROUP, currentNewGroup_)

			var currentNewGroup = currentNewGroup_



			for (let i = 0; i < GROUP.children.length; i++) {


				if (GROUP.children[i].name.endsWith('.ldr') || GROUP.children[i].name.endsWith('.dat')) {

					//克隆零件

					const library_part = that.findObjByName(GROUP.children[i].name)


					var NewPart

					if (!library_part) {

						//缺失零件

						NewPart = missingPart_Group.clone()

						console.log('missingPart_Group', missingPart_Group)


						//替换物
						NewPart.userData.MissingReplacement = true

						NewPart.userData.colorCode = ['4'];

						console.log('NewPart333', NewPart)
						// missingPart_Group.userData.colorCode = ['4'];

						// missingPart_Group.material.userData.colorCode = ['4'];


						console.log('克隆时缺失零件', library_part, GROUP.children[i].name, GROUP.children[i])

						if (missingPartsArr.indexOf(GROUP.children[i].name) == -1) {

							missingPartsArr.push(GROUP.children[i].name)

						}


						// continue

					} else {



						// const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
						// const material = lDrawLoader.materialLibrary['4']

						// const cube = new Mesh(geometry, material);





						NewPart = library_part.clone()

					}


					// var NewPart = library_part.clone()

					currentNewGroup.add(NewPart)


					NewPart.name = GROUP.children[i].name


					NewPart.position.copy(GROUP.children[i].position.clone())

					NewPart.quaternion.copy(GROUP.children[i].quaternion.clone())


					//加入缺失零件替身的标志
					if (NewPart.userData.MissingReplacement) {

						GROUP.children[i].userData.MissingReplacement = true
						GROUP.children[i].userData.backupColorCode = '4';
						GROUP.children[i].userData.colorCode = '4';

					}


					NewPart.userData = JSON.parse(JSON.stringify(GROUP.children[i].userData));



					console.log('NewPart33444333', NewPart)

					//设置颜色
					that.changeColorByLdrawRule(NewPart, GROUP.children[i].userData.colorCode, that.materialLibrary)

				} else {
					//克隆组

					var NewGroup_GROUP = new Group()

					NewGroup_GROUP.name = GROUP.children[i].name

					currentNewGroup.add(NewGroup_GROUP)

					NewGroup_GROUP.position.copy(GROUP.children[i].position.clone())
					NewGroup_GROUP.quaternion.copy(GROUP.children[i].quaternion.clone())

					NewGroup_GROUP.userData = JSON.parse(JSON.stringify(GROUP.children[i].userData));

					FindChildrenAndCloneDo(GROUP.children[i], NewGroup_GROUP)

				}
			}
		}

		FindChildrenAndCloneDo(Model_No3DpartGroup, newThreeGroup_MODEL)

		return newThreeGroup_MODEL

	}

	/**
	* 传入零件后，递归修改零件的颜色，如果遇到16的组才进去修改，不是16的组不修改（子孙也不修改）
	*/
	changeColorByLdrawRule(Part, ColorNum_string, materialLibrary) {

		//条件线段现在不能显示，我还没想好怎么处理条件线段,我要移除
		var ConditionalLineSegmentsArr = []

		const setMaterials = function (GROUP, upperColour_ = null) {

			//console.log('设置颜色主', upperColour_)

			var upperColour = upperColour_


			for (let index = 0; index < GROUP.children.length; index++) {
				const i_obj = GROUP.children[index]

				// if (i_obj.userData.colorCode) {
				if (i_obj.isLine) {


					//条件线段不能上色
					// if (!i_obj.isConditionalLine) {






					// } else {
					//零件的线应该是什么颜色？以后还得升级，可以尝试读取线父级的材质的rgb值再决定
					if (i_obj.parent.userData.colorCode == '0' || i_obj.parent.userData.colorCode == '256') {

						// 	//有优化空间
						// //console.log('material_flotArr', material_flotArr)
						i_obj.material = material_flotArr[8]

						// i_obj.parent.userData.colorCode = '8'

					} else {

						i_obj.material = material_flotArr[0]

						// i_obj.parent.userData.colorCode = '0'

					}

					//条件线段现在不能显示，我还没想好怎么处理条件线段
					if (i_obj.isConditionalLine) {

						// i_obj.visible = false

						ConditionalLineSegmentsArr.push(i_obj)



					}
					// }


					// continue

					// i_obj.isLine

				} else if (i_obj.isMesh) {

					//console.log('设置材质333', i_obj, materialLibrary, materialLibrary[0])
					//console.log('i_obj.material', i_obj.material)
					//console.log('!i_obj.material', !i_obj.material)
					//console.log(upperColour, i_obj.material)

					if (!i_obj.material) {

						console.error('没有材质文件', upperColour)

						i_obj.material = materialLibrary[upperColour]


						continue
					}

					//组形式的材质
					if (Array.isArray(i_obj.material)) {

						//  if (i_obj.userData.colorCode.length >= 1) {

						// i_obj.material = i_obj.material.forEach(function (colourValue) {

						// 	if (colourValue == '16') {

						// 		materialLibrary[upperColour]


						// 	} else {

						// 		materialLibrary[colourValue]

						// 	}
						// })

						//颜色数据保存
						i_obj.userData.colorCode = i_obj.material.map(function (colourValue) {
							return colourValue
						});

						//颜色数据保存
						// i_obj.userData
						i_obj.userData.backupColorCode = i_obj.material.map(function (colourValue) {
							return colourValue
						});


						i_obj.material = i_obj.material.map(function (colourValue) {
							if (colourValue == '16') {
								return materialLibrary[upperColour];
							} else {
								return materialLibrary[colourValue];
							}
						});




						// }

					} else {
						//普通形式的材质

						if (i_obj.material == '16') {

							console.log('颜色16', i_obj)

							i_obj.userData.colorCode = '16'
							i_obj.material = materialLibrary[upperColour]


						} else {

							console.log('i_obj.userData.colorCode', i_obj.material, i_obj.userData.colorCode)

							i_obj.userData.colorCode = i_obj.material
							i_obj.material = i_obj.material.isMaterial ? i_obj.material : materialLibrary[i_obj.material]


						}

					}


					// if (i_obj.userData.colorCode == ['16']) {

					// 	i_obj.material = materialLibrary[upperColour]

					// } else if (i_obj.userData.colorCode != null) {

					// 	// if(Array.isArray(i_obj.userData.colorCode)){

					// 	if (i_obj.userData.colorCode.length == 1) {

					// 		i_obj.material = materialLibrary[i_obj.userData.colorCode[0]]

					// 	} else if (i_obj.userData.colorCode.length >= 1) {

					// 		i_obj.material = i_obj.userData.colorCode.forEach(function (colourValue) { materialLibrary[colourValue] })

					// 	}

					// 	// }



					// }

					// i_obj.material = upperColour ? materialLibrary[upperColour] : materialLibrary[i_obj.userData.colorCode]




				} else if (i_obj.isGroup) {

					if (i_obj.userData.colorCode) {


						if (i_obj.userData.colorCode != '16') {


							setMaterials(i_obj, i_obj.userData.colorCode)

							// if(i_obj.material){

							// }

						} else if (i_obj.userData.colorCode == '16') {

							// i_obj.userData.colorCode


							setMaterials(i_obj, upperColour)

						}
					}

				}



				// }

				// if (i_obj.name.endsWith('.ldr') || i_obj.name.endsWith('.dat')) {
				// 	//零件



				// }


			}



		}


		setMaterials(Part, ColorNum_string)


		//目前不知道条件线段如何处理，所以我全部移除
		for (let i = 0; i < ConditionalLineSegmentsArr.length; i++) {

			ConditionalLineSegmentsArr[i].removeFromParent()

		}





		// Group
		// for (let index = 0; index < Group.children.length; index++) {
		// 	const element = Group.children[index];

		// 	//console.log('element5666', element)

		// 	//组就继续往下找
		// 	if (element.isGroup) {

		// 		//16的颜色才进去修改 
		// 		//这是组
		// 		//继续找children，直到不是组
		// 		if (element.userData.backupColorCode == '16') {

		// 			//console.log('颜色16可以查找修改', element)

		// 			//设置零件的主颜色
		// 			element.userData.colorCode = this.copyArray(ColorNum_string)

		// 			this.changeColorByLdrawRule(element, ColorNum_string)

		// 		} else if (element.userData.TYPE != null && element.userData.TYPE == 'GROUP') {
		// 			//不是16 ，如果这是组 TYPE：GROUP 也往下找

		// 			//console.log('修改颜色，这是组', element)

		// 			element.userData.colorCode = this.copyArray(ColorNum_string)
		// 			this.changeColorByLdrawRule(element, ColorNum_string)

		// 		} else if (element.userData.TYPE != null && element.userData.TYPE == 'PART') {

		// 			//console.log('修改颜色，这是零件PART', element)

		// 			element.userData.colorCode = this.copyArray(ColorNum_string)
		// 			this.changeColorByLdrawRule(element, ColorNum_string)

		// 		}

		// 	} else if (element.isMesh) {
		// 		//准备修改颜色

		// 		if (element.isMesh) {

		// 			//修改颜色
		// 			if (element.userData.colorCode) {

		// 				// element.material = lDrawLoader.materialLibrary[color]; 
		// 				//多种材质
		// 				if (Array.isArray(element.userData.colorCode) && element.userData.colorCode.length > 1) {


		// 					for (let k = 0; k < element.userData.colorCode.length; k++) {

		// 						//只修改能修改的颜色
		// 						if (element.userData.LdrawColorEditable != null && element.userData.LdrawColorEditable[k] != null && element.userData.LdrawColorEditable[k] == true) {
		// 							// element.userData.colorCode[k]
		// 							// lDrawLoader.materialLibrary[colorcode * 1];

		// 							element.material[k] = lDrawLoader.materialLibrary[ColorNum_string];
		// 							element.userData.colorCode[k] = ColorNum_string

		// 						} else if (element.userData.LdrawColorEditable != null && element.userData.LdrawColorEditable[k] != null && element.userData.LdrawColorEditable[k] == false) {

		// 							//不能修改的恢复原色，这一步看似多余，但有可能会遇到不小心改变了颜色的情况下很有用
		// 							element.material[k] = lDrawLoader.materialLibrary[element.userData.backupColorCode[k]];

		// 						}

		// 					}

		// 				} else if (Array.isArray(element.userData.colorCode) && element.userData.colorCode.length == 1) {

		// 					//假如只有一种材质
		// 					if (element.userData.LdrawColorEditable != null && element.userData.LdrawColorEditable[0] != null && element.userData.LdrawColorEditable[0] == true) {
		// 						element.material = lDrawLoader.materialLibrary[ColorNum_string];
		// 						element.userData.colorCode = [ColorNum_string]


		// 					}

		// 				}


		// 			}

		// 		}

		// 	}
		// }
	}

	clearOBJ() {
		if (THREE_D_Parts_Arr) {
			THREE_D_Parts_Arr.length = 0
			// THREE_D_Parts_Arr = null

		}

		mpdText = ''
	}

	findObjByName(name) {
		//console.log('查找模型传入的参数是,', THREE_D_Parts_Arr, name)

		var findONJ = null

		for (let i = 0; i < THREE_D_Parts_Arr.length; i++) {

			if (THREE_D_Parts_Arr[i].name == name) {

				findONJ = THREE_D_Parts_Arr[i]

				break

			}

			// name

		}

		//没找到就忽略大小写查找

		if (findONJ == null) {

			for (let i = 0; i < THREE_D_Parts_Arr.length; i++) {

				if (THREE_D_Parts_Arr[i].name.toLowerCase() == name.toLowerCase()) {

					findONJ = THREE_D_Parts_Arr[i]

					break

				}

				// name

			}

		}


		return findONJ

	}


	GreateAllTextByTxtInfo(partname) {
		var ThisPartTxtAll = ''

		//找到零件数据

		//未找齐零件数据
		var notFindPart = false

		var missingPartName



		const findSubPartsInfo = function (partName) {


			var partInfo = null

			// 缺零件数据未找齐，不用找了，这个零件找不齐了
			if (notFindPart == true) {

				return

			}

			//console.log('txt_partArr4444', txt_partArr)

			for (let i = 1; i < txt_partArr.length; i++) {

				if (txt_partArr[i].name == partName) {

					ThisPartTxtAll += txt_partArr[i].partTxt + '\n'

					partInfo = txt_partArr[i]

					break

				}

			}


			//没找到,不区分大小写再找一遍
			if (!partInfo) {

				for (let i = 1; i < txt_partArr.length; i++) {

					if (txt_partArr[i].name.toLowerCase() == partName.toLowerCase()) {

						ThisPartTxtAll += txt_partArr[i].partTxt + '\n'

						partInfo = txt_partArr[i]

						break

					}

				}

			}

			if (!partInfo) {
				console.log('没找到零件信息', partName)
				console.log('txt_partArr33', txt_partArr)
				missingPartName = partName
				notFindPart = true
				return
			}


			console.log('找到part4444', partInfo)

			if (partInfo.needSubPartsArr) {

				for (let j = 0; j < partInfo.needSubPartsArr.length; j++) {


					//console.log('subTex', partInfo, partInfo.needSubPartsArr[j])
					var subTex = findSubPartsInfo(partInfo.needSubPartsArr[j])

					if (subTex) {

						ThisPartTxtAll += subTex + '\n'

					}
				}
			}




		}

		findSubPartsInfo(partname)


		//未找齐就返回空，否则返回数据
		if (notFindPart == true) {

			return {
				res: 'missing_part',
				data: missingPartName
			}

		} else {

			return {
				res: 'success',
				data: ThisPartTxtAll
			}

		}

		// return notFindPart ? null : ThisPartTxtAll





		// for (let i = 0; i < partInfo.needSubPartsArr.length; i++) {


		// }
	}

	parse(text, onLoad) {

		this.partsCache
			.parseModel(text, this.materialLibrary)
			.then(group => {

				this.applyMaterialsToMesh(group, MAIN_COLOUR_CODE, this.materialLibrary, true);
				this.computeBuildingSteps(group);
				group.userData.fileName = '';
				onLoad(group);

			});

	}

	setMaterials(materials) {

		this.materialLibrary = {};
		this.materials = [];
		for (let i = 0, l = materials.length; i < l; i++) {

			this.addMaterial(materials[i]);

		}

		// Add default main triangle and line edge materials (used in pieces that can be colored with a main color)
		//添加默认的主三角形和线条边缘材质（用于可以用主颜色着色的块）
		this.addMaterial(this.parseColorMetaDirective(new LineParser('Main_Colour CODE 16 VALUE #FF8080 EDGE #333333')));
		this.addMaterial(this.parseColorMetaDirective(new LineParser('Edge_Colour CODE 24 VALUE #A0A0A0 EDGE #333333')));
		// this.addMaterial(this.parseColorMetaDirective(new LineParser('Main_Colour CODE 16 VALUE #000000 EDGE #333333')));
		// this.addMaterial(this.parseColorMetaDirective(new LineParser('Edge_Colour CODE 24 VALUE #000000 EDGE #333333')));
		return this;

	}

	setFileMap(fileMap) {

		this.fileMap = fileMap;

		return this;

	}

	addMaterial(material) {

		// Adds a material to the material library which is on top of the parse scopes stack. And also to the materials array

		const matLib = this.materialLibrary;
		if (!matLib[material.userData.code]) {

			this.materials.push(material);
			matLib[material.userData.code] = material;

		}

		return this;

	}

	getMaterial(colorCode) {

		if (colorCode.startsWith('0x2')) {

			// Special 'direct' material value (RGB color)
			const color = colorCode.substring(3);

			return this.parseColorMetaDirective(new LineParser('Direct_Color_' + color + ' CODE -1 VALUE #' + color + ' EDGE #' + color + ''));

		}

		return this.materialLibrary[colorCode] || null;

	}

	// Applies the appropriate materials to a prebuilt hierarchy of geometry. Assumes that color codes are present
	// in the material array if they need to be filled in.
	//将适当的材质应用于预先构建的几何体层次。假设存在颜色代码
	//如果需要填充，则在材料阵列中。

	//32颜色感觉也不能修改 Trans_Black_IR_Lens 比如wedo 运动传感器的玻璃泡
	//LdrawColorEditable 属性是我liuyu加的，目的是我在修改模型颜色的时候可以判断模型颜色能不能被修改 true材质表示能修改颜色，false表示不能修改，不如积木的头可以修改颜色，但眼睛不能修改颜色

	applyMaterialsToMesh(group, parentColorCode, materialHierarchy, finalMaterialPass = false) {

		// find any missing materials as indicated by a color code string and replace it with a material from the current material lib
		const loader = this;
		const parentIsPassthrough = parentColorCode === MAIN_COLOUR_CODE;
		group.traverse(c => {

			if (c.isMesh || c.isLineSegments) {

				if (Array.isArray(c.material)) {

					if (c.userData.LdrawColorEditable == null) { c.userData.LdrawColorEditable = [] }


					for (let i = 0, l = c.material.length; i < l; i++) {

						if (!c.material[i].isMaterial) {
							//16能修改 和 32 都不能修改

							var bool = false

							if (c.material[i] == MAIN_COLOUR_CODE) {
								//能修改
								bool = true
							} else if (c.material[i] == '32') {
								//不能修改
								bool = false
							}


							// //console.log('解析颜色指令参数', c, c.material[i], MAIN_COLOUR_CODE, bool)

							if (c.userData.LdrawColorEditable[i] == null) {
								//liuyu加的，试试
								c.userData.LdrawColorEditable[i] = bool

							}

							c.material[i] = getMaterial(c, c.material[i]);

						}

					}

				} else if (!c.material.isMaterial) {

					if (c.userData.LdrawColorEditable == null) { c.userData.LdrawColorEditable = [] }


					//16能修改 和 32 都不能修改
					// var bool2 = c.material == MAIN_COLOUR_CODE || c.material  == '32'

					var bool2 = false

					if (c.material == MAIN_COLOUR_CODE) {
						//能修改
						bool2 = true
					} else if (c.material == '32') {
						//不能修改
						bool2 = false
					}

					if (c.userData.LdrawColorEditable != null && c.userData.LdrawColorEditable.length == 0) { c.userData.LdrawColorEditable = [bool2] }


					c.material = getMaterial(c, c.material);

				}

			}

		});

		//在主程序中还可以获取文件
		// function getcolorFile(){

		// };


		// Returns the appropriate material for the object (line or face) given color code. If the code is "pass through"
		// (24 for lines, 16 for edges) then the pass through color code is used. If that is also pass through then it's
		// simply returned for the subsequent material application.
		//返回给定颜色代码的对象（线或面）的适当材质。如果代码是“通过”
		//（24表示线条，16表示边缘），则使用直通色代码。如果这也是通过，那么它
		//简单地返回用于随后的材料申请。

		function getMaterial(c, colorCode) {

			// if our parent is a passthrough color code and we don't have the current material color available then
			// return early.

			//如果我们的父对象是一个passthrough颜色代码，而我们没有可用的当前材质颜色，那么
			//早点返回。
			if (parentIsPassthrough && !(colorCode in materialHierarchy) && !finalMaterialPass) {

				return colorCode;

			}

			const forEdge = c.isLineSegments || c.isConditionalLine;
			const isPassthrough = !forEdge && colorCode === MAIN_COLOUR_CODE || forEdge && colorCode === MAIN_EDGE_COLOUR_CODE;
			if (isPassthrough) {

				colorCode = parentColorCode;

			}

			let material = null;
			if (colorCode in materialHierarchy) {

				material = materialHierarchy[colorCode];

			} else if (finalMaterialPass) {

				// see if we can get the final material from from the "getMaterial" function which will attempt to
				// parse the "direct" colors
				material = loader.getMaterial(colorCode);
				if (material === null) {

					// otherwise throw a warning if this is final opportunity to set the material
					console.warn(`LDrawLoader: Material properties for code ${colorCode} not available.`);
					//保存缺失材质
					if (!missingColor.includes(colorCode)) {

						missingColor.push(colorCode)

					}

					// And return the 'missing color' material

					//我推测这里是缺失材质的赋予地方 MeshStandardMaterial,
					material = loader.missingColorMaterial
					// material = new  MeshStandardMaterial ()
					// material.copy(loader.missingColorMaterial)
					material.userData.missingMaterialCode = colorCode

				}


			} else {

				return colorCode;

			}

			if (c.isLineSegments) {

				material = material.userData.edgeMaterial;

				if (c.isConditionalLine) {

					material = material.userData.conditionalEdgeMaterial;

				}

			}

			return material;

		}

	}

	getMainMaterial() {

		return this.getMaterial(MAIN_COLOUR_CODE);

	}

	getMainEdgeMaterial() {

		const mat = this.getMaterial(MAIN_EDGE_COLOUR_CODE);
		return mat ? mat.userData.edgeMaterial : null;

	}

	//parse Color Meta Directive 解析颜色元指令
	parseColorMetaDirective(lineParser) {

		// Parses a color definition and returns a THREE.Material

		//平面材质组
		// material_flotArr = []
		let code = null;

		// Triangle and line colors
		let fillColor = '#FF00FF';
		let edgeColor = '#FF00FF';

		// Transparency
		let alpha = 1;
		let isTransparent = false;
		// Self-illumination:
		let luminance = 0;

		let finishType = FINISH_TYPE_DEFAULT;

		let edgeMaterial = null;

		const name = lineParser.getToken();
		if (!name) {

			throw new Error('LDrawLoader: Material name was expected after "!COLOUR tag' + lineParser.getLineNumberString() + '.');

		}

		// Parse tag tokens and their parameters
		let token = null;
		while (true) {

			token = lineParser.getToken();

			if (!token) {

				break;

			}

			if (!parseLuminance(token)) {

				switch (token.toUpperCase()) {

					case 'CODE':

						code = lineParser.getToken();
						break;

					case 'VALUE':

						fillColor = lineParser.getToken();
						if (fillColor.startsWith('0x')) {

							fillColor = '#' + fillColor.substring(2);

						} else if (!fillColor.startsWith('#')) {

							throw new Error('LDrawLoader: Invalid color while parsing material' + lineParser.getLineNumberString() + '.');

						}

						break;

					case 'EDGE':

						edgeColor = lineParser.getToken();
						if (edgeColor.startsWith('0x')) {

							edgeColor = '#' + edgeColor.substring(2);

						} else if (!edgeColor.startsWith('#')) {

							// Try to see if edge color is a color code
							edgeMaterial = this.getMaterial(edgeColor);
							if (!edgeMaterial) {

								throw new Error('LDrawLoader: Invalid edge color while parsing material' + lineParser.getLineNumberString() + '.');

							}

							// Get the edge material for this triangle material
							edgeMaterial = edgeMaterial.userData.edgeMaterial;

						}

						break;

					case 'ALPHA':

						alpha = parseInt(lineParser.getToken());

						if (isNaN(alpha)) {

							throw new Error('LDrawLoader: Invalid alpha value in material definition' + lineParser.getLineNumberString() + '.');

						}

						alpha = Math.max(0, Math.min(1, alpha / 255));

						if (alpha < 1) {

							isTransparent = true;

						}

						break;

					case 'LUMINANCE':

						if (!parseLuminance(lineParser.getToken())) {

							throw new Error('LDrawLoader: Invalid luminance value in material definition' + LineParser.getLineNumberString() + '.');

						}

						break;

					case 'CHROME':
						finishType = FINISH_TYPE_CHROME;
						break;

					case 'PEARLESCENT':
						finishType = FINISH_TYPE_PEARLESCENT;
						break;

					case 'RUBBER':
						finishType = FINISH_TYPE_RUBBER;
						break;

					case 'MATTE_METALLIC':
						finishType = FINISH_TYPE_MATTE_METALLIC;
						break;

					case 'METAL':
						finishType = FINISH_TYPE_METAL;
						break;

					case 'MATERIAL':
						// Not implemented
						lineParser.setToEnd();
						break;

					default:
						throw new Error('LDrawLoader: Unknown token "' + token + '" while parsing material' + lineParser.getLineNumberString() + '.');

				}

			}

		}

		let material = null;

		let material_flot = null

		switch (finishType) {

			case FINISH_TYPE_DEFAULT:

				material = new MeshStandardMaterial({ roughness: 0.3, metalness: 0 });

				// material_flot = convertMaterial(material);

				break;

			case FINISH_TYPE_PEARLESCENT:

				// Try to imitate pearlescency by making the surface glossy
				material = new MeshStandardMaterial({ roughness: 0.3, metalness: 0.25 });

				// material = new MeshBasicMaterial({ roughness: 0.3, metalness: 0.25 });

				// material_flot = convertMaterial(material);


				break;

			case FINISH_TYPE_CHROME:

				// Mirror finish surface
				material = new MeshStandardMaterial({ roughness: 0, metalness: 1 });
				// material = new MeshBasicMaterial({ roughness: 0, metalness: 1 });

				// material_flot = convertMaterial(material);


				break;

			case FINISH_TYPE_RUBBER:

				// Rubber finish
				material = new MeshStandardMaterial({ roughness: 0.9, metalness: 0 });
				// material = new MeshBasicMaterial({ roughness: 0.9, metalness: 0 });

				// material_flot = convertMaterial(material);

				break;

			case FINISH_TYPE_MATTE_METALLIC:

				// Brushed metal finish
				material = new MeshStandardMaterial({ roughness: 0.8, metalness: 0.4 });

				// material = new MeshBasicMaterial({ roughness: 0.8, metalness: 0.4 });

				// material_flot = convertMaterial(material);

				break;

			case FINISH_TYPE_METAL:

				// Average metal finish
				material = new MeshStandardMaterial({ roughness: 0.2, metalness: 0.85 });

				// material = new MeshBasicMaterial({ roughness: 0.2, metalness: 0.85 });

				// material_flot = convertMaterial(material);


				break;

			default:
				// Should not happen
				break;

		}

		material.color.setStyle(fillColor, COLOR_SPACE_LDRAW);
		material.transparent = isTransparent;
		material.premultipliedAlpha = true;
		material.opacity = alpha;
		material.depthWrite = !isTransparent;

		material.polygonOffset = true;
		material.polygonOffsetFactor = 1;

		if (luminance !== 0) {

			material.emissive.setStyle(fillColor, COLOR_SPACE_LDRAW).multiplyScalar(luminance);

		}

		if (!edgeMaterial) {

			// This is the material used for edges
			edgeMaterial = new LineBasicMaterial({
				color: new Color().setStyle(edgeColor, COLOR_SPACE_LDRAW),
				transparent: isTransparent,
				opacity: alpha,
				depthWrite: !isTransparent
			});
			edgeMaterial.color;
			edgeMaterial.userData.code = code;
			edgeMaterial.name = name + ' - Edge';

			// This is the material used for conditional edges
			edgeMaterial.userData.conditionalEdgeMaterial = new LDrawConditionalLineMaterial({

				fog: true,
				transparent: isTransparent,
				depthWrite: !isTransparent,
				color: new Color().setStyle(edgeColor, COLOR_SPACE_LDRAW),
				opacity: alpha,

			});
			edgeMaterial.userData.conditionalEdgeMaterial.userData.code = code;
			edgeMaterial.userData.conditionalEdgeMaterial.name = name + ' - Conditional Edge';

		}

		material.side = DoubleSide
		material.userData.code = code;
		material.name = name;

		//创建和保存平面材质
		material_flot = convertMaterial(material);
		material_flot.userData.code = code;
		material_flot.name = name

		material_flotArr.push(material_flot)
		material_flotArr[material_flot.userData.code] = material_flot;

		return material;

		// material.userData.edgeMaterial = edgeMaterial;


		// this.addMaterial(material);


		// material_flot = convertMaterial(material);






		// material_flot.userData.code = code;
		// material_flot.name = name;
		// material_flot.userData.edgeMaterial = edgeMaterial;
		// this.addMaterial(material_flot);





		// 
		// return material_flot


		//将标准网格材质修改成基础网格材质
		function convertMaterial(material) {

			const newMaterial = new MeshBasicMaterial();

			newMaterial.side = DoubleSide
			newMaterial.color.copy(material.color);
			newMaterial.polygonOffset = material.polygonOffset;
			newMaterial.polygonOffsetUnits = material.polygonOffsetUnits;
			newMaterial.polygonOffsetFactor = material.polygonOffsetFactor;
			newMaterial.opacity = material.opacity;
			newMaterial.transparent = material.transparent;
			newMaterial.depthWrite = material.depthWrite;
			newMaterial.toneMapping = false;

			return newMaterial;

		}

		function parseLuminance(token) {

			// Returns success

			let lum;

			if (token.startsWith('LUMINANCE')) {

				lum = parseInt(token.substring(9));

			} else {

				lum = parseInt(token);

			}

			if (isNaN(lum)) {

				return false;

			}

			luminance = Math.max(0, Math.min(1, lum / 255));

			return true;

		}

	}

	computeBuildingSteps(model) {

		let stepNumber = 0;

		model.traverse(c => {

			if (c.isGroup) {

				if (c.userData.startingBuildingStep) {

					stepNumber++;

				}

				c.userData.buildingStep = stepNumber;

			}

		});

		model.userData.numBuildingSteps = stepNumber + 1;

	}

	//释放资源
	disposeData() {
		// this.partsCache.clearAllCache()
		// const parseCache = this.parseCache;
		// const info = parseCache.parse(text);
		// clearAllCache

		this.clearAllCache()
		mpdText = null

		//缺失的零件编号
		missingColor.length = 0

		// modelName = ''

		txt_partArr = ''

		//所有零件（零件库单零件）
		// THREE_D_Parts_Arr.length = 0

		// material_flotArr.length = 0

		//缺失的零件
		missingPartsArr.length = 0

	}

}

export { LDrawLoader };
